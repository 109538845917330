import { Col, Container, ContainerFluid, Row } from "components/molecules/grid";
import { Layout } from "components/organisms";
import { useAppSelector } from "hooks";
import { useLayoutEffect, useState } from "react";
import skillsHardJson from "../../assets/json/skillsHard.json";
import skillsSoftJson from "../../assets/json/skillsSoft.json";
import { Banner, Courses } from "./childrens";
import About from "./childrens/about";
import Avatar from "./childrens/avatar";
import Discovery from "./childrens/discovery";
import Information from "./childrens/information";
import Kpis from "./childrens/kpis";
import Skills from "./childrens/skills";
import Tests from "./childrens/tests";
import { getCourses, getProfile } from "./services";
import "./styles.scss";

const myProfile = () => {
  const [loading, setLoading] = useState(false);
  const { authenticated } = useAppSelector((state) => state.auth);
  const [data, setData] = useState({
    names: ``,
    roles: [],
    lastNames: ``,
    description: ``,
    skills: [],
    banner: ``,
  });

  const [dataBanner, setDataBanner] = useState({
    banner: `hola`,
  });

  const [dataAvatar, setDataAvatar] = useState({
    avatar: ``,
  });

  const [dataInformation, setDataInformation] = useState({
    names: ``,
    lastNames: ``,
  });

  const [dataAbout, setDataAbout] = useState({
    description: ``,
  });

  const [dataCourses, setDataCourses] = useState([]);
  const [dataProgress, setDataProgress] = useState({
    numberOfCourses: 0,
    numberCertificates: 0,
    numberOfTopics: 0,
  });

  useLayoutEffect(() => {
    if (authenticated) {
      getProfile({ setLoading, setData, setDataInformation, setDataAbout });
      getCourses({ setLoading, setDataCourses, setDataProgress });
    } else {
      window.location.href = `/acceso`;
    }
  }, []);

  const role = (roleEnglish: string) => {
    let roleSpanish = ``;
    if (roleEnglish == `student`) {
      roleSpanish = `Estudiante`;
    } else if (roleEnglish == `teacher`) {
      roleSpanish = `Estudiante`;
    } else {
      roleSpanish = `Apoderado`;
    }
    return roleSpanish;
  };

  const skillDataToSkillSoft = (skills: any) => {
    const skillSoft: any = [];
    skills.map((item: any) => {
      skillSoft.push(skillsSoftJson.find((element) => element.id == item));
    });
    return skillSoft.filter((element: any) => element != undefined);
  };

  const skillDataToSkillHard = (skills: any) => {
    const skillHard: any = [];
    skills.map((item: any) => {
      skillHard.push(skillsHardJson.find((element) => element.id == item));
    });
    return skillHard.filter((element: any) => element != undefined);
  };

  return (
    <Layout>
      <ContainerFluid>
        <Row className="--bs-gutter-x-0rem">
          <Col lg="12">
            <Banner dataBanner={dataBanner} setDataBanner={setDataBanner} />
          </Col>
        </Row>
      </ContainerFluid>

      <Container>
        <Row>
          <Col lg="12">
            <Avatar dataAvatar={dataAvatar} setDataAvatar={setDataAvatar} />
          </Col>
        </Row>
      </Container>

      <Information
        dataInformation={dataInformation}
        setDataInformation={setDataInformation}
        role={role(data.roles[0])}
      />
      <Container>
        <Row>
          <Col lg="8" className="order-lg-2">
            <Container>
              <Row>
                <Col lg="12" className="pt-3rem">
                  <About dataAbout={dataAbout} setDataAbout={setDataAbout} />
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <Kpis dataProgress={dataProgress} />
                </Col>
              </Row>
            </Container>
          </Col>
          <Col lg="4" className="pt-3rem order-lg-1 px-1-5rem">
            <Skills
              skillsSoft={skillDataToSkillSoft(data.skills)}
              skillsHard={skillDataToSkillHard(data.skills)}
            />
          </Col>
        </Row>
        <Row>
          {/*<Col lg="4">
            <Classroom />
  </Col>*/}
          <Col lg="12">
            <Courses dataCourses={dataCourses} />
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <Discovery />
          </Col>
          <Col lg="8">
            <Container>
              <Row>
                <Col lg="12" className="d-flex jc-center ai-center pt-3rem">
                  <span className="ff-secondary fs-40px fw-bold">
                    MIS TESTS EMOCIONALES
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <Tests />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default myProfile;
