import Button from "../button";
import { IPagination } from "./props";
import "./styles.scss";

const Pagination = ({
  open,
  currentPage,
  totalPages,
  onChangePage,
}: IPagination) => {
  if (!open) return null;

  return (
    <div className="pagination">
      <div className="pagination__btns">
        <Button
          className="pagination__btn m-0rem p-0-5rem"
          bgColor="white"
          bColor="turquoise"
          txtColor="turquoise"
          bgHover="silverLight"
          onClick={() => currentPage > 1 && onChangePage(1)}
          iconName="leftAll"
          iconSize={20}
          iconList="arrows"
        />
        <Button
          className="pagination__btn m-0rem p-0-5rem"
          bgColor="white"
          bColor="turquoise"
          txtColor="turquoise"
          bgHover="silverLight"
          onClick={() => currentPage > 1 && onChangePage(currentPage - 1)}
          iconName="left"
          iconSize={20}
          iconList="arrows"
        />
        <Button
          className="pagination__btn m-0rem p-0-5rem"
          bgColor="white"
          bColor="turquoise"
          txtColor="turquoise"
          bgHover="silverLight"
          onClick={() =>
            currentPage < totalPages && onChangePage(currentPage + 1)
          }
          iconName="right"
          iconSize={20}
          iconList="arrows"
        />
        <Button
          className="pagination__btn m-0rem p-0-5rem"
          bgColor="white"
          bColor="turquoise"
          txtColor="turquoise"
          bgHover="silverLight"
          onClick={() => currentPage < totalPages && onChangePage(totalPages)}
          iconName="rightAll"
          iconSize={20}
          iconList="arrows"
        />
      </div>
      <p>
        Página {currentPage} de {totalPages}
      </p>
    </div>
  );
};

Pagination.defaultProps = {
  open: true,
};

export default Pagination;
