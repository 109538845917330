import images from "assets/images";
import { useState } from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import { Container } from "components/molecules/grid";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./styles.scss";

const SectionTestimony = () => {
  const [testimonies, setTestimonies] = useState([
    {
      message: `La plataforma me ayudó mucho a que siguiera aprendiendo durante la cuarentena. Ahora que ya empezaron mis clases me siento adelantado.`,
      author: `Enrique Tapia`,
      job: `Estudiante`,
      location: `Lima`,
      avatar: `avatar.webp`,
    },
    {
      message: `¡Me encantó el material complementario! En verdad estos temas nadie les daba importancia y creo que son la base del éxito para cualquier persona. Es lo que necesita nuestro país, mejorar la educación, sobre todo en valores.`,
      author: `Carmen Marcilla`,
      job: `Mamá`,
      location: `Lima`,
      avatar: `avatar.webp`,
    },
    {
      message: `Al entrar a la plataforma pude ver videos y pude resolver ejercicios que me sirvieron y me relajaron.`,
      author: `Rodrigo Cáceda`,
      job: `Docente`,
      location: `Lima`,
      avatar: `avatar.webp`,
    },
    {
      message: `Yo fui a MAB, porque justamente me parece lo máximo que podamos trabajar no solo lo académico sino también lo emocional que es súper importante.`,
      author: `Marisol Bravo`,
      job: `Mamá`,
      location: `Lima`,
      avatar: `avatar.webp`,
    },
    {
      message: `Qué buena la asistencia de MAB. Al entrar a la plataforma pude ver videos y resolver ejercicios que me sirvieron y también me relajaron.`,
      author: `Silvia Rodriguez`,
      job: `Docente`,
      location: `Piura`,
      avatar: `avatar.webp`,
    },
    {
      message: `Es necesario, hoy más que nunca, abrir algunas mochilas y cerrar algunas ventanas para poder estar bien con nosotros mismos y poder transmitirlo a nuestros alumnos.`,
      author: `Luis Rodriguez`,
      job: `Docente`,
      location: `Ayacucho`,
      avatar: `avatar.webp`,
    },
  ]);

  return (
    <section className="bg-black pt-2rem">
      <div className="testimonies">
        <Container className="pt-2rem pb-1rem">
          <Swiper
            spaceBetween={10}
            slidesPerView={3}
            navigation={true}
            modules={[Navigation]}
            breakpoints={{
              360: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              480: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            {testimonies.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="testimonie-cards">
                  <div className="testimonie-card pos-relative t-black ff-primary ta-center  ">
                    <img className="quotes" src={images.LandingComas} />

                    <div className="contenido fs-16px ta-center fw-bold">
                      {item.message}
                    </div>

                    <div className="abaut t-black">
                      <div className=" text-left">
                        <p className="mb-0rem fs-18px fw-bold">{item.author}</p>
                        <p className="mb-0rem fs-14px ">{item.job}</p>
                        <p className="mb-0rem fs-12px ">{item.location}</p>
                      </div>
                    </div>
                    {/*<img
                      className="testimonie-card__avatar pos-absolute of--cover"
                      src="{{ theme.link }}/static/images/landing/{{ testimonie.avatar }}"
                      alt=""
            />*/}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </div>
    </section>
  );
};

export default SectionTestimony;
