import "../styles.scss";
import { ICol } from "./props";

const Col = ({ className, xxl, xl, lg, md, sm, xsm, col, children }: ICol) => {
  return (
    <div
      className={`col-xxl-${xxl} col-xl-${xl} col-lg-${lg} col-md-${md} col-sm-${sm} col-xsm-${xsm} col-${col} ${className}`}
    >
      {children}
    </div>
  );
};

export default Col;
