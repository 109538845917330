import { Header, Footer } from "components/organisms";
import { ILayout } from "./props";

const Layout = ({ children }: ILayout) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
