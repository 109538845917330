import "./styles.scss";

import { Col, Container, Row } from "components/molecules/grid";

import { Icon } from "components/atoms";
import { ISearchContent } from "./props";

const SearchContent = ({ onSearch, setSearch }: ISearchContent) => {
  const handleSearch = (e: any) => {
    setSearch(e.target.value);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === `Enter`) {
      onSearch();
    }
  };

  return (
    <>
      <div className="searchContent open">
        <Container className="m-0rem p-0rem">
          <Row className="m-0rem p-0rem">
            <Col className="p-1rem">
              <div className="content">
                <input
                  name="query"
                  type="text"
                  placeholder="Hoy quiero aprender sobre..."
                  autoComplete="off"
                  maxLength={50}
                  onChange={(e) => handleSearch(e)}
                  onKeyDown={(e) => handleKeyDown(e)}
                />
                <button onClick={() => onSearch()}>
                  <Icon name="search" color="black" width={20} />
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SearchContent;
