import { Toast } from "components/atoms";
import API from "constants/api";
import endpoints from "constants/endpoints";
import { setAuthenticated } from "redux/actions/auth";
import { IHandleSignIn } from "./props";

export const logged = (data: any) => {
  localStorage.setItem(`token`, data.token);
  localStorage.setItem(`names`, btoa(data.names));
  localStorage.setItem(`lastNames`, btoa(data.lastNames));
  
  Toast({
    type: `success`,
    message: `Inicio exitoso`,
  });

  window.location.href = `/cursos`; //mi-perfil
  setAuthenticated(true);
};

export const handleSignIn = async ({ setLoading, values }: IHandleSignIn) => {
  try {
    setLoading(true);

    if (values.remember) {
      localStorage.setItem(`email`, btoa(values.email));
    } else {
      localStorage.removeItem(`email`);
    }

    const response = await API.request({
      method: `post`,
      url: endpoints.login,
      data: values,
    });
    console.log(response);
    if (response?.data?.status !== `success`) {
      throw new Error();
    }

    logged(response.data.data);
  } catch {
    Toast({
      type: `error`,
      message: `Credenciales inválidas`,
    });
  } finally {
    setLoading(false);
  }
};

export const handleGoogleSignIn = async (dataGoogleAuth: {
  credential: string;
}) => {
  try {
    const data = {
      token: dataGoogleAuth.credential,
      url: endpoints.loginGoogle,
    };

    const response = await API.request({
      url: endpoints.loginGoogle,
      method: `post`,
      data: data,
    });

    if (response?.data?.status !== `success`) {
      throw new Error();
    }

    logged(response.data.data);
  } catch (error: any) {
    Toast({
      type: `error`,
      message: error?.message || `No se pudo iniciar sesión con Google`,
    });
  }
};
