import { Button, Combobox, Icon } from "components/atoms";
import errorsForm from "constants/errorsForm";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Step } from "../../../../components/molecules";
import "../../styles.scss";
import images from "./../../../../assets/images";
import { IFormStep3b, IStep3b } from "./props";
import { rules } from "./rules";
import { handleStep3b } from "./services";
import "./styles.scss";

const Step3b = ({ setStep }: IStep3b) => {
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormStep3b>({
    mode: `onChange`,
    defaultValues: {
      names: atob(localStorage.getItem(`names`) || ``),
      lastNames: atob(localStorage.getItem(`lastNames`) || ``),
      email: atob(localStorage.getItem(`email`) || ``),
      password: atob(localStorage.getItem(`password`) || ``),
      role: atob(localStorage.getItem(`role`) || ``),
      schoolName: atob(localStorage.getItem(`schoolName`) || ``),
      sectorSchool: atob(localStorage.getItem(`sectorSchool`) || ``),
      numberChildren: atob(localStorage.getItem(`numberChildren`) || ``),
    },
  });

  const onSubmit = (payload: IFormStep3b) =>
    handleStep3b({ payload, setLoading, setStep });

  const [steps, setSteps] = useState([
    {
      number: 1,
      currentActive: true,
    },
    {
      number: 2,
      currentActive: true,
    },
    {
      number: 3,
      currentActive: true,
    },
  ]);

  const [numbersChildren, setNumbersChildren] = useState([
    {
      id: `1`,
      value: `1`,
      option: `1`,
    },
    {
      id: `2`,
      value: `2`,
      option: `2`,
    },
    {
      id: `3`,
      value: `3`,
      option: `3`,
    },
    {
      id: `4`,
      value: `4`,
      option: `4`,
    },
    {
      id: `5`,
      value: `5`,
      option: `5`,
    },
    {
      id: `6`,
      value: `6`,
      option: `6`,
    },
  ]);

  const onBack = () => {
    setStep(`2`);
    localStorage.setItem(`step`, `2`);
  };

  return (
    <section className="register bgImage3b">
      <div className="py-1-5rem">
        <Step steps={steps} />
      </div>
      <div className="register-content">
        <div className="card w-450px mt-1rem mb-2rem mx-1rem pb-3rem">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="head bg-yellow d-flex ac-ai-jc-center">
              <button
                className="button-return bg-yellow t-black hov-t-red d-flex ai-center jc-center"
                onClick={() => onBack()}
              >
                <Icon
                  list="arrows"
                  name="left"
                  width={20}
                  height={20}
                  className="ff-secondary fw-900"
                ></Icon>
              </button>
              <span className="fs-20px fw-400 ff-secondary ml-3rem mr-5rem">
                ¿CUÁNTOS(AS) HIJOS TIENES?
              </span>
            </div>

            <div className="body">
              <div className="py-3rem">
                <img src={images.HeartVector} className="imgPerfil" />
              </div>
              <div className=" pb-6rem px-3rem">
                <span className="ta-left">Cantidad de hijos(as)</span>

                <Controller
                  control={control}
                  name="numberChildren"
                  rules={rules.numberChildren}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Combobox
                      labeltext=""
                      defaultText="Seleccionar cantidad..."
                      selectedtext={value || `Seleccionar cantidad...`}
                      icon="eva:arrow-ios-downward-outline"
                      classInput="pt-1 pb-1 pl-05 pr-25"
                      classIcon="txt-silver fs-15"
                      hasSearch={false}
                      required={true}
                      changeValue={onChange}
                      listOptions={numbersChildren}
                      messageError={
                        errorsForm[errors?.numberChildren?.type || ``]
                      }
                    />
                  )}
                />
              </div>

              <div className="btnIngresar pt-6rem">
                <Button
                  type="submit"
                  className="bg-yellowLigth t-black hov-bg-yellow hov-t-red hov-t-red br-25px ff-secondary fs-18px fw-900 w-75por"
                  text="Registrar"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Step3b;
