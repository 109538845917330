import * as authSlice from "redux/slices/auth";
import store from "redux/store";
import { IAccess } from "redux/types/auth";

export const setAuthenticated = (payload: boolean) => {
  store.dispatch(authSlice.setAuthenticated(payload));
};

export const setAccess = (payload: IAccess) => {
  store.dispatch(authSlice.setAccess(payload));
};
