import { Button } from "components/atoms";
import { Col, Container, Row } from "components/molecules/grid";
import { useState } from "react";
import { ISeeMoreBtn } from "./props";
import "./styles.scss";

const SeeMoreBtn = ({
  text,
  maxLength,
  className,
  color,
  btnHover,
}: ISeeMoreBtn) => {
  const [showLess, setShowLess] = useState(true);

  if (text.length < maxLength) {
    return <p>{text}</p>;
  }

  return (
    <Container>
      <Row className={`see-more ${className}`}>
        <Col lg="10" className={`see-more-txt t-${color} fs-18px pr-1rem`}>
          {showLess ? `${text.slice(0, maxLength)}...` : text}
        </Col>
        <Col lg="2" className="d-flex ai-center jc-center ">
          <Button
            text={showLess ? `Ver más` : `Ocultar`}
            IconClass={showLess ? `rotate` : `Ocultar`}
            iconName="up"
            iconList="arrows"
            bgColor="transparent"
            className="see-more-btn ff-primary fw-bold "
            txtHover={btnHover}
            txtColor={color}
            disabled={false}
            onClick={() => setShowLess(!showLess)}
          />
        </Col>
      </Row>
    </Container>
  );
};

SeeMoreBtn.defaultProps = {
  color: `black`,
  btnHover: `gray`,
};

export default SeeMoreBtn;
