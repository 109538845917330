import images from "assets/images";
import { Col, Container, Row } from "components/molecules/grid";
import ModalProfile from "components/molecules/modalProfile";
import { useState } from "react";
import skillsSoftJson from "../../../../assets/json/skillsSoft.json";
import { ISkills } from "./props";
import "./styles.scss";

const Skills = ({ role, names, skillsSoft, skillsHard }: ISkills) => {
  const [editUserSkill, setUserAbout] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState(false);
  const [arrSkillSoft, setArrSkillSoft] = useState(skillsSoft);

  const onEditSkill = () => {
    setUserAbout(!editUserSkill);
  };

  const onSelectedSkill = (id: any) => {
    const filter = skillsSoftJson.find((skill) => skill.id == id);
    console.log(filter);
  };

  return (
    <div className="user-skills">
      <div className="user-skills_title bg-yellow">
        <h5 className="m-0rem p-0rem ff-secondary fw-bold px-1rem">
          Mis Habilidades
        </h5>
        {/*<Button
          className="m-0rem p-0rem ff-primary fs-12px fw-bold br-30px w-35px h-35px"
          bgColor="cyan"
          bgHover="cyan"
          txtColor="white"
          txtHover="black"
          onClick={() => onEditSkill()}
          iconName="pencil"
          iconList="buttons"
          iconSize={20}
  />*/}
      </div>
      {skillsSoft.length != 0 && skillsHard.length != 0 ? (
        <Container className="user-skills_content d-flex fd-column jc-start bg-white">
          <Row>
            <Col lg="12" className="pt-1rem">
              <span className="fs-18px fw-bold ff-secondary ">
                HABILIDADES BLANDAS
              </span>
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="skillContent d-flex fd-column">
              {skillsSoft.map((item: any, index: any) => (
                <div key={index} className="skill bg-silverLight my-0-2rem">
                  <span className="fs-14px ">{item.name}</span>
                </div>
              ))}
            </Col>
          </Row>
          <Row className="pt-3rem">
            <Col lg="12">
              <span className="fs-18px fw-bold ff-secondary">
                HABILIDADES DURAS
              </span>
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="skillContent d-flex fd-column">
              {skillsHard.map((item: any, index: any) => (
                <div key={index} className="skill bg-silverLight my-0-2rem">
                  <span className="fs-14px ">{item.name}</span>
                </div>
              ))}
            </Col>
          </Row>
        </Container>
      ) : (
        <Container className="user-skills_content d-flex fd-column ai-center jc-center bg-white">
          <Row>
            <Col lg="12" className="d-flex fd-column ai-center jc-center">
              <span className="fs-14px ta-center">
                Cuéntanos un poco sobre de tus habilidades. ¡Nos encantaría
                conocerte más!
              </span>
              <img src={images.Bee} alt="" className="w-50por" />
            </Col>
          </Row>
        </Container>
      )}
      <ModalProfile
        open={editUserSkill}
        title="ELEGIR MIS HABILIDADES"
        onClose={() => onEditSkill()}
      >
        <Container className="p-2rem">
          <Row>
            <Col lg="6">
              <h5>HABILIDADES BLANDAS</h5>
              <div className="d-flex fd-column ">
                {skillsSoftJson.map((item: any, index: any) => (
                  <div
                    key={index}
                    className={`${
                      skillsSoft.find((skill: any) => skill.id == item.id) !=
                      null
                        ? `bg-blue`
                        : `bg-silver`
                    }`}
                    onClick={() => onSelectedSkill(item.id)}
                  >
                    <span>{item.name}</span>
                  </div>
                ))}
              </div>
            </Col>
            <Col lg="6">
              <h5>HABILIDADES DURAS</h5>
              <div>
                {skillsSoftJson.map((item: any, index: any) => {
                  return item.name;
                })}
              </div>
            </Col>
          </Row>
        </Container>
      </ModalProfile>
    </div>
  );
};

export default Skills;
