import { Icon } from "components/atoms";
import { IInput } from "./props";
import "./styles.scss";

const Input = ({
  id,
  type,
  iconName,
  iconList,
  txtLabel,
  classLabel,
  htmlfor,
  onChange,
  placeholder,
  value,
  readOnly,
  onKeyUp,
  onBlur,
  messageError,
  required,
  iconColor,
  className,
}: IInput) => {
  return (
    <div className={`input-component`}>
      <label
        htmlFor={htmlfor}
        className={`input-component_label fs-questions ${classLabel}`}
      >
        {txtLabel}
      </label>

      <div className="input-component_container">
        <input
          id={id}
          placeholder={placeholder}
          type={type}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange && onChange(e.target.value);
          }}
          value={value}
          readOnly={readOnly}
          required={required}
          onBlur={onBlur}
          onKeyUp={onKeyUp}
          className={`input-component_input ${className}`}
        />

        {iconName && (
          <Icon
            className={`input-component_icon             
                ${iconName ? `ml-05` : ``}
              `}
            name={iconName}
            list={iconList}
            color={iconColor}
            width={20}
            height={20}
          />
        )}
      </div>

      {messageError && <p className="input-component_error">{messageError}</p>}
    </div>
  );
};

Input.defaultProps = {
  type: `text`,
  iconColor: `red`,
};

export default Input;
