import images from "assets/images";
import { Button, Skeleton } from "components/atoms";
import SeeMoreBtn from "components/atoms/seeMoreBtn";
import { Col, Container, ContainerFluid, Row } from "components/molecules/grid";
import { useState } from "react";
import ReactPlayer from "react-player/vimeo";
import { ITrailer } from "./props";
import "./styles.scss";

const Trailer = ({ loading, dataTrailer, dataUnitsContent }: ITrailer) => {
  const [playing, setPlaying] = useState(false);

  const onPlay = () => {
    playing ? setPlaying(false) : setPlaying(true);
  };

  return (
    <>
      <section className="course-trailer mt-60px">
        <ContainerFluid className="course-trailer-video bg-black">
          {loading ? (
            <Skeleton dotColor="white" bgColor="transparent" />
          ) : (
            <>
              {dataTrailer.idCourse != undefined ? (
                <ReactPlayer
                  url={`https://player.vimeo.com/video/${
                    dataTrailer.trailer.split(`/`)[3]
                  }?h=${dataTrailer.trailer.split(`/`)[4]}`}
                  width="100%"
                  height="100%"
                  playing={playing}
                />
              ) : null}
            </>
          )}
        </ContainerFluid>
        <ContainerFluid className="course-trailer-detail desktop">
          <Row>
            <Col lg="12" className="course-trailer-detail-title t-white">
              <Container>
                <Row>
                  <Col lg="12">
                    {loading ? (
                      <Skeleton
                        height="60px"
                        dotColor="gray"
                        bgColor="transparent"
                      ></Skeleton>
                    ) : (
                      <h1 className="fs-50px ff-secondary ">
                        {dataTrailer.titleCourse}
                      </h1>
                    )}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="course-trailer-detail-description t-white">
              <Container>
                <Row>
                  <Col lg="9" md="9" sm="9">
                    {loading ? (
                      <Skeleton
                        height="170px"
                        dotColor="gray"
                        bgColor="transparent"
                      ></Skeleton>
                    ) : (
                      <p className="d-block fs-21px">
                        {dataTrailer.description}
                      </p>
                    )}
                  </Col>
                  <Col
                    lg="3"
                    md="3"
                    sm="12"
                    className="d-flex jc-center ai-center"
                  >
                    <Button
                      redirect={`/curso/${dataTrailer.idCourse}/topico/${dataUnitsContent[0]?.idTopics[0]}`}
                      text="Comienza ahora"
                      className="ff-primary fs-21px fw-bold "
                      txtHover="yellow"
                    />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </ContainerFluid>
        <ContainerFluid className="course-trailer-control d-flex ai-center jc-center">
          {loading ? (
            <Button
              iconList="course"
              iconName="video"
              txtColor="white"
              iconSize={100}
              iconAnimate={true}
            />
          ) : (
            <>
              {!playing ? (
                <img
                  src={images.Play}
                  width="40%"
                  onClick={onPlay}
                  className="play"
                />
              ) : (
                <img
                  src={images.Pause}
                  width="40%"
                  onClick={onPlay}
                  className="pause"
                />
              )}
            </>
          )}
        </ContainerFluid>
      </section>

      <Container className="course-trailer-mobile py-2rem px-1rem mobile">
        <Row>
          <Col lg="12" className="course-trailer-mobile-detail-title">
            {loading ? (
              <Skeleton
                height="100px"
                dotColor="black"
                bgColor="gray"
              ></Skeleton>
            ) : (
              <span className="t-black fw-bold fs-30px ff-secondary ">
                {dataTrailer.titleCourse}
              </span>
            )}
          </Col>
          <Col lg="12" className="course-trailer-mobile-detail-description">
            {loading ? (
              <Skeleton
                height="150px"
                dotColor="black"
                bgColor="gray"
              ></Skeleton>
            ) : (
              <SeeMoreBtn
                text={dataTrailer.description}
                maxLength={100}
                color="black"
                btnHover="yellow"
                className="course-trailer-mobile-detail-description-all pb-1rem"
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col lg="12" className="d-flex jc-center ai-center pt-1rem">
            <Button
              redirect={`/curso/${dataTrailer.idCourse}/topico/${dataUnitsContent[0]?.idTopics[0]}`}
              text="Comienza ahora"
              className="ff-primary fw-bold px-2rem"
              txtHover="yellow"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Trailer;
