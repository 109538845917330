export const rules = {
  password: {
    required: true,
    minLength: 8,
  },

  repeatPassword: {
    required: true,
    minLength: 8,
  },
};
