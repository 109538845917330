import { Toast } from "components/atoms";
import API from "constants/api";
import endpoints from "constants/endpoints";
import { IGetSpeaker } from "./props";

export const getSpeaker = async ({ speakerId, setData }: IGetSpeaker) => {
  try {
    const response = await API.request({
      url: endpoints.getSpeaker,
      params: {
        speakerId: speakerId,
      },
      hasToken: false,
    });
    console.log(response);
    if (response?.status !== 200) {
      throw new Error(response?.data?.message);
    }
    setData(response.data?.data);
  } catch (error: any) {
    Toast({
      message: error?.message || `No se ha encontrado el speaker`,
      type: `error`,
    });
  }
};
