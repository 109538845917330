import { Toast } from "components/atoms";
import API from "constants/api";
import endpoints from "constants/endpoints";
import { setAccess, setAuthenticated } from "redux/actions/auth";
import * as XLSX from "xlsx";

export const converteToObjDropdown = (
  data: any[],
  key: string,
  value: string
) => {
  const arrayDropdown = data.map((item) => ({
    id: item[key],
    text: item[value],
  }));

  return arrayDropdown;
};

export const converteFromExcelToJson = (excel: File, onLoad: Function) => {
  let data: any;
  const reader = new FileReader();
  reader.readAsArrayBuffer(excel);

  reader.onload = async function (e: any) {
    const hojas: any = [];
    const response = new Uint8Array(e.target.result);
    const workbook = XLSX.read(response, { type: `array` });

    await workbook.SheetNames.forEach((sheetName) => {
      const rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
      hojas.push({ rowObject, sheetName });
    });

    data = hojas[0].rowObject;
    onLoad(data);
  };
};

export const handleCopyClipboard = (url: string) => {
  navigator.clipboard.writeText(url);

  Toast({
    message: `Enlace copiado`,
    type: `info`,
  });
};

export const validateAuth = async (setLoading: (loading: boolean) => void) => {
  try {
    setLoading(true);
    const response = await API.request({
      url: endpoints.getProfile,
      hasToken: true,
    });

    if (response?.data?.status == 401) {
      throw new Error(`Token de acceso expirado.`);
    }

    if (!response?.data?.data) {
      throw new Error(`Servidor fuera de servicio.`);
    }

    setAccess(response.data.data);
    setAuthenticated(true);
    setLoading(false);
  } catch {
    setAuthenticated(false);
  } finally {
    document.getElementById(`load`)?.remove();
    setLoading(false);
  }
};

export const validateAuthOld = async (
  setLoading: (loading: boolean) => void
) => {
  try {
    setLoading(true);

    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (localStorage.getItem(`token`)) {
      setAuthenticated(true);
    }
  } catch {
    setAuthenticated(false);
  } finally {
    document.getElementById(`load`)?.remove();
    setLoading(false);
  }
};
