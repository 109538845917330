import { Toast } from "components/atoms";
import API from "constants/api";
import endpoints from "constants/endpoints";
import { setAuthenticated } from "redux/actions/auth";
import { IHandleStep3b } from "./props";

const logged = (data: any) => {
  localStorage.setItem(`token`, data.token);
  localStorage.setItem(`names`, btoa(data.names));
  localStorage.setItem(`lastNames`, btoa(data.lastNames));
  setAuthenticated(true);

  Toast({
    type: `success`,
    message: `Se registró correctamente.`,
  });

  window.location.href = `/cursos`; //mi-perfil
};

export const handleStep3b = async ({
  payload,
  setLoading,
  setStep,
}: IHandleStep3b) => {
  try {
    setLoading(true);

    const response = await API.request({
      method: `post`,
      url: endpoints.register,
      data: payload,
    });

    if (response?.data?.status !== `success`) {
      throw new Error();
    }

    Toast({
      type: `success`,
      message: `Inicio exitoso`,
    });

    logged(response.data.data);
    localStorage.removeItem(`step`);
    localStorage.removeItem(`email`);
    localStorage.removeItem(`password`);
    localStorage.removeItem(`role`);
    localStorage.removeItem(`schoolName`);
    localStorage.removeItem(`sectorSchool`);
    localStorage.removeItem(`numberChildren`);
  } catch {
    localStorage.setItem(`step`, `1`);
    localStorage.setItem(`numberChildren`, btoa(payload.numberChildren));
    Toast({
      type: `error`,
      message: `Correo digitado ya fue registrado anteriormente.`,
    });
    setStep(`1`);
  } finally {
    setLoading(false);
  }
};
