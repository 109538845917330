import { ISpinnerLoading } from "./props";
import "./styles.scss";

const SpinnerLoading = ({ className, bgColor }: ISpinnerLoading) => {
  return (
    <div className={`spinner-loading ${className}`}>
      <div className="loader">
        <div className={`dot ${bgColor ? `bg-${bgColor}` : ``}`} />
        <div className={`dot ${bgColor ? `bg-${bgColor}` : ``}`} />
        <div className={`dot ${bgColor ? `bg-${bgColor}` : ``}`} />
      </div>
    </div>
  );
};

SpinnerLoading.defaultProps = {
  bgColor: `magenta`,
};

export default SpinnerLoading;
