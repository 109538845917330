import { IProgress } from "./props";
import "./styles.scss";

const Progress = ({ ariaValueMin, ariaValueNow, ariaValueMax }: IProgress) => {
  return (
    <div className="progress w-100por">
      <div
        className={`progress-bar w-${ariaValueNow.toFixed(0)}por`}
        role="progressbar"
        aria-valuenow={ariaValueNow}
        aria-valuemin={ariaValueMin}
        aria-valuemax={ariaValueMax}
      ></div>
    </div>
  );
};

export default Progress;
