import API from "constants/api";

interface IHttp {
    url: string,
    method?: any,
    data?: any;
    params?: any;
    hasToken?: boolean;
  }
  
export const http = async ({ url, method, data, params, hasToken }: IHttp) => {
    const response = await API.request({
      url: url,
      method: method || 'get',
      data: data,
      params: params,
      hasToken: hasToken || true,
    });
    
    return {
              status: response?.status,
              message: response?.data?.message,
              data: response?.data?.data,
              paginationInfo: response?.data?.paginationInfo,
              errors: response?.data?.errors,
              fileStatus: response?.status,
              fileData: response?.data,
              fileErrors: response?.errors,
          }
  };