import { useEffect, useState } from "react";
import { CToastContainerProps, IOptions } from "../props";
import Toast from "./Toast";

const camelCase = (str: string) =>
  str.replace(/-([a-z])/g, (g) => g[1].toUpperCase());

const defaultToasts = {
  topLeft: [],
  topCenter: [],
  topRight: [],
  bottomLeft: [],
  bottomCenter: [],
  bottomRight: [],
};

const ToastContainer = ({ toast }: CToastContainerProps) => {
  const [allToasts, setToasts] = useState<any>(defaultToasts);

  useEffect(() => {
    if (toast) {
      setToasts((prevToasts: any) => {
        const position = camelCase(toast.position);
        return { ...prevToasts, [position]: [...prevToasts[position], toast] };
      });
    }
  }, [toast]);

  const handleRemove = () => {
    return (id: number, position: string) => {
      setToasts((prevToasts: any) => {
        const toastPosition = camelCase(position);
        return {
          ...prevToasts,
          [toastPosition]: prevToasts[toastPosition].filter(
            (item: IOptions) => item.id !== id
          ),
        };
      });
    };
  };

  const rows = [`top`, `bottom`];
  const groups = [`Left`, `Center`, `Right`];

  return (
    <>
      {rows.map((row) => (
        <div key={`row_${row}`} className="ct-row">
          {groups.map((group) => {
            const type = `${row}${group}`;
            const className = [
              `ct-group`,
              row === `bottom` ? `ct-flex-bottom` : ``,
            ].join(` `);

            return (
              <div key={type} className={className}>
                {allToasts[type].map((item: IOptions) => (
                  <Toast
                    key={`${type}_${item.id}`}
                    id={item.id}
                    message={item.message}
                    position={item.position}
                    heading={item.heading}
                    type={item.type}
                    hideAfter={item.hideAfter}
                    onHide={handleRemove()}
                  />
                ))}
              </div>
            );
          })}
        </div>
      ))}
    </>
  );
};

export default ToastContainer;
