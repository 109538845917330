import images from "assets/images";
import { Button, Icon, Input } from "components/atoms";
import errorsForm from "constants/errorsForm";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "../styles.scss";
import { FRecoveryCredential } from "./props";
import { rules } from "./rules";
import { handleRecoveryCredential } from "./services";
import "./styles.scss";

const RecoveryCredential = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FRecoveryCredential>({
    mode: `onChange`,
    defaultValues: {
      email: atob(localStorage.getItem(`email`) || ``),
    },
  });

  const onBack = () => {
    navigate(`/acceso`);
  };

  const onSubmit = (values: FRecoveryCredential) =>
    handleRecoveryCredential({ setLoading, values });

  return (
    <div>
      <section className="login">
        <div className="login-card bg-white w-450px mx-1rem mb-2rem pb-3rem">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="head bg-yellow d-flex ac-ai-jc-center">
              <button
                className="button-return bg-yellow t-black hov-t-red d-flex ai-center jc-center"
                onClick={() => onBack()}
              >
                <Icon
                  list="arrows"
                  name="left"
                  width={20}
                  height={20}
                  className="ff-secondary fw-900"
                ></Icon>
              </button>
              <span className="fs-20px fw-400 ff-secondary ml-5rem mr-7rem">
                RECUPERAR CREDENCIAL
              </span>
            </div>
            <div className="body">
              <div className="py-2rem">
                <img src={images.MabRed} className="w-25por" />
              </div>

              <div className="pt-1rem pb-2rem px-3rem ta-left">
                <span>Correo electrónico</span>
                <Controller
                  control={control}
                  name="email"
                  rules={rules.email}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Input
                      type="email"
                      placeholder="Correo electrónico"
                      value={value || ``}
                      onChange={onChange}
                      onBlur={onBlur}
                      iconList="access"
                      iconNameLeft="email"
                      iconLeft={true}
                      messageError={errorsForm[errors?.email?.type || ``]}
                    />
                  )}
                />
              </div>
              <Button
                type="submit"
                loading={loading}
                className="btnAccess bg-yellow t-black ff-secondary fw-900 fs-20px w-75por "
                text="Recuperar"
              />
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default RecoveryCredential;
