import { Button, RadioButtonTest } from "components/atoms";
import { Col, Container, Row } from "components/molecules/grid";
import { useLayoutEffect, useState } from "react";
import questionsJson from "../../question.json";
import { handleStepResult } from "../../services";
import { IQuestion } from "./props";
import "./styles.scss";

const Question = ({ setStepTest }: IQuestion) => {
  const [loading, setLoading] = useState(false);
  //SI EN CASO AVANZO CON EL TEST RECUPERAMOS DESDE EL LOCAL STORAGE
  const storageQuestions: any = localStorage.getItem(
    `testMotivationArrQuestion`
  );
  //SI EN CASO ES UN TEST NUEVO O EL LOCALSTORAGE SE LIMPIO LE ASIGNAMOS EL JSON QUE SE TIENE
  const [questions, setQuestions] = useState(
    JSON.parse(storageQuestions) || questionsJson
  );
  //ESTADO PARA TENER EL ID DE LA ULTIMA PREGUNTA DONDE SE QUEDO
  const [currentIdTestMotivation, setCurrentIdTestMotivation] = useState(
    Number(localStorage.getItem(`testMotivationCurrentIdQuestion`)) || 1
  );
  //ESTADO PARA TENER LA DATA DEL ULTIMA PREGUNTA DONDE SE QUEDO
  const [currentQuestion, setCurrentQuestion] = useState([
    { id: 0, title: ``, dimention: ``, options: [], answer: 0 },
  ]);
  //ESTADO PARA LOS BOTONOS DE ATRAS ADELANTE Y FINALIZAR
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isFinishTest, setIsFinishTest] = useState(false);
  const isBackDisabled = currentIdTestMotivation === 1 ? true : false;

  //INICIALIZAR LA PRIMERA O EN LA ULTIMA PREGUNTA DONDE SE QUEDO
  const callInit = () => {
    const testMotivation: any = questions.filter(
      (item: any) => item.id === currentIdTestMotivation
    );
    setCurrentQuestion(testMotivation);

    //SI HAY UNA OPCION MARCADA EL BOTON SIGUIENTE ESTARÁ HABILITADO
    testMotivation[0].answer == -1
      ? setIsNextDisabled(true)
      : setIsNextDisabled(false);
    //
    currentIdTestMotivation == questions.length
      ? setIsFinishTest(true)
      : setIsFinishTest(false);
  };

  useLayoutEffect(() => {
    callInit();
  }, []);

  const handleAnswer = (idQuestion: any, credit: any) => {
    //ACTUALIZAR EL ARRAY QUESTIONS
    const oldQuestions = questions;
    const newQuestions = oldQuestions.map((p: any) => {
      if (p.id == idQuestion) {
        p.answer = credit;
        return p;
      }
      return p;
    });
    setQuestions(newQuestions);
    //GUARDAR EN EL STORAGE
    localStorage.setItem(
      `testMotivationArrQuestion`,
      JSON.stringify(questions)
    );
    //localStorage.setItem(`currentIdTestMotivation`, idQuestion);
    //HABILITAR EL BOTON SIGUIENTE
    setIsNextDisabled(false);
    //SI SELECCIONA LA ULTIMA PREGUNTA HABILITAR EL BOTON FINALIZAR
    currentIdTestMotivation == questions.length
      ? setIsFinishTest(true)
      : setIsFinishTest(false);
  };

  const handlePrevious = () => {
    const testMotivation: any = questions.filter(
      (item: any) => item.id === currentIdTestMotivation - 1
    );
    setCurrentQuestion(testMotivation);
    setCurrentIdTestMotivation(currentIdTestMotivation - 1);

    //SI HAY UNA OPCION MARCADA EL BOTON SIGUIENTE ESTARÁ HABILITADO
    testMotivation[0].answer == -1
      ? setIsNextDisabled(true)
      : setIsNextDisabled(false);
    //DESHABILITAR EL FINISH TEST
    setIsFinishTest(false);
  };

  const handleNext = () => {
    const testMotivation: any = questions.filter(
      (item: any) => item.id === currentIdTestMotivation + 1
    );
    setCurrentQuestion(testMotivation);
    setCurrentIdTestMotivation(currentIdTestMotivation + 1);
    //GUARDAR EN EL LOCAL STORAGE
    localStorage.setItem(
      `testMotivationCurrentIdQuestion`,
      testMotivation[0].id
    );
    //SI HAY UNA OPCION MARCADA EL BOTON SIGUIENTE ESTARÁ HABILITADO
    if (testMotivation[0].answer == -1) {
      setIsNextDisabled(true);
      setIsFinishTest(false);
    } else {
      setIsNextDisabled(false);
      currentIdTestMotivation + 1 == questions.length
        ? setIsFinishTest(true)
        : setIsFinishTest(false);
    }
    //DESHABILITAR EL FINISH TEST
  };

  const handleFinish = () => {
    handleStepResult({ setLoading, setStepTest });
  };

  return (
    <Container className="px-2rem pb-4rem mt-60px">
      <Row>
        <Col lg="12">
          <h2 className="ta-center ff-secondary t-black pt-3rem pb-2rem fw-bold">
            TEST DE MOTIVACIÓN
          </h2>
        </Col>
      </Row>
      <Row>
        <Col lg="12" className="d-flex ai-center jc-center">
          <p className="tp-question-progress bg-cyan p-0-5rem t-black">
            <span>{currentIdTestMotivation}</span> de{` `}
            <span>{questions.length}</span>
          </p>
        </Col>
      </Row>
      <Container className="question bg-white ">
        <form>
          <Row className="px-2rem pt-3rem">
            <Col lg="12" className="ta-left">
              <span className="ta-center ff-primary t-black fs-18px p-1rem fw-bold">
                {currentQuestion[0].id}.- {currentQuestion[0].title}
              </span>
            </Col>
          </Row>
          <Row className="px-4rem py-2rem">
            <Col lg="12" className="ta-left">
              {currentQuestion[0].options.map((item: any, index: any) => (
                <>
                  <RadioButtonTest
                    key={index}
                    name={`option-${currentQuestion[0].id}`}
                    id={`option-${currentQuestion[0].id}-${index + 1}`}
                    text={item.title}
                    value={item.credit}
                    checked={
                      item.credit === currentQuestion[0].answer ? true : false
                    }
                    onChange={() =>
                      handleAnswer(currentQuestion[0].id, item.credit)
                    }
                  />
                </>
              ))}
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="d-flex ai-center jc-center py-2rem">
              <Button
                iconList="arrows"
                iconName="back-alt"
                txtColor="black"
                bgColor="white"
                bgHover="turquoise"
                text="Atrás"
                className={`ff-primary fw-bold px-2rem br-20px mr-1rem`}
                onClick={() => handlePrevious()}
                disabled={isBackDisabled}
                iconPosition={`right`}
                // rowReverse={true}
              />

              {!isFinishTest && (
                <Button
                  iconList="arrows"
                  iconName="next-alt"
                  txtColor="black"
                  bgColor="white"
                  bgHover="turquoise"
                  text="Siguiente"
                  className="ff-primary fw-bold px-2rem br-20px ml-1rem"
                  onClick={() => handleNext()}
                  disabled={isNextDisabled}
                />
              )}
              {isFinishTest && currentIdTestMotivation == questions.length && (
                <Button
                  iconList="arrows"
                  iconName="next-alt"
                  txtColor="black"
                  bgColor="white"
                  bgHover="turquoise"
                  text="Finalizar"
                  className="ff-primary fw-bold px-2rem br-20px ml-1rem"
                  onClick={() => handleFinish()}
                />
              )}
            </Col>
          </Row>
        </form>
      </Container>
    </Container>
  );
};

export default Question;
