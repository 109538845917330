import images from "assets/images";
import { Button, Combobox, Icon, Input } from "components/atoms";
import errorsForm from "constants/errorsForm";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Step } from "../../../../components/molecules";
import "../../styles.scss";
import { IFormStep3a, IStep3a } from "./props";
import { rules } from "./rules";
import { handleStep3a } from "./services";
import "./styles.scss";

const Step3a = ({ setStep }: IStep3a) => {
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormStep3a>({
    mode: `onChange`,
    defaultValues: {
      names: atob(localStorage.getItem(`names`) || ``),
      lastNames: atob(localStorage.getItem(`lastNames`) || ``),
      email: atob(localStorage.getItem(`email`) || ``),
      password: atob(localStorage.getItem(`password`) || ``),
      role: atob(localStorage.getItem(`role`) || ``),
      schoolName: atob(localStorage.getItem(`schoolName`) || ``),
      sectorSchool: atob(localStorage.getItem(`sectorSchool`) || ``),
      numberChildren: atob(localStorage.getItem(`numberChildren`) || ``),
    },
  });

  const onSubmit = (payload: IFormStep3a) =>
    handleStep3a({ payload, setLoading, setStep });

  const [steps, setSteps] = useState([
    {
      number: 1,
      currentActive: true,
    },
    {
      number: 2,
      currentActive: true,
    },
    {
      number: 3,
      currentActive: true,
    },
  ]);

  const [sectorSchool, setSectorSchool] = useState([
    {
      id: `public`,
      value: `public`,
      option: `Público`,
    },
    {
      id: `private`,
      value: `private`,
      option: `Privado`,
    },
  ]);

  const onBack = () => {
    setStep(`2`);
    localStorage.setItem(`step`, `2`);
  };

  return (
    <section className="register bgImage3a">
      <div className="py-1-5rem">
        <Step steps={steps} />
      </div>
      <div className="register-content">
        <div className="card w-450px mt-1rem mb-2rem mx-1rem pb-3rem">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="head bg-yellow d-flex ac-ai-jc-center">
              <button
                className="button-return bg-yellow t-black hov-t-red d-flex ai-center jc-center"
                onClick={() => onBack()}
              >
                <Icon
                  list="arrows"
                  name="left"
                  width={20}
                  height={20}
                  className="ff-secondary fw-900"
                ></Icon>
              </button>
              <span className="fs-20px fw-400 ff-secondary ml-3rem mr-5rem">
                ¿DE QUÉ INSTITUCIÓN VIENES?
              </span>
            </div>

            <div className="body">
              <div className="py-3rem">
                <img className="imgPerfil" src={images.Door} />
              </div>

              <div className="pb-4rem px-3rem">
                <span>Nombre de la institución</span>
                <Controller
                  control={control}
                  name="schoolName"
                  rules={rules.schoolName}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Input
                      type="text"
                      placeholder="Nombre de la institución"
                      value={value || ``}
                      onChange={onChange}
                      onBlur={onBlur}
                      iconList="access"
                      iconNameLeft="user"
                      iconLeft={true}
                      messageError={errorsForm[errors?.schoolName?.type || ``]}
                    />
                  )}
                />
              </div>

              <div className=" pb-6rem px-3rem">
                <span className="ta-left">
                  ¿A qué sector pertenece tu institución?
                </span>

                <Controller
                  control={control}
                  name="sectorSchool"
                  rules={rules.sectorSchool}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Combobox
                      labeltext=""
                      defaultText="Seleccionar sector..."
                      selectedtext={
                        value == `private`
                          ? `Privado`
                          : value == `public`
                          ? `Público`
                          : `Seleccionar sector` || `Seleccionar sector...`
                      }
                      icon="eva:arrow-ios-downward-outline"
                      classInput="pt-1 pb-1 pl-05 pr-25"
                      classIcon="txt-silver fs-15"
                      hasSearch={false}
                      required={true}
                      changeValue={onChange}
                      listOptions={sectorSchool}
                      messageError={
                        errorsForm[errors?.sectorSchool?.type || ``]
                      }
                    />
                  )}
                />
              </div>

              <div className="btnIngresar">
                <Button
                  type="submit"
                  className="bg-yellowLigth t-black hov-bg-yellow hov-t-red hov-t-red br-25px ff-secondary fs-18px fw-900 w-75por"
                  text="Registrar"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Step3a;
