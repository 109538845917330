import { Layout } from "components/organisms";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Banner, Description } from "./childrens";
import { ASpeaker } from "./props";
import { getSpeaker } from "./services";

const Speaker = () => {
  const { speakerId } = useParams();

  const [data, setData] = useState<ASpeaker>({
    speakerId: ``,
    name: ``,
    about: ``,
    cover: ``,
  });

  useEffect(() => {
    getSpeaker({ speakerId: speakerId, setData: setData });
  }, []);

  return (
    <Layout>
      <Banner name={data.name} cover={data.cover} />
      <Description about={data.about} />
      {/*<Courses />*/}
    </Layout>
  );
};

export default Speaker;
