import { Header } from "components/organisms";
import { useState } from "react";
import { Step1, Step2, Step3a, Step3b } from "./children";

const Register = () => {
  const [step, setStep] = useState(localStorage.getItem(`step`) || `1`);

  return (
    <div>
      <Header />
      {step == `1` ? <Step1 setStep={setStep} /> : null}
      {step == `2` ? <Step2 setStep={setStep} /> : null}
      {step == `3a` ? <Step3a setStep={setStep} /> : null}
      {step == `3b` ? <Step3b setStep={setStep} /> : null}
    </div>
  );
};

export default Register;
