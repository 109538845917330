import { Toast } from "components/atoms";
import API from "constants/api";
import endpoints from "constants/endpoints";
import baremoJson from "./baremo.json";
import { IHandleStepQuestion, IHandleStepResult } from "./props";

export const getResult = async (
  setLoading: Function,
  setStepTest: Function
) => {
  try {
    setLoading(true);

    const response = await API.request({
      method: `get`,
      url: endpoints.getTestMotivation,
      hasToken: true,
    });

    if (response?.data?.status !== `success`) {
      throw new Error();
    }

    localStorage.setItem(`testMotivationStep`, `result`);
    localStorage.setItem(
      `testMotivationResult`,
      JSON.stringify(response.data.data)
    );

    Toast({
      type: `success`,
      message: `Test realizado correctamente.`,
    });

    setStepTest(`result`);
    setLoading(false);
  } catch {
    if (localStorage.getItem(`testMotivationStep`) != `question`) {
      localStorage.setItem(`testMotivationStep`, `init`);
      setStepTest(`init`);
    } else {
      localStorage.setItem(`testMotivationStep`, `question`);
      setStepTest(`question`);
    }
  } finally {
    setLoading(false);
  }
};

export const handleStepQuestion = ({
  setLoading,
  values,
  setStepTest,
}: IHandleStepQuestion) => {
  try {
    setLoading(true);
    localStorage.setItem(`testMotivationStep`, `question`);
    localStorage.setItem(`testMotivationName`, btoa(values.name));
    localStorage.setItem(`testMotivationSex`, btoa(values.sex));
    localStorage.setItem(`testMotivationAge`, btoa(values.age));
    setStepTest(`question`);
    setLoading(false);
  } catch {
    localStorage.setItem(`testMotivationStep`, `init`);
    setStepTest(`init`);
  } finally {
    setLoading(false);
  }
};

export const handleStepResult = async ({
  setLoading,
  setStepTest,
}: IHandleStepResult) => {
  try {
    setLoading(true);

    let percentilg = 0;
    let percentili = 0;
    let percentile = 0;
    const name = atob(localStorage.getItem(`testMotivationName`) || ``);
    const age = atob(localStorage.getItem(`testMotivationAge`) || ``);
    const sex = atob(localStorage.getItem(`testMotivationSex`) || ``);
    const storageQuestions: any = localStorage.getItem(
      `testMotivationArrQuestion`
    );
    const questions = JSON.parse(storageQuestions);

    // OBTENER LOS VALORES DE LAS DIMENSIONES
    const filter: any = {
      i: questions.filter((q: any) => q.dimention == `I`),
      e: questions.filter((q: any) => q.dimention == `E`),
    };

    // SUMAR LOS VALORES DE LAS DIMENSIONES
    const total: any = {
      i: filter.i
        .map((q: any) => q.answer)
        .reduce((q1: any, q2: any) => q1 + q2, 0),
      e: filter.e
        .map((q: any) => q.answer)
        .reduce((q1: any, q2: any) => q1 + q2, 0),
    };

    //OBETNER EL TOTAL GENERAL
    const tg = total.e + total.i;

    // OBTENER LA TABLA DE BAREMO DE UNA EDAD ESPECÍFICA
    const baremo = baremoJson.filter((q: any) => q.age == age + ` AÑOS`);

    if (sex == `FEMENINO`) {
      const rg = baremo[0].baremoWomen.filter((q) => q.g <= tg);
      const ri = baremo[0].baremoWomen.filter((q) => q.i <= total.i);
      const re = baremo[0].baremoWomen.filter((q) => q.e <= total.e);
      percentilg = rg[rg.length - 1].percentil;
      percentili = ri[ri.length - 1].percentil;
      percentile = re[re.length - 1].percentil;
    } else {
      const rg = baremo[0].baremoMan.filter((q) => q.g <= tg);
      const ri = baremo[0].baremoMan.filter((q) => q.i <= total.i);
      const re = baremo[0].baremoMan.filter((q) => q.e <= total.e);
      percentilg = rg[rg.length - 1].percentil;
      percentili = ri[ri.length - 1].percentil;
      percentile = re[re.length - 1].percentil;
    }

    //REGLA UNIVERSAL GENERAL
    let rug = ``;
    if (percentilg < 30) {
      rug = `BAJO`;
    } else if (percentilg >= 30 && percentilg < 70) {
      rug = `MEDIO`;
    } else if (percentilg >= 70) {
      rug = `ALTO`;
    }

    //REGLA UNIVERSAL INTRINSECA
    let rui = ``;
    if (percentili < 30) {
      rui = `BAJO`;
    } else if (percentili >= 30 && percentili < 70) {
      rui = `MEDIO`;
    } else if (percentili >= 70) {
      rui = `ALTO`;
    }

    //REGLA UNIVERSAL EXTRINSECA
    let rue = ``;
    if (percentile < 30) {
      rue = `BAJO`;
    } else if (percentile >= 30 && percentile < 70) {
      rue = `MEDIO`;
    } else if (percentile >= 70) {
      rue = `ALTO`;
    }

    //ESTRUCTURA QUE SE INSERTARÁ EN LA BASE DE DATOS
    const data = {
      name: name,
      sex: sex,
      age: age + ` AÑOS`,
      g: tg,
      i: total.i,
      e: total.e,
      percentilg: percentilg,
      percentili: percentili,
      percentile: percentile,
      rug: rug,
      rui: rui,
      rue: rue,
    };

    localStorage.setItem(`testMotivationResult`, JSON.stringify(data));

    const response = await API.request({
      method: `post`,
      url: endpoints.setTestMotivation,
      hasToken: true,
      data: data,
    });

    if (response?.data?.status !== `success`) {
      throw new Error();
    }

    localStorage.removeItem(`testMotivationName`);
    localStorage.removeItem(`testMotivationSex`);
    localStorage.removeItem(`testMotivationAge`);
    localStorage.removeItem(`testMotivationCurrentIdQuestion`);
    localStorage.removeItem(`testMotivationArrQuestion`);
    localStorage.removeItem(`testMotivationStep`);

    Toast({
      type: `success`,
      message: `Test realizado correctamente.`,
    });

    setStepTest(`result`);
    setLoading(false);
  } catch {
    localStorage.setItem(`testMotivationStep`, `question`);
    setStepTest(`question`);
  } finally {
    setLoading(false);
  }
};
