import { useLayoutEffect, useState } from "react";
import { Provider } from "react-redux";
import store from "redux/store";
import Routes from "routes";
import { validateAuth } from "tools/functions";

const App = () => {
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    validateAuth(setLoading);
  }, []);

  if (loading) return <div />;

  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
};

export default App;
