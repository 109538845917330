import { Button, Icon } from "components/atoms";
import { useEffect, useState } from "react";
import { Col, Row } from "../grid";
import { IAccordion } from "./props";
import "./styles.scss";

const AccordionUnits = ({
  items,
  idUnitCurrent,
  idTopicCurrent,
}: IAccordion) => {
  const [selected, setSelected] = useState(idUnitCurrent);

  const onToggle = (i: any) => {
    /*if (selected === i) {
      return setSelected(0);
    }*/
    setSelected(i);
  };

  useEffect(() => {
    setSelected(idUnitCurrent);
  }, [idUnitCurrent]);

  return (
    <div className="accordionUnit">
      <h4 className="content-units_title fs-subTitle">CONTENIDO</h4>
      {items.map((item: any, index: any) => (
        <div key={index} className="item">
          <div
            key={index}
            className="title collapse-btn bg-yellow ff-secondary fs-16px p-1rem fw-400"
            onClick={() => onToggle(item.idUnit)}
          >
            UNIDAD {index + 1}: {item.unitTitle}
            <Icon
              className={`toggle ${selected === item.idUnit ? `open` : ``}`}
              name="down"
              list="arrows"
              color="black"
              width={20}
            />
          </div>
          <div
            className={`${
              selected === item.idUnit ? `content show` : `content`
            }`}
          >
            {item.topicContent.map((sitem: any, sindex: any) => (
              <div key={sindex}>
                <Button
                  redirect={`/curso/${item.idCourse}/topico/${sitem.idTopic}`}
                  redirectExternal={true}
                  bgColor="silverLight"
                  txtColor="black"
                  bgHover="silver"
                  className="content-units_option w-100por ff-primary"
                >
                  <Row className="content-units_topics">
                    {/*<Col lg="4" md="4" sm="4" className="content-units_Col">
                      <div className="content-units_video">
                        <iframe
                          src="https://player.vimeo.com/video/715778106?h=d70d55ce67&ampbadge=0&ampautopause=0&ampplayer_id=0&ampapp_id=58479"
                          className=""
                          title=""
                        ></iframe>
                      </div>
                    </Col>*/}
                    <Col
                      lg="12"
                      md="12"
                      sm="12"
                      className="content-units_Col d-flex fd-column ai-left jc-center fs-16px"
                    >
                      <p>{sitem.title} </p>
                      {idTopicCurrent == sitem.idTopic ? (
                        <span className="t-red fs-14px">
                          <i>(Reproduciendo ahora)</i>
                        </span>
                      ) : null}
                      {/*<p>
                        <span>
                          <Icon list="items" name="time-line" color="black" />
                        </span>
                        {item.duration}
                      </p>*/}
                    </Col>
                  </Row>
                </Button>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AccordionUnits;
