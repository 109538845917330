import images from "assets/images";
import { Layout } from "components/organisms";
import { useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Speaker, Trailer } from "./childrens";
import { ASpeaker } from "./childrens/speaker/props";
import { ATrailer } from "./childrens/trailer/props";
import UnitsContent from "./childrens/unitsContent";
import { FCourse } from "./props";
import { getCourse } from "./services";

const Course = () => {
  const { idCourse } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<FCourse>({
    idCourse: ``,
    titleCourse: ``,
    description: ``,
    unitsContent: [],
    firstUnit: [],
    trailer: ``,
    speaker: [],
  });

  const [dataTrailer, setDataTrailer] = useState<ATrailer>({
    idCourse: ``,
    titleCourse: ``,
    description: ``,
    trailer: ``,
  });

  const [dataSpeaker, setDataSpeaker] = useState<ASpeaker>({
    speakerId: ``,
    name: ``,
    about: ``,
    cover: ``,
    calification: 0,
    numberCourses: 0,
  });

  const [dataUnitsContent, setDataUnitsContent] = useState([]);
  const [dataFirstUnit, setDataFirstUnit] = useState([]);

  useLayoutEffect(() => {
    getCourse({
      idCourse,
      setLoading,
      setDataTrailer,
      setDataSpeaker,
      setDataFirstUnit,
      setDataUnitsContent,
    });
  }, []);

  const [means, setMeans] = useState([
    {
      id: 1,
      pdf: `resume.pdf`,
      title: `Resumen`,
      img: images.Resume,
    },
    {
      id: 2,
      pdf: `worksheet.pdf`,
      title: `Hoja de Trabajo`,
      img: images.Worksheet,
    },
    {
      id: 3,
      pdf: `solver.pdf`,
      title: `Solucionario`,
      img: images.Solver,
    },
    {
      id: 4,
      pdf: `mindMap.pdf`,
      title: `Mapa mental`,
      img: images.MindMap,
    },
  ]);

  const [certificate, setCertificate] = useState(``);

  return (
    <Layout>
      <Trailer
        loading={loading}
        dataTrailer={dataTrailer}
        dataUnitsContent={dataUnitsContent}
      />
      <Speaker loading={loading} dataSpeaker={dataSpeaker} />
      <UnitsContent
        loading={loading}
        idCourse={idCourse}
        dataUnitsContent={dataUnitsContent}
        dataFirstUnit={dataFirstUnit}
      />
    </Layout>
  );
};

export default Course;
