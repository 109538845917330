import images from "assets/images";
import { Col, Container, Row } from "components/molecules/grid";
import "./styles.scss";

const SectionTwo = () => {
  return (
    <section className="bg-yellow">
      <Container>
        <Row className="m-0rem p-0rem">
          <Col lg="4" className="ai-flex-start">
            <div className="pt-3rem pb-3rem">
              <div className="h1 listos t-black desktop ">
                ¡EN <img src={images.MabRed} className="img-mab-red" /> <br />
                ESTAMOS
                <br /> LISTOS!
              </div>
              <div className="h1 listos t-black mobile">
                ¡EN <img src={images.MabRed} className="img-mab-red" /> <br />
                ESTAMOS LISTOS!
              </div>

              <p className="listos-st t-black fs-18px c-lh--1-3 mt-1">
                Nuestra plataforma <b>aprende MAB</b> propone <br /> un balance
                entre las{` `}
                <b>
                  habilidades duras y <br /> las habilidades blandas.
                </b>
                {` `}
                <br />
                Nuestro contenido integra lo{` `}
                <b>
                  emocional,
                  <br /> con lo académico y creativo.
                </b>
              </p>
            </div>
          </Col>
          <Col lg="8" className="ai-center pb-2">
            <div className="d-flex jc-center h-100por ">
              <img src={images.LandingMain} className="img-main w-85por" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SectionTwo;
