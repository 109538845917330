export default {
  landing: `/`,
  us: `/nosotros`,
  cart: `/carrito`,
  school: `/escuela-de-hoy`,
  projects: `/proyectos`,
  bookAndPlay: `/libro-y-juego`,
  donations: `/donaciones`,
  complaintsBook: `/libro-de-reclamaciones`,

  login: `/acceso`,
  register: `/registro`,
  recoveryCredential: `/recuperar-credencial`,
  generatePassword: `/recuperacion-contrasena`,
  verefiedEmail: `/sign-up/verified-email`,

  myCourses: `/mis-cursos`,

  courses: `/cursos`,
  course: `/curso`,
  topic: `/topico`,
  speaker: `/speaker`,

  myProfile: `/mi-perfil`,

  testMotivation: `/test/motivacion`,
  testPersonality: `/test/personalidad`,
};
