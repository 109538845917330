import API from "constants/api";
import endpoints from "constants/endpoints";
import { IHandleStepResult } from "./props";

const numberToLetterAlphabet = (number: number) => {
  const arrAlphabet = [`A`, `B`, `C`, `D`, `E`, `F`];
  return arrAlphabet[number];
};

const isCorrect = (right: any, answer: any) => {
  if (right == answer) {
    return true;
  } else {
    return false;
  }
};

const getOption = (options: any, answer: any) => {
  return numberToLetterAlphabet(answer) + `) ` + options[answer - 1].option;
};

export const handleStepResult = async ({
  setLoading,
  idCourse,
  idTopic,
  setStepReview,
  setDataReviewSatisfactory,
}: IHandleStepResult) => {
  try {
    setLoading(true);
    let correct = 0;
    let incorrect = 0;

    const storageQuestions: any = localStorage.getItem(`reviewArrQuestion`);
    const questions = JSON.parse(storageQuestions);

    //VERIFICAR LOS CORRECTOS E INCORRECTOS
    questions.map((item: any) => {
      if (item.right == item.answer) {
        correct = correct + 1;
      } else {
        incorrect = incorrect + 1;
      }
    });

    //LÓGICA DE APROBACIÓN
    if (correct >= 5) {
      //ENVIAR
      const payload = {
        idCourse: idCourse,
        idTopic: idTopic,
        correct: correct,
        incorrect: incorrect,
      };

      const response = await API.request({
        method: `post`,
        url: endpoints.setTopicReview,
        data: payload,
        hasToken: true,
      });

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }

      setDataReviewSatisfactory(response?.data?.data);
      setStepReview(`reviewSatisfactory`);
      localStorage.removeItem(`reviewArrQuestion`);
      localStorage.removeItem(`reviewCurrentIdQuestion`);
      window.location.reload();
    } else {
      setStepReview(`reviewUnsatisfactory`);
      const reviewUnsatisfactory: any = [];
      questions.map((item: any) => {
        reviewUnsatisfactory.push({
          question: item.id + `.- ` + item.title,
          option: getOption(item.options, item.answer),
          result: isCorrect(item.right, item.answer),
        });
      });
      localStorage.setItem(
        `reviewFeedBack`,
        JSON.stringify(reviewUnsatisfactory)
      );
    }

    setLoading(false);
  } catch {
    setStepReview(`question`);
  } finally {
    setLoading(false);
  }
};
