import { useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import "./styles.scss";

// COMPONENTS
import Icon from "../icon";
import { ICombobox } from "./props";

const ComboBox = ({
  labeltext,
  listOptions,
  changeValue,
  selectedtext,
  defaultText,
  htmlfor,
  icon,
  classInput,
  classListGroup,
  classIcon,
  hasSearch,
  required,
  messageError,
}: ICombobox) => {
  // DECLARACIÓN DE ESTADO PARA SETEAR EL VALOR SELECCIONADO DEL COMBO BOX
  const [searchSelected, setSearchSelected] = useState(selectedtext);
  const [searchOption, setSearchOption] = useState(``);
  const [openList, setOpenList] = useState(false);

  const handleClickOutside = () => setOpenList(false);
  const ref = useDetectClickOutside({ onTriggered: handleClickOutside });

  // EVENTO PARA SETEAR LOS VALORES DEL COMBOBOX
  const onOptionClicked = (id: any, option: any) => {
    changeValue(id);
    setOpenList(false);
    setSearchOption(``);
    setSearchSelected(option);
  };

  const onOptionClosed = () => {
    // IMPORTANT ! REFACTOR A NULL
    changeValue({ id: 0 });
  };

  // RENDERIZAR LOS DATOS DEL COMBOBOX
  const renderOptionList = () => (
    <div className={`list-group-combobox ${classListGroup}`}>
      {listOptions
        .filter((item: any) => {
          if (!hasSearch) return item;
          return item.option.toLowerCase().includes(searchOption.toLowerCase());
        })
        .map((item: any, index: any) => (
          <button
            onClick={() => onOptionClicked(item.id, item.option)}
            id={item.id}
            key={index}
            className="btnItem"
          >
            {item.option}
          </button>
        ))}
    </div>
  );

  return (
    <>
      <div ref={ref} className="ComboBox-flex-column">
        <label className="txt-none" htmlFor={htmlfor}>
          {labeltext}
          {` `}
        </label>
        <div className="ComboBox">
          <div
            onClick={() => {
              setOpenList(!openList);
            }}
            className={`ComboBox__toggle`}
          >
            <input
              type="text"
              placeholder={selectedtext || defaultText}
              value={searchSelected}
              className={`ComboBox-input ComboBox-Collapse fs-16px ff-primary ${classInput}`}
              disabled
            />

            <Icon
              list="arrows"
              name="down"
              className={`ComboBox-iconArrow ${classIcon}`}
            />
          </div>

          {/* {searchSelected !== `` && (
            <Button
              className="ComboBox-iconClear zi-100 d-flex ai-center btn-padding h-txt-red"
              onClick={() => {
                setSearchSelected(``);
                onOptionClosed();
              }}
            >
              <Icon icon="ep:close-bold" className="fs-s" />
            </Button>
          )}*/}

          {openList && (
            <div className={`ComboBox__content ComboBox__content-listgroup`}>
              {hasSearch && (
                <div
                  className={`ComboBox__toggle p-sticky top-0 zi-100 bg-white bb-1-silver`}
                >
                  <input
                    type="text"
                    placeholder="Buscar..."
                    value={searchOption}
                    className={`ComboBox-input-intro ComboBox-Collapse-Search ${classInput}`}
                    onKeyDown={(e) => {
                      e.code !== `Tab` && setOpenList(true);
                    }}
                    onChange={(e) => {
                      setSearchOption(e.target.value);
                    }}
                  />
                  {/*} <Icon
                    icon="material-symbols:manage-search-rounded"
                    className={`icon-input txt-blue`}
                  />*/}
                </div>
              )}
              {renderOptionList()}
            </div>
          )}
        </div>
        {messageError && <p className="error">{messageError}</p>}
      </div>
    </>
  );
};

ComboBox.defaultProps = {
  hasSearch: true,
};

export default ComboBox;
