import images from "assets/images";
import { Button, Icon } from "components/atoms";
import { useState } from "react";
import { Step } from "../../../../components/molecules";
import "../../styles.scss";
import { IStep2 } from "./props";
import "./styles.scss";

const Step2 = ({ setStep }: IStep2) => {
  const [loading, setLoading] = useState(false);

  const [steps, setSteps] = useState([
    {
      number: 1,
      currentActive: true,
    },
    {
      number: 2,
      currentActive: true,
    },
    {
      number: 3,
      currentActive: false,
    },
  ]);

  const handleStep2 = (role: any) => {
    setLoading(true);
    localStorage.setItem(`role`, btoa(role));

    if (role == `representative`) {
      localStorage.setItem(`step`, `3b`);
      setStep(`3b`);
    } else {
      localStorage.setItem(`step`, `3a`);
      setStep(`3a`);
    }

    setLoading(false);
  };

  const onBack = () => {
    setStep(`1`);
    localStorage.setItem(`step`, `1`);
  };

  return (
    <section className="register bgImage2">
      <div className="py-1-5rem">
        <Step steps={steps} />
      </div>
      <div className="register-content">
        <div className="card w-450px mx-1rem mb-2rem pb-1rem">
          <div className="head bg-yellow d-flex ac-ai-jc-center">
            <button
              className="button-return bg-yellow t-black hov-t-red d-flex ai-center jc-center"
              onClick={() => onBack()}
            >
              <Icon
                list="arrows"
                name="left"
                width={20}
                height={20}
                className="ff-secondary fw-900"
              ></Icon>
            </button>
            <span className="fs-20px fw-400 ff-secondary ml-5rem mr-7rem">
              ESCOGE UNA OPCIÓN
            </span>
          </div>

          <div className="body">
            <div className="py-3rem">
              <img className="imgPerfil" src={images.Student} />
              <Button
                className="bg-yellowLigth t-black hov-bg-yellow hov-t-red hov-t-red br-25px ff-secondary fs-18px fw-900 w-75por"
                text="Soy estudiante"
                onClick={() => handleStep2(`student`)}
              />
            </div>

            <div className="pb-3rem">
              <img className="imgPerfil" src={images.Teacher} />
              <Button
                className="bg-yellowLigth t-black hov-bg-yellow hov-t-red hov-t-red br-25px ff-secondary fs-18px fw-900 w-75por"
                text="Soy docente"
                onClick={() => handleStep2(`teacher`)}
              />
            </div>

            <div className="pb-3rem">
              <img className="imgPerfil" src={images.Representative} />
              <Button
                className="bg-yellowLigth t-black hov-bg-yellow hov-t-red hov-t-red br-25px ff-secondary fs-18px fw-900 w-75por"
                text="Soy mamá, papá o apoderado"
                onClick={() => handleStep2(`representative`)}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Step2;
