import { useEffect, useRef, useState } from "react";
import { ITooltip } from "./props";
import "./styles.scss";

const Tooltip = ({
  text,
  reference,
  position,
}: ITooltip) => {
  const refTooltip = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)

  const showTooltip = () => setOpen(true)
  const hideTooltip = () => setOpen(false)

  useEffect(() => {
    const father = reference.current

    if(!father) return

    father.addEventListener('mouseover', showTooltip)
    father.addEventListener('mouseleave', hideTooltip)

    return () => {
      father.removeEventListener('mouseover', showTooltip)
      father.removeEventListener('mouseleave', hideTooltip)
    } 
  }, [])

  useEffect(() => {
    const father = reference.current

    if(!father) return

    if(refTooltip.current){
      if(position === 'right'){
        refTooltip.current.style.top = `${father.offsetTop + father.offsetHeight / 2 - refTooltip.current.offsetHeight / 2}px`
        refTooltip.current.style.left = `${father.offsetLeft + father.offsetWidth}px`
        refTooltip.current.style.marginLeft = '5px'
      }

      if(position === 'bottom'){
        refTooltip.current.style.top = `${father.offsetTop + father.offsetHeight}px`  
        refTooltip.current.style.left = `${father.offsetLeft + father.offsetWidth / 2 - refTooltip.current.offsetWidth / 2}px`
        refTooltip.current.style.marginTop = '5px'
      }

      if(position === 'top'){
        refTooltip.current.style.top = `${father.offsetTop - refTooltip.current.offsetHeight - 5}px`
        refTooltip.current.style.left = `${father.offsetLeft + father.offsetWidth / 2 - refTooltip.current.offsetWidth / 2}px`
      }

      if(position === 'left'){
        refTooltip.current.style.top = `${father.offsetTop + father.offsetHeight / 2 - refTooltip.current.offsetHeight / 2}px`
        refTooltip.current.style.left = `${father.offsetLeft - refTooltip.current.offsetWidth - 5}px`
        refTooltip.current.style.marginRight = '5px'
      }
    }
  }, [open])

  return (
    <>
      {
        open && (
          <div
            ref={refTooltip}
            className="tooltip"
          >
            {text}
          </div>
        )
      }
    </>
  )
}

Tooltip.defaultProps = {
  position: 'right',
}

export default Tooltip