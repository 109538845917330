const endpoints = {
  getCourses: `/v2/course`,
  getCourse: `/v2/course/overview`,
  getTopics: `/v2/unit`,
  getTopic: `/v2/topic`,
  getTopicLikes: `/v1/likes/topic`,
  getTopicsContent: `/v2/topic/content`,
  getSpeaker: `/v2/speaker`,
  login: `/v1/account/sign-in`,
  loginGoogle: `/v1/account/login/google`,
  loginRecoveryCretendial: `/v1/account/recovery-credential`,
  loginGeneratePassword: `/v1/account/generate-password`,
  register: `/v1/account/sign-up`,
  getTestPersonality: `/v2/test/personality`,
  setTestPersonality: `/v2/test/personality`,
  getTestMotivation: `/v2/test/motivation`,
  setTestMotivation: `/v2/test/motivation`,
  getProfile: `/v2/user/profile`,
  getProfileCourses: `/v2/courses/enrolled`,
  setProfileInformation: `/v2/user/profile`,
  setProfileAbout: `/v2/user/profile`,
  setTopicLikes: `/v1/likes/topic`,
  setTopicReview: `/v2/topic/review`,
  getTopicReview: `/v2/topic/review`,
  generateCertificate: `/v1/certificate/render`,
};

export default endpoints;
