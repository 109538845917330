import { Col, Row } from "components/molecules/grid";
import { IRadioButtonOption } from "./props";
import "./styles.scss";

const RadioButtonOption = ({
  name,
  id,
  text,
  value,
  checked,
  onChange,
  onBlur,
  className,
  classNameLabel,
  disabled,
}: IRadioButtonOption) => {
  return (
    <Row className="py-0-5rem">
      <Col lg="1" col="2">
        <input
          type="radio"
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          checked={checked}
          onBlur={onBlur}
          className={`${className}`}
          disabled={disabled}
        />
      </Col>
      <Col lg="11" col="10">
        <label
          id={`label-${id}`}
          htmlFor={id}
          className={` fs-16px  ${classNameLabel}`}
        >
          {text}
        </label>
      </Col>
    </Row>
  );
};

export default RadioButtonOption;
