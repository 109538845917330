import { Toast } from "components/atoms";
import API from "constants/api";
//import endpoints from "constants/endpoints";
import endpoints from "constants/endpoints";
import { setAuthenticated } from "redux/actions/auth";
import { IHandleStep1 } from "./props";

export const handleGoogleSignIn = async (dataGoogleAuth: {
  credential: string;
}) => {
  try {
    const params = {
      token: dataGoogleAuth.credential,
      url: endpoints.loginGoogle,
    };

    const response = await API.request({
      url: endpoints.loginGoogle,
      method: `post`,
      data: params,
    });

    if (response?.data?.status !== `success`) {
      throw new Error();
    }

    localStorage.setItem(`token`, response.data.data.token);
    setAuthenticated(true);

    Toast({
      type: `success`,
      message: `Inicio exitoso`,
    });
  } catch (error: any) {
    Toast({
      type: `error`,
      message: error?.message || `No se pudo iniciar sesión con Google`,
    });
  }
};

export const handleStep1 = ({ setLoading, values, setStep }: IHandleStep1) => {
  try {
    setLoading(true);

    if (values.password != values.passwordRepeat) {
      Toast({
        type: `error`,
        message: `Las contraseñas no coinciden`,
      });
      throw new Error();
    }

    localStorage.setItem(`names`, btoa(values.names));
    localStorage.setItem(`lastNames`, btoa(values.lastNames));
    localStorage.setItem(`email`, btoa(values.email));
    localStorage.setItem(`password`, btoa(values.password));
    localStorage.setItem(`step`, `2`);
    setStep(`2`);
  } catch (error: any) {
    setLoading(false);
  }
};
