import "./styles.scss";

import { Button, Icon } from "components/atoms";
import { Col, Row } from "components/molecules/grid";

import { Collapse2 } from "components/molecules";
import { IUnits } from "./props";
import { getTopics } from "./services";

const Units = ({ idCourse, unitsContent, firstUnit, className }: IUnits) => {
  interface iUnitContent {
    idTopic: string;
    bodyTopic: string;
    title: string;
    videoTopic: string;
  }
  const allUnits = firstUnit ? [firstUnit] : [];

  const renderTopics = async (idUnit: any, index: number) => {
    if (index === 0 && firstUnit) return renderContent(firstUnit);
    if (allUnits[index]) return renderContent(allUnits[index]);
    const unit = (await getTopics({ idUnit: idUnit }))[0];
    allUnits[index] = unit.topics;
    return renderContent(unit.topics);
  };
  const formatDuration = (duration: number) => {
    if (!duration) return `0:00`;
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    return `${minutes} minutos y ${seconds} segundos.`;
  };
  const renderContent = (unitContent: iUnitContent[] | undefined) => {
    if (!unitContent) return <></>;
    return unitContent.map((topic: any, tIndex: any) => (
      <div key={tIndex}>
        <Button
          redirect={`/curso/${idCourse}/topico/${topic.idTopic}`}
          bgColor="silverLight"
          txtColor="black"
          bgHover="silver"
          className="content-units_option w-100por ff-primary"
        >
          <Row className="content-units_topics ">
            <Col lg="4" md="4" sm="4" className="content-units_Col">
              <div className="content-units_video">
                <iframe
                  src={`https://player.vimeo.com/video/${
                    topic.videoTopic.split(`/`)[3]
                  }?h=${topic.videoTopic.split(`/`)[4]}`}
                  className=""
                  allow="autoplay; fullscreen; picture-in-picture"
                  title=""
                ></iframe>
              </div>
            </Col>
            <Col
              lg="8"
              md="8"
              sm="8"
              className="content-units_Col d-flex fd-column ai-left jc-center"
            >
              <p className="fw-bold pt-1rem">{topic.title} </p>
              <p className="fs-16px pt-1rem">{topic.bodyTopic}</p>
              <p className="fs-14px pt-1rem">
                <span>
                  <Icon list="items" name="time-line" color="black" />
                </span>
                {formatDuration(topic.duration)}
              </p>
            </Col>
          </Row>
        </Button>
      </div>
    ));
  };

  return (
    <section className={`${className}`}>
      <div className="content-units">
        <h4 className="content-units_title fs-subTitle">CONTENIDO</h4>
        <div>
          {unitsContent.map((unit: any, index: any) => (
            <Collapse2
              text={`UNIDAD ${index + 1}: ${unit.title}`}
              className={`content-units_subTitle fs-description`}
              classNameImg={`content-units_img`}
              key={unit.idUnit}
              collapsed={index === 0 ? true : false}
              extraOnShowCollapse={
                index != 0 ? () => renderTopics(unit.idUnit, index) : null
              }
            >
              {index === 0 ? renderContent(firstUnit) : null}
            </Collapse2>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Units;
