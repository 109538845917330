import images from "assets/images";
import { Col, Container, Row } from "components/molecules/grid";
import { Link } from "react-router-dom";
import { ICourses } from "./props";
import "./styles.scss";
// Import Swiper styles
import { Button, Progress } from "components/atoms";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const Courses = ({ dataCourses }: ICourses) => {
  const percentage = (
    numberOfTopics: number,
    numberOfTopicsApproved: number
  ) => {
    let percentage = 0;
    if (numberOfTopics == 0) {
      percentage = 0;
    } else {
      percentage = (numberOfTopicsApproved / numberOfTopics) * 100;
    }

    return percentage;
  };

  console.log('dataCourses',dataCourses)

  const onDownloadCertificate = () => {
    console.log('aqui')
  }

  return (
    <Row className="pt-3rem">
      <Col lg="12">
        <div className="course">
          <div className="course-title bg-black d-flex jc-center ai-center">
            <span className="t-white ff-secondary fs-19px">MIS CURSOS</span>
          </div>

          {dataCourses.length != 0 ? (
            <div className="course-content h-600px bg-white  px-1rem pb-1rem">
              <Container>
                {dataCourses.map((item: any, index: any) => (
                  <>
                    <Row key={index} className="pt-1rem">
                      <Col
                        lg="2"
                        col="3"
                        className="d-flex ai-center jc-center"
                      >
                        <img src={item.thumbnail} className="w-90por"></img>
                      </Col>
                      <Col lg="8" col="7">
                        <Container className="ai-center jc-center">
                          <Row className="pt-1rem pb-0-5rem">
                            <Col lg="10" col="9" className="fs-14px fw-bold">
                              {item.name}
                            </Col>
                            <Col
                              lg="2"
                              col="3"
                              className="ta-right fs-14px fw-bold"
                            >
                              {percentage(
                                item.numberOfTopics,
                                item.numberOfTopicsApproved
                              ).toFixed(0)}
                              %
                            </Col>
                          </Row>
                          <Row className="pt-0-5rem pb-1rem">
                            <Col lg="12">
                              <Progress
                                ariaValueMax={100}
                                ariaValueNow={percentage(
                                  item.numberOfTopics,
                                  item.numberOfTopicsApproved
                                )}
                                ariaValueMin={0}
                              ></Progress>
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                      <Col
                        lg="2"
                        col="2"
                        className="d-flex ai-center jc-center"
                      >
                        {item.certificate === true ? (
                          <>
                            <Button
                            text=""
                            iconList="items"
                            iconName="download"
                            className="mobile"
                            bgColor="magenta"
                            txtColor="black"
                            />

                            <Button
                              text='Descargar certificado'
                              bgColor='magenta'
                              txtColor="black"
                              className="ff-primary fs-14px fw-bold desktop ta-center"  

                            />
                          </>
                          
                        ) : (
                          <>
                            <Button
                              text=""
                              iconList="arrows"
                              iconName="right"
                              className="mobile"
                              bgColor="cyan"
                              txtColor="black"
                            />

                            <Button
                            text='Continuar curso'
                            bgColor='cyan'
                            txtColor="black"
                            className="ff-primary fs-14px fw-bold desktop ta-center"
                            redirect={`/curso/${item.idCourse}/topico/${item.lastIdTopic}`}
                            redirectExternal={true}
                            
                            />
                          </>
                        )}
                        
                      </Col>
                    </Row>
                    <Row className="pt-1rem">
                      <Col lg="12" className="d-flex ai-center jc-center">
                        <hr className="hr" />
                      </Col>
                    </Row>
                  </>
                ))}
              </Container>
            </div>
          ) : (
            <div className="course-content h-100px bg-white d-flex fd-column jc-center ai-center px-1rem pb-1rem">
              <div className="d-flex jc-center ai-center">
                <span className="fs-14px">
                  Aún no has comenzando ningún curso, <br /> ¿Qué esperas?,
                  estás a un click de aprender <br /> nuevas cosas.
                </span>
                <img className="ml-1rem w-10por" src={images.HeartVector}></img>
              </div>
              <Link
                to="/cursos"
                className="fs-14px t-cyan fw-bold td-underline"
              >
                Ir a cursos
              </Link>
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default Courses;
