import images from "assets/images";
import { Button } from "components/atoms";
import { Col, Row } from "components/molecules/grid";
import { useLayoutEffect, useState } from "react";
import { getResultMotivation, getResultPersonality } from "./services";
import "./styles.scss";

const Tests = () => {
  const [loading, setLoading] = useState(false);
  const [isTestPersonality, setIsTestPersonality] = useState(false);
  const [isTestMotivation, setIsTestMotivation] = useState(false);

  useLayoutEffect(() => {
    getResultPersonality(setLoading, setIsTestPersonality);
    getResultMotivation(setLoading, setIsTestMotivation);
  }, []);

  const onTestPersonality = () => {
    window.location.href = `/test/personalidad`;
  };

  const onTestMotivation = () => {
    window.location.href = `/test/motivacion`;
  };
  return (
    <Row>
      <Col lg="6" className="pt-3rem">
        <div className="d-flex fd-column jc-center ai-center">
          <div className="fs-19px fw-bold py-2rem">Test de Personalidad</div>
          <div className="d-flex jc-center ai-center py-1rem">
            <img src={images.LandingTest1} className="w-75por" />
          </div>
          <div className="py-2rem">
            <Button
              bgColor={isTestPersonality ? `yellow` : `black`}
              txtColor={isTestPersonality ? `black` : `white`}
              bgHover={isTestPersonality ? `green` : `green`}
              txtHover="white"
              className="ff-primary fw-900 fs-18px"
              onClick={onTestPersonality}
              loading={loading}
              text={isTestPersonality ? `Ver Resultado` : `Tomar Test`}
            />
          </div>
        </div>
      </Col>
      <Col lg="6" className="pt-3rem">
        <div className="d-flex fd-column jc-center ai-center">
          <div className="fs-19px fw-bold py-2rem">Test de Motivación</div>
          <div className="d-flex jc-center ai-center py-1rem">
            <img src={images.LandingTest2} className="w-75por" />
          </div>
          <div className="py-2rem">
            <Button
              bgColor={isTestMotivation ? `yellow` : `black`}
              txtColor={isTestMotivation ? `black` : `white`}
              bgHover={isTestMotivation ? `cyan` : `cyan`}
              txtHover="white"
              className="ff-primary fw-900 fs-18px"
              onClick={onTestMotivation}
              loading={loading}
              text={isTestMotivation ? `Ver Resultado` : `Tomar Test`}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Tests;
