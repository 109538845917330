import images from "assets/images";
import { Col, Container, Row } from "components/molecules/grid";
import "./styles.scss";

const SectionSponsor = () => {
  return (
    <div>
      <section className="bg-yellow t-black py-2rem">
        <Container className="py-2rem align-center-middle">
          <Row className="ai-center ta-center m-0rem p-0rem">
            <Col lg="12">
              <span className="fs-40px fw-bold">Nuestros sponsors</span>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bg-white t-black py-2rem">
        <Container className="py-3rem align-center-middle ">
          <Row className="ai-center m-0rem p-0rem">
            <Col lg="3">
              <img src={images.LandingSponsor8v} className="w-100por h-90por" />
            </Col>
            <Col lg="9">
              <Container>
                <Row className="ai-center pb-2rem m-0rem">
                  <Col lg="3">
                    <img src={images.LandingSponsor1} className="w-100por" />
                  </Col>
                  <Col lg="3">
                    <img src={images.LandingSponsor6} className="w-100por" />
                  </Col>
                  <Col lg="3">
                    <img src={images.LandingSponsor7} className="w-100por" />
                  </Col>
                  <Col lg="3">
                    <img src={images.LandingSponsor3} className="w-100por" />
                  </Col>
                </Row>
                <Row className="ai-center pb-2rem m-0rem">
                  <Col lg="3">
                    <img src={images.LandingSponsor9} className="w-100por" />
                  </Col>
                  <Col lg="3">
                    <img src={images.LandingSponsor12} className="w-100por" />
                  </Col>
                  <Col lg="3">
                    <img src={images.LandingSponsor2} className="w-100por" />
                  </Col>
                  <Col lg="3">
                    <img src={images.LandingSponsor5} className="w-100por" />
                  </Col>
                </Row>
                <Row className="ai-center jc-center m-0rem p-0rem">
                  <Col lg="3">
                    <img src={images.LandingSponsor4} className="w-100por" />
                  </Col>
                  <Col lg="3">
                    <img src={images.LandingSponsor11} className="w-100por" />
                  </Col>
                  <Col lg="3">
                    <img src={images.LandingSponsor10} className="w-100por" />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default SectionSponsor;
