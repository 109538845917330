import images from "assets/images";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Button } from "components/atoms";
import { Col, Container, ContainerFluid, Row } from "components/molecules/grid";
import { Pie } from "react-chartjs-2";
import "./styles.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

const Result = () => {
  const testResult: any = JSON.parse(
    localStorage.getItem(`testMotivationResult`) || ``
  );

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: `bottom`,
      },
    },
  };

  const data = {
    labels: [`Intrínseca`, `Extrínseca`],
    datasets: [
      {
        label: `Percentil`,
        data: [testResult.percentili, testResult.percentile],
        backgroundColor: [`rgba(222, 13, 69)`, `rgba(249, 207, 28)`],
      },
    ],
  };

  return (
    <ContainerFluid className="test-motivation mt-60px">
      <Row className="bg-yellow pt-1rem">
        <Col lg="12">
          <div className="title">
            <img
              className="width-100 megaphone img-shadow"
              src={images.Megaphone}
              alt=""
            />
            <h2 className="tt-uppercase pb-1rem">
              <span className="fw-bold">Test de Motivación</span>
              <br />
              <span className="fw-bold">en el proceso de aprendizaje</span>
            </h2>
          </div>
        </Col>
      </Row>
      <Row className="bg-white">
        <Col lg="12">
          <Container className="contenedort">
            <div className="ta-center">
              <p className="fw-bold fs-25px pt-1rem">
                <span className="t-blue tt-uppercase">{testResult.name}, </span>
                <span className="t-black">gracias por completar el test.</span>
              </p>
              <p className="t-black fs-18px">
                Ahora puedes conocer un poco más de ti.
              </p>
            </div>

            <div className="card-contenedort p-2rem">
              <Row>
                <Col lg="4" className="ta-center">
                  <span className="t-granate fs-19px fw-bold">MOTIVACIÓN</span>
                  <div className="pt-2rem">
                    <Pie data={data} options={options} />
                  </div>
                </Col>
                <Col lg="8" className="d-flex ai-center jc-center">
                  <Container>
                    <Row className="d-flex ai-center jc-center">
                      <Col
                        lg="4"
                        md="6"
                        sm="12"
                        className="d-flex ai-center jc-center py-1rem"
                      >
                        <div className="cardt">
                          <div className="titlet bg-indigo t-white ff-secondary fs-21px ta-center">
                            GENERAL
                          </div>
                          <div className="contentt bg-white ff-secondary fs-21px">
                            <span>{testResult.rug}</span>
                          </div>
                        </div>
                      </Col>
                      <Col
                        lg="4"
                        md="6"
                        sm="12"
                        className="d-flex ai-center jc-center py-1rem"
                      >
                        <div className="cardt">
                          <div className="titlet bg-granate t-white ff-secondary fs-21px ta-center">
                            INTRÍNSECA
                          </div>
                          <div className="contentt bg-white ff-secondary fs-21px">
                            <span>{testResult.rui}</span>
                          </div>
                        </div>
                      </Col>
                      <Col
                        lg="4"
                        md="6"
                        sm="12"
                        className="d-flex ai-center jc-center py-1rem"
                      >
                        <div className="cardt">
                          <div className="titlet bg-yellow t-black ff-secondary fs-21px ta-center">
                            EXTRÍNSECA
                          </div>
                          <div className="contentt bg-white ff-secondary fs-21px">
                            <span>{testResult.rue}</span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </div>

            <div className="p-2rem w-75por">
              <Row>
                <Col
                  lg="4"
                  sm="12"
                  className="p-1rem d-flex ai-center jc-center"
                >
                  <Button
                    iconList="arrows"
                    iconName="back-alt"
                    txtColor="black"
                    txtHover="white"
                    bgColor="yellow"
                    bgHover="red"
                    text="Volver al perfil"
                    className={`ff-secondary fs-16px fw-bold ta-center px-2rem py-0-5rem br-25px w-100por`}
                    redirect="/mi-perfil"
                    //onClick={() => handlePrevious()}
                    //disabled={isBackDisabled}
                    iconPosition="left"
                    // rowReverse={true}
                  />
                </Col>
                {/*<Col
                  lg="6"
                  sm="12"
                  className="p-1rem d-flex ai-center jc-center"
                >
                  <Button
                    iconList="items"
                    iconName="download"
                    txtColor="black"
                    txtHover="white"
                    bgColor="cyan"
                    bgHover="red"
                    text="Descargar aquí"
                    className={`ff-secondary fs-16px fw-bold px-2rem py-0-5rem br-25px w-100por`}
                    //onClick={() => handlePrevious()}
                    //disabled={isBackDisabled}
                    iconPosition="right"
                    // rowReverse={true}
                  />
  </Col>*/}
              </Row>
            </div>
          </Container>
        </Col>
      </Row>
    </ContainerFluid>
  );
};

export default Result;
