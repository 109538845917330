import { Icon } from "components/atoms";
import { ITextArea } from "./props";
import "./styles.scss";

const TextArea = ({
  iconNameRight,
  iconNameLeft,
  iconList,
  onChange,
  placeholder,
  value,
  rows,
  readOnly,
  onKeyUp,
  onBlur,
  messageError,
  required,
  iconColor,
  iconLeft,
  iconRight,
  className,
  classContainer,
  size,
}: ITextArea) => {
  return (
    <div className="input ">
      <div className="inputWithIcon">
        {iconLeft === true && (
          <div className="iLeft d-flex ac-ai-jc-center">
            <Icon
              name={`${iconNameLeft}`}
              className={`ml-0-2rem`}
              list={iconList}
              color={iconColor}
              width={20}
              height={20}
            />
          </div>
        )}

        <textarea
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            onChange && onChange(e.target.value);
          }}
          value={value}
          autoComplete="none"
          readOnly={readOnly}
          required={required}
          onBlur={onBlur}
          onKeyUp={onKeyUp}
          className="textarea bg-white fs-16px ff-primary"
          placeholder={placeholder}
          rows={rows}
        >
          {value}
        </textarea>

        {iconRight === true && (
          <div className="iRight d-flex ac-ai-jc-center">
            <Icon
              name={`${iconNameRight}`}
              className={`mr-0-2rem`}
              list={iconList}
              color={iconColor}
              width={20}
              height={20}
            />
          </div>
        )}
      </div>

      {messageError && <p className="input_error">{messageError}</p>}
    </div>
  );
};

TextArea.defaultProps = {
  type: `text`,
  iconColor: `silver`,
  size: 52,
};

export default TextArea;
