import { MutableRefObject, useEffect, useState } from "react"
import { useDetectClickOutside } from "react-detect-click-outside"
import Icon from "../icon"
import { IOption, ISelect } from "./props"
import './styles.scss'

const Select = ({
  txtLabel,
  value,
  options,
  placeholder,
  onChange,
  className,
  size,
  width
}: ISelect) => {
  const [open, setOpen] = useState(false)
  const handleClickOutside = () => setOpen(false)
  const ref: MutableRefObject<HTMLDivElement | null> = useDetectClickOutside({ onTriggered: handleClickOutside })

  const handleSelectOption = (option: IOption) => {
    onChange?.(option.value)
    setOpen(false)
  }

  useEffect(() => {
    if(!open) return

    document.body.style.overflow = 'hidden'
    document.body.style.marginRight = '5px'

    const container = document.createElement('div')
    container.className = 'select-component-container'

    const element = document.createElement('div')
    element.className = 'content'

    container.appendChild(element)
    
    if(ref.current){
      const { top, left, width, height } = ref.current.getBoundingClientRect()

      const isBottom = window.innerHeight - top < 200
      if(isBottom){
        element.style.bottom = `${window.innerHeight - top}px`
      }else{
        element.style.top = `${top + height}px`
      }
  
      element.style.left = `${left}px`
      element.style.width = `${width}px`
    }

    options.forEach((option) => {
      const optionElement = document.createElement('div')
      optionElement.className = 'option'
      optionElement.addEventListener('click', () => handleSelectOption(option))
      optionElement.innerText = option.label
      element.appendChild(optionElement)
    })

    document.body.appendChild(container)

    return () => {
      document.body.style.overflow = 'auto'
      document.body.style.marginRight = '0px'
  
      const container = document.querySelector('.select-component-container')
      if(container){
        document.body.removeChild(container)
      }
    }
  }, [open])

  return (
    <div className={`select-component w-${width}px`}>
      {txtLabel &&
        <label className='label mb-05'>
          {txtLabel}
        </label>
      }

      <div
        ref={ref}
        className={`select h-${size}px ${className}`}
        onClick={() => setOpen(!open)}
      >
        <p className={value ? 'txt-gray' : 'txt-silverMedium'}>
          {options?.find((option) => option.value === value)?.label ?? placeholder}
        </p>

        <Icon
          name="down"
          className={`arrow ${open ? 'rotate' : ''}`}
          list="arrows"
          color='gray'
          width={16}
        />
      </div>
    </div>
  )
}

Select.defaultProps = {
  placeholder: 'Seleccione',
  size: 52,
}

export default Select