import { Toast } from "components/atoms";
import regexs from "constants/regexs";
import { useRef, useState } from "react";
import { IBanner } from "./props";
import "./styles.scss";

const Banner = ({ dataBanner, setDataBanner }: IBanner) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<Blob | MediaSource | [] | any>(
    dataBanner.banner
  );

  const returnSrc = () => {
    if (!image) return;
    if (typeof image === `string`) return image;
    return URL.createObjectURL(image);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = e.target.files?.[0];

      if (!file) throw new Error(`No se ha seleccionado un archivo`);

      if (!regexs.formatImage.test(file.type))
        throw new Error(`El archivo debe ser una imagen`);

      setImage(file);
    } catch (error: any) {
      Toast({
        type: `error`,
        message: error?.message || `Error al cargar la imagen`,
      });
    } finally {
      fileRef.current?.value && (fileRef.current.value = ``);
    }
  };

  return (
    <section className="user-banner bg-black">
      <div className="user-banner-content">
        <div className="user-banner-content-img">
          {image == `hola` ? (
            <div className="user-banner-text bg-orange">
              <h2>
                AQUÍ PUEDES COLOCAR UNA IMAGEN <br /> QUE TE IDENTIFIQUE
              </h2>
            </div>
          ) : (
            <img src={returnSrc()} alt="portada" className="w-100por" />
          )}

          {/*<Button
            className="user-banner-edit-btn"
            iconName="pencil"
            iconList="buttons"
            iconSize={24}
            txtColor="white"
            bgColor="turquoise"
            txtHover="black"
            onClick={() => fileRef.current?.click()}
          />

          <input
            ref={fileRef}
            className="d-none"
            type="file"
            onChange={handleChange}
            accept="image/png, image/jpg, image/jpeg, image/webp"
          />*/}
        </div>
      </div>
    </section>
  );
};

export default Banner;
