import { useEffect } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { Link } from "react-router-dom";
import { IDropdown } from "./props";
import "./styles.scss";

const Dropdown = ({
  typeOption,
  options,
  className,
  open,
  onClose,
  htmlBy,
}: IDropdown) => {
  const handleClose = () => open && onClose();

  const ref = useDetectClickOutside({ onTriggered: handleClose });

  const Component = typeOption === `link` ? Link : `a`;

  useEffect(() => {
    if (!open) return;

    const element = document.getElementById(htmlBy);
    if (element) {
      const { top, left, width, height } = element.getBoundingClientRect();

      if (ref.current) {
        // @ts-ignore
        ref.current.style.top = `${top + height + 20}px`;
        // @ts-ignore
        const widthOwn = ref.current.getBoundingClientRect().width;
        // @ts-ignore
        ref.current.style.left = `${left + width / 2 - widthOwn / 2}px`;
      }
    }
    return () => {
      onClose();
    };
  }, [open]);

  return (
    <div ref={ref} className={`dropdown ${open && `open`}`}>
      <div className={`content ${className}`}>
        {options.map((option, index) => (
          <Component
            key={index}
            className="option"
            to={option.redirect || ``}
            href={option.redirect || ``}
            onClick={option.onClick}
          >
            <p className={`text ${index !== options.length - 1 && `border`}`}>
              {option.label}
            </p>
          </Component>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
