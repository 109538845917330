import { ICheckbox } from "./props";
import "./styles.scss";

const Checkbox = ({
  name,
  text,
  checked,
  onChange,
  onBlur,
  className,
}: ICheckbox) => {
  return (
    <div>
      <label className={`input-checkbox ${className}`}>
        <div className="input-checkbox_content">
          <input
            type="checkbox"
            name={name}
            checked={checked}
            onChange={onChange}
            onBlur={onBlur}
          />
        </div>
        {text}
      </label>
    </div>
  );
};

export default Checkbox;
