import { Card, Skeleton } from "components/atoms";
import { Col, Row } from "components/molecules/grid";
import { IDataCourse } from "./props";
import "./styles.scss";

const Content = (props: any) => {
  const { search, page, data, paginationInfo, loading } = props;
  return (
    <div className="container w-100por">
      <div className="contentHead bg-white">
        {search == `` ? (
          <h1 className="t-black py-1rem px-1rem">TODOS LOS CURSOS</h1>
        ) : (
          <h1 className="t-black py-1rem">
            Término de búsqueda: <span className="t-red">{search}</span>
          </h1>
        )}
      </div>

      <div className="mt-60px">
        <Row className="d-flex jc-center ai-center m-0rem p-0rem">
          {loading ? (
            new Array(12).fill(``).map((item, index) => (
              <Col key={index} lg="3" sm="6" className="pb-2rem">
                <div className="" key={index}>
                  <Skeleton height="500px" dotColor="gray" />
                </div>
              </Col>
            ))
          ) : (
            <>
              {data.length > 0 ? (
                data.map((course: IDataCourse, index: any) => (
                  <Col key={index} lg="3" sm="6" className="pb-2rem m-0rem">
                    <Card
                      _id={course._id}
                      id={course.id}
                      name={course.name}
                      description={course.description}
                      duration={course.duration}
                      color={course.color}
                      thumbnail={course.thumbnail}
                      teacher={course.teacher}
                      price={course.price}
                      likes={course.likes}
                      link={course.link}
                      grade={course.grade}
                      isLikes={true}
                    />
                  </Col>
                ))
              ) : (
                <div className="withOutResult">
                  <span className="t-black fs-20px">
                    No se encontraron cursos.
                  </span>
                </div>
              )}
            </>
          )}
        </Row>
      </div>
    </div>
  );
};

export default Content;
