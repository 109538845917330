import images from "assets/images";
import { Icon, Skeleton } from "components/atoms";
import { ICertificate } from "./props";
import "./styles.scss";

const Certificate = ({
  loading,
  isCertificate,
  nameCourse, 
  certificate,
  className,
}: ICertificate) => {

 

  return (
    <section className={`${className}`}>
      {loading && (
        <Skeleton height="400px" bgColor="transparent" dotColor="yellow" />
      )}
      {!loading && (
        <div className="courses-certificate">
          <div className="courses-certificate_card">
            <div className="courses-certificate_h4 ff-secondary">
              <h4 className="fs-subTitle">Certificado</h4>
            </div>
            <div className="courses-certificate_div">
              {loading && (
                <Skeleton
                  height="100%"
                  bgColor="transparent"
                  dotColor="yellow"
                />
              )}

              {!loading && !isCertificate && (
                <>
                  <div className="courses-certificate-btn">
                    <div className="courses-certificate-btn_div_none">
                      <img
                        className="courses-certificate-btn_div_none__img"
                        src={images?.Certificate}
                        alt="Certificado"
                      />
                      <div className="courses-certificate-btn_div_none__hover">
                        <img
                          className="courses-certificate-btn_div_none__hover___img"
                          src={images?.Security}
                          alt="pdf"
                        />
                        <a
                          href={certificate}
                          target="_blank"
                          rel="noopener noreferrer"
                          download={`${nameCourse}-certificado.pdf`}
                          className="courses-certificate-btn_div_none__hover___a hov-t-yellow "
                        >
                          Obten tu certificado al terminar el curso
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {!loading && isCertificate && (
                <>
                  <div
                    className="courses-certificate-btn"                    
                  >
                    <a
                      href={certificate}
                      target="_blank"
                      rel="noopener noreferrer"
                      download={`${nameCourse}-certificado.pdf`}
                      className="courses-certificate-btn_div__hover___a hov-t-yellow "
                    >
                      <div className="courses-certificate-btn_div">
                        <img
                          className="courses-certificate-btn_div__img"
                          src={images?.Certificate}
                          alt="Certificado"
                        />
                        <div className="courses-certificate-btn_div__hover hov-t-yellow">
                          <Icon
                            name="download"
                            list="items"
                            color="white"
                            width={30}
                            height={30}
                          />
                          Clic para descargar
                        </div>
                      </div>
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Certificate;
