import { Toast } from "components/atoms";
import endpoints from "constants/endpoints";
import { http } from "requests/http";
import { IGetCourses } from "./props";

export const getCourses = async ({
  setLoading,
  search,
  paged,  
  setPaginationInfo,
  setDataCourses,
  dataCourses,
}: IGetCourses) => {
  try {
    setLoading(true);

    const {status, data, errors, paginationInfo} =  await http({
      method: `get`,
      url: endpoints.getCourses,
      params: {
        search,
        paged,
      },
      hasToken: false,
    })
    /*const response = await API.request({
      method: `get`,
      url: endpoints.getCourses,
      params: {
        search,
        paged,
      },
      hasToken: false,
    });*/

    if (status !== 200) {
      throw new Error(errors);
    }

    if (Array.isArray(data)) {
      setDataCourses([...data, ...dataCourses]);
      setPaginationInfo(paginationInfo);
    }

    setLoading(false);
    
  } catch (error: any) {
    Toast({
      message: error?.message || `No se han encontrado cursos.`,
      type: `error`,
    });
    setDataCourses([]);
    setLoading(false);
  } 
};
