import LogoAprendeMab from "./logos/logo-amab.webp";
import LogoMab from "./logos/logo_mab.png";
import HeartMab from "./mabIcons/heart.svg";
import Pdf from "./mabIcons/pdf.png";
import PdfColor from "./mabIcons/pdfColor.png";
import PeopleHeart from "./mabIcons/people-heart.png";
import QuestionIcon from "./mabIcons/questionIcon.png";
import Rainbow from "./mabIcons/rainbow500x500.png";
import AllSoft from "./socioemotional/categories/all-soft.png";
import ChallengesHealthyCoexistence from "./socioemotional/categories/challenges-healthy-coexistence.png";
import Feedback from "./socioemotional/categories/feedback.jpg";
import SchoolLife from "./socioemotional/categories/school-life.png";
import SocialEmotionalSkills from "./socioemotional/categories/social-emotional-skills.jpg";
import Team from "./socioemotional/categories/team.jpg";
import HomeKumpai from "./socioemotional/home.svg";
import BannerMacarena from "./socioemotional/landingPage/banner-macarena.webp";
import BannerSocioEmotional from "./socioemotional/landingPage/banner.png";
import LogoKumpai from "./socioemotional/logo.png";
import Certificate from "./socioemotional/means/certificado.png";

import MindMap from "./socioemotional/means/mind-map.png";
import Resume from "./socioemotional/means/resume.png";
import Solver from "./socioemotional/means/solver.png";
import Worksheet from "./socioemotional/means/worksheet.png";

import Security from "./socioemotional/means/security.png";
import SchoolLifeBanner from "./socioemotional/photos/schoolLife-banner-wallpaper.png";
import SchoolLifeBannerWallpaper from "./socioemotional/photos/schoolLife-banner.png";

/* VECTORS */
import MabRed from "./vectors/mab-red.svg";

import HeartVector from "./vectors/heart.webp";

/*LANDING */
import LandingAprendeMAB from "./landing/aprendemab.webp";
import LandingCategorie1 from "./landing/categorie-1.webp";
import LandingCategorie2 from "./landing/categorie-2.webp";
import LandingCategorie3 from "./landing/categorie-3.webp";
import LandingCategorie4 from "./landing/categorie-4.webp";
import LandingCategorie5 from "./landing/categorie-5.webp";
import LandingCategorie6 from "./landing/categorie-6.webp";
import LandingCategorie7 from "./landing/categorie-7.webp";
import LandingCategorie8 from "./landing/categorie-8.webp";
import LandingComas from "./landing/comas.webp";
import LandingCourse1 from "./landing/course-1.webp";
import LandingCourse2 from "./landing/course-2.webp";
import LandingCourse3 from "./landing/course-3.webp";
import LandingCourse4 from "./landing/course-4.webp";
import LandingCourse5 from "./landing/course-5.webp";
import LandingCourse6 from "./landing/course-6.webp";
import LandingCourse10 from "./landing/curso-10.webp";
import LandingCourse7 from "./landing/curso-7.webp";
import LandingCourse8 from "./landing/curso-8.webp";
import LandingCourse9 from "./landing/curso-9.webp";
import LandingExpert1 from "./landing/expert-1.webp";
import LandingExpert10 from "./landing/expert-10.webp";
import LandingExpert2 from "./landing/expert-2.webp";
import LandingExpert3 from "./landing/expert-3.webp";
import LandingExpert4 from "./landing/expert-4.webp";
import LandingExpert5 from "./landing/expert-5.webp";
import LandingExpert6 from "./landing/expert-6.webp";
import LandingExpert7 from "./landing/expert-7.webp";
import LandingExpert8 from "./landing/expert-8.webp";
import LandingExpert9 from "./landing/expert-9.webp";
import LandingGallery1 from "./landing/gallery-1.webp";
import LandingGallery2 from "./landing/gallery-2.webp";
import LandingGallery3 from "./landing/gallery-3.webp";
import LandingHead from "./landing/landing-head.webp";
import LandingMain from "./landing/landing-main.webp";
import LandingSponsor1 from "./landing/sponsor-1.webp";
import LandingSponsor10 from "./landing/sponsor-10.webp";
import LandingSponsor11 from "./landing/sponsor-11.webp";
import LandingSponsor12 from "./landing/sponsor-12.webp";
import LandingSponsor2 from "./landing/sponsor-2.webp";
import LandingSponsor3 from "./landing/sponsor-3.webp";
import LandingSponsor4 from "./landing/sponsor-4.webp";
import LandingSponsor5 from "./landing/sponsor-5.webp";
import LandingSponsor6 from "./landing/sponsor-6.webp";
import LandingSponsor7 from "./landing/sponsor-7.webp";
import LandingSponsor8 from "./landing/sponsor-8.webp";
import LandingSponsor8v from "./landing/sponsor-8v.webp";
import LandingSponsor9 from "./landing/sponsor-9.webp";
import LandingTeamMobile from "./landing/team-mobile.webp";
import LandingTeam from "./landing/team.webp";
import LandingTest1 from "./landing/test-1.webp";
import LandingTest2 from "./landing/test-2.webp";
import LandingTool1 from "./landing/tool-1.webp";
import LandingTool2 from "./landing/tool-2.webp";

/*WORLD*/
import WorldUsBanner from "./world/us/banner.webp";
import WorldUsGarage1 from "./world/us/garage-1.webp";

/*LOGIN*/
import LoginWallpaper1 from "./access/login/wallpaper-1.png";

/*REGISTER*/
import Student from "./access/register/contador.webp";
import Teacher from "./access/register/pinceles.webp";
import Door from "./access/register/puerta.webp";
import Representative from "./access/register/taza.webp";
import RegisterWallpaper1 from "./access/register/wallpaper-1.png";
import RegisterWallpaper2 from "./access/register/wallpaper-2.png";
import RegisterWallpaper3a from "./access/register/wallpaper-3a.png";
import RegisterWallpaper3b from "./access/register/wallpaper-3b.png";

/*PROFILE*/
import Bee from "./access/profile/bee.png";
import Book from "./access/profile/book.png";
import Check from "./access/profile/check.png";
import Medal from "./access/profile/medal.png";
import RainbowProfile from "./access/profile/rainbow.png";
import Test1 from "./access/profile/test1.png";
import Test2 from "./access/profile/test2.png";
import User from "./access/profile/user.png";

/*COURSE*/
import Course from "./courses/course.webp";
import Pause from "./courses/pause.png";
import Play from "./courses/play.png";

/*REVIEW*/
import Correct from "./courses/correct.png";
import Incorrect from "./courses/incorrect.png";

/*TEST*/
import Megaphone from "./test/megaphone.webp";

/*PROFILE*/
import ProfileCourseCertificated from "./profile/certificated.png";
import ProfileCourseCompleted from "./profile/completed.png";
import ProfileCourseEnrolled from "./profile/enrolled.png";

export default {
  LogoMab,
  Pdf,
  Resume,
  Solver,
  Worksheet,
  MindMap,
  Security,
  Certificate,
  SchoolLife,
  SocialEmotionalSkills,
  Team,
  ChallengesHealthyCoexistence,
  AllSoft,
  Feedback,
  Rainbow,
  BannerSocioEmotional,
  HeartMab,
  SchoolLifeBanner,
  LogoAprendeMab,
  BannerMacarena,
  QuestionIcon,
  PdfColor,
  PeopleHeart,
  SchoolLifeBannerWallpaper,

  MabRed,
  LandingHead,
  LandingMain,
  LandingCourse1,
  LandingCourse2,
  LandingCourse3,
  LandingCourse4,
  LandingCourse5,
  LandingCourse6,
  LandingCourse7,
  LandingCourse8,
  LandingCourse9,
  LandingCourse10,
  LandingCategorie1,
  LandingCategorie2,
  LandingCategorie3,
  LandingCategorie4,
  LandingCategorie5,
  LandingCategorie6,
  LandingCategorie7,
  LandingCategorie8,
  LandingTest1,
  LandingTest2,
  LandingTool1,
  LandingTool2,
  LandingExpert1,
  LandingExpert2,
  LandingExpert3,
  LandingExpert4,
  LandingExpert5,
  LandingExpert6,
  LandingExpert7,
  LandingExpert8,
  LandingExpert9,
  LandingExpert10,
  LandingTeam,
  LandingTeamMobile,
  LandingSponsor1,
  LandingSponsor2,
  LandingSponsor3,
  LandingSponsor4,
  LandingSponsor5,
  LandingSponsor6,
  LandingSponsor7,
  LandingSponsor8,
  LandingSponsor8v,
  LandingSponsor9,
  LandingSponsor10,
  LandingSponsor11,
  LandingSponsor12,
  LandingComas,
  LandingGallery1,
  LandingGallery2,
  LandingGallery3,
  LandingAprendeMAB,

  HeartVector,

  WorldUsBanner,
  WorldUsGarage1,

  LoginWallpaper1,
  RegisterWallpaper1,
  RegisterWallpaper2,
  RegisterWallpaper3a,
  RegisterWallpaper3b,
  Representative,
  Student,
  Teacher,
  Door,

  User,
  Bee,
  Book,
  Check,
  Medal,
  RainbowProfile,
  Test1,
  Test2,

  Course,
  Play,
  Pause,
  Incorrect,
  Correct,

  Megaphone,

  LogoKumpai,
  HomeKumpai,

  ProfileCourseEnrolled,
  ProfileCourseCompleted,
  ProfileCourseCertificated,
};
