import images from "assets/images";
import { Button, Icon, Skeleton } from "components/atoms";
import {
  AccordionUnits,
  Certificate,
  Means,
  ModalQuestion
} from "components/molecules";
import { Col, Container, Row } from "components/molecules/grid";
import { Layout } from "components/organisms";
import { useAppSelector } from "hooks";
import { useLayoutEffect, useState } from "react";
import ReactPlayer from "react-player/vimeo";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Review from "./childrens/review";
import ReviewSatisfactory from "./childrens/reviewSatisfactory";
import ReviewUnsatisfactory from "./childrens/reviewUnsatisfactory";
import { AReviewSatisfactory, ATopic } from "./props";
import { getTopic, getTopicProgress, getTopicsContent } from "./services";
import "./styles.scss";

const Topic = () => {
  const { idTopic, idCourse } = useParams();
  const [loading, setLoading] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [stepReview, setStepReview] = useState(`question`);
  const navigate = useNavigate();
  const { authenticated } = useAppSelector((state) => state.auth);
  const [isCertificate, setIsCertificate] = useState(false);
  const [nameCourse, setNameCourse] = useState(atob(localStorage.getItem(`nameCourse`) || ``));
  const [certificate, setCertificate] = useState('')

  const [data, setData] = useState<ATopic>({
    idCourse: ``,
    idUnit: ``,
    idTopic: ``,
    title: ``,
    bodyTopic: ``,
    videoTopic: ``,
    duration: ``,
    likes: ``,
    summary: ``,
    worksheet: ``,
    map: ``,
    solutions: ``,
    progress: {},
    questions: [],
  });

  const [dataReviewSatisfactory, setDataReviewSatisfactory] =
    useState<AReviewSatisfactory>({
      correct: 0,
      incorrect: 0,
    });

  const [dataContent, setDataContent] = useState([]);
  const [topicContent, setTopicContent] = useState([]);
  const [currentTopicIndex, setCurrentTopicIndex] = useState(-1);

  useLayoutEffect(() => {   
    
    getTopic({
      setLoading,
      authenticated,
      idCourse,
      idTopic,
      setData,
      setStepReview,
      setIsCertificate,
      setCertificate,      
    });

    getTopicsContent(
      {
        setLoading,
        idCourse,
        idTopic,
        setDataContent,
        setTopicContent,
        setCurrentTopicIndex,
        setNameCourse,
      }      
    );

    getTopicProgress({
      setLoading,
      idCourse,
      idTopic,
      setDataReviewSatisfactory,
    });
    
  }, []);

  const handleNextTopic = () => {
    window.location.href = `/curso/${idCourse}/topico/${
      topicContent[currentTopicIndex + 1]
    }`;
  };

  const handleBackTopic = () => {
    window.location.href = `/curso/${idCourse}/topico/${
      topicContent[currentTopicIndex - 1]
    }`;
  };

  const handleCloseModal = () => {
    setIsShowModal(!isShowModal);
  };

  const handleNextQuestion = () => {
    setCurrentIndex(currentIndex + 1);
    //HABILITAR OPCIONES
    const options: HTMLElement | any = document.getElementsByName(
      `options-question` + `-` + currentIndex
    );
    for (let i = 0; i < options.length; i++) {
      options[i].disabled = false;
      options[i].checked = false;
    }
  };

  const handleAnswer = (
    index: any,
    currentIndex: any,
    answer: any,
    right: any
  ) => {
    //VERIFICAR SI ES CORRECTA LA RESPUESTA
    if (answer == right) {
      const option: HTMLElement | any = document.getElementById(
        `label-option-` + index + `-` + currentIndex
      );
      option.style.color = `green`;
      const span: HTMLElement | any = document.getElementById(
        `correct-` + index + `-` + currentIndex
      );
      span.hidden = false;
    } else {
      const option: HTMLElement | any = document.getElementById(
        `label-option-` + index + `-` + currentIndex
      );
      option.style.color = `red`;
      const span: HTMLElement | any = document.getElementById(
        `incorrect-` + index + `-` + currentIndex
      );
      span.hidden = false;
    }

    //DESHABILITAR OPCIONES
    const options: HTMLElement | any = document.getElementsByName(
      `options-question` + `-` + currentIndex
    );
    for (let i = 0; i < options.length; i++) {
      options[i].disabled = true;
    }
  };

  const isNextDisabled = currentTopicIndex === topicContent.length - 1;
  const isBackDisabled = currentTopicIndex === 0 ? true : false;
  const isCompleteDisabled = false;

  const onBack = () => {
    navigate(`/curso/${idCourse}`);
  };

  const [isLike, setIsLike] = useState(false);
  const handleLike = () => setIsLike(!isLike);



  return (
    <Layout>
      <Container className="mt-60px">
        <Row className="pt-1-5rem">
          <Col lg="12">
            {loading ? (
              <Skeleton height="80px" dotColor="gray" />
            ) : (
              <div className="d-flex ai-center jc-start ">
                <div className="d-flex  pr-0-5rem h-50px">
                  <button
                    className="btnBack bg-yellow t-black hov-t-red d-flex ai-center jc-center"
                    onClick={() => onBack()}
                  >
                    <Icon
                      list="arrows"
                      name="left"
                      width={20}
                      height={20}
                      className="ff-secondary fw-900"
                    ></Icon>
                  </button>
                </div>
                <h2>{data.title}</h2>
              </div>
            )}
          </Col>
        </Row>
        <Row className="pt-1rem ">
          <Col lg="8">
            {loading ? (
              <Skeleton height="450px" dotColor="gray" />
            ) : (
              <div className="course-topic">
                <div className="course-topic-video">
                  <ReactPlayer
                    url={`https://player.vimeo.com/video/${
                      data.videoTopic.split(`/`)[3]
                    }?h=${data.videoTopic.split(`/`)[4]}&speed=true`}
                    width="100%"
                    height="100%"
                    controls={true}
                    allow="autoplay; fullscreen; picture-in-picture"
                  />
                </div>
                <div className="course-topic-subTitle px-1rem">
                  <Button
                    text={data.likes}
                    txtColor={isLike ? `black` : `red`}
                    bgColor="white"
                    txtHover="red"
                    className="ff-primary p-0rem m-0rem d-flex fd-row-reverse ai-center g-0-5rem"
                    iconName={isLike ? `heart-line` : `heart`}
                    //onClick={handleLike}
                  />
                  <p>{data.bodyTopic}</p>
                </div>
              </div>
            )}
            {/* <div>
              <h2>/topico/{topicContent[currentTopicIndex].idTopic}</h2>
            </div> */}
            <Row className="pb-2rem ">
              <Col lg="4" className="pt-1rem  d-flex ai-center jc-center">
                {loading ? (
                  <Skeleton height="50px" dotColor="gray" />
                ) : (
                  <Button
                    iconList="arrows"
                    iconName="back-alt"
                    txtColor="black"
                    bgColor="yellow"
                    bgHover="turquoise"
                    text="Atrás"
                    className={`ff-primary fw-bold px-2rem br-20px`}
                    onClick={() => handleBackTopic()}
                    disabled={isBackDisabled}
                    iconPosition={`left`}
                    // rowReverse={true}
                  />
                )}
              </Col>
              <Col lg="4" className="pt-1rem  d-flex ai-center jc-center">
                {loading ? (
                  <Skeleton height="50px" dotColor="gray" />
                ) : (
                  <>
                    {authenticated && data.questions.length > 0 ? (
                      <Button
                        txtColor="black"
                        bgColor="cyan"
                        bgHover="turquoise"
                        text="Repaso"
                        className={`ff-primary fw-bold px-2rem br-20px`}
                        onClick={() => handleCloseModal()}
                        disabled={isCompleteDisabled}
                        // rowReverse={true}
                      />
                    ) : null}
                  </>
                )}
              </Col>
              <Col lg="4" className="pt-1rem  d-flex ai-center jc-center">
                {loading ? (
                  <Skeleton height="50px" dotColor="gray" />
                ) : (
                  <Button
                    iconList="arrows"
                    iconName="next-alt"
                    txtColor="black"
                    bgColor="yellow"
                    bgHover="turquoise"
                    text="Siguiente"
                    iconPosition={`right`}
                    className="ff-primary fw-bold px-2rem br-20px"
                    onClick={() => handleNextTopic()}
                    disabled={isNextDisabled}
                  />
                )}
              </Col>
            </Row>
          </Col>
          <Col lg="4">
            {loading ? (
              <Skeleton height="650px" dotColor="gray" />
            ) : (
              <>
                <AccordionUnits
                  items={dataContent}
                  idUnitCurrent={data.idUnit}
                  idTopicCurrent={idTopic}
                ></AccordionUnits>
              </>
            )}
          </Col>
        </Row>
        <Row className="py-1rem pb-2rem">
          <Col lg="8" className="py-1rem">
            {loading ? (
              <Skeleton height="250px" dotColor="gray" className="mt-1rem" />
            ) : (
              <Means
                means={[
                  {
                    id: 1,
                    pdf: data.summary,
                    title: `Resumen`,
                    img: images.Resume,
                  },
                  {
                    id: 2,
                    pdf: data.worksheet,
                    title: `Hoja de Trabajo`,
                    img: images.Worksheet,
                  },
                  {
                    id: 3,
                    pdf: data.solutions,
                    title: `Solucionario`,
                    img: images.Solver,
                  },
                  {
                    id: 4,
                    pdf: data.map,
                    title: `Mapa mental`,
                    img: images.MindMap,
                  },
                ]}
                loading={loading}
                className="topic-content_3 "
              />
            )}
          </Col>
          <Col lg="4" className="py-1rem">
            {loading ? (
              <Skeleton height="250px" dotColor="gray" className="mt-1rem" />
            ) : (
              <Certificate
                loading={loading}
                isCertificate={isCertificate}
                certificate={certificate}
                nameCourse={nameCourse}   
                className="topic-content_4"
              />
            )}
          </Col>
        </Row>
      </Container>
      <ModalQuestion
        open={isShowModal}
        title={
          stepReview == `question`
            ? data.title
            : stepReview == `reviewSatisfactory`
              ? `FELICITACIONES, APROBASTE!!!`
            : `VAMOS TÚ PUEDES, INTÉNTALO NUEVAMENTE`
        }
        onClose={() => handleCloseModal()}
      >
        {stepReview == `question` ? (
          <Review
            idCourse={idCourse}
            idTopic={idTopic}
            setStepReview={setStepReview}
            setDataReviewSatisfactory={setDataReviewSatisfactory}
          ></Review>
        ) : null}

        {stepReview == `reviewSatisfactory` ? (
          <ReviewSatisfactory
            dataReviewSatisfactory={dataReviewSatisfactory}
          ></ReviewSatisfactory>
        ) : null}

        {stepReview == `reviewUnsatisfactory` ? (
          <ReviewUnsatisfactory
            setStepReview={setStepReview}
          ></ReviewUnsatisfactory>
        ) : null}
      </ModalQuestion>
    </Layout>
  );
};

export default Topic;
