import { Button } from "components/atoms";
import { Col, Container, Row } from "components/molecules/grid";
import { Layout } from "components/organisms";

const myCourses = () => {
  const signOut = () => {
    localStorage.removeItem(`token`);
    localStorage.removeItem(`names`);
    localStorage.removeItem(`lastNames`);
    window.location.href = `/`;
  };
  return (
    <Layout>
      <Container>
        Vista de 'Mis Cursos'
        {/* <Row className="bg-yellow">
          <Col lg="12 p-3rem">
            <h1>
              BIENVENIDO {localStorage.getItem(`names`)}
              {` `}
              {localStorage.getItem(`lastNames`)}
            </h1>
          </Col>
        </Row> */}
        {/* <Row className="h-50vh">
          <Col lg="12 p-3rem">
            <Button text="Cerrar Sesión" onClick={() => signOut()}></Button>
          </Col>
        </Row> */}
      </Container>
    </Layout>
  );
};

export default myCourses;
