import regexs from "constants/regexs";

export const rules = {
  email: {
    required: true,
    pattern: regexs.email,
  },
  password: {
    required: true,
    minLength: 8,
  },
};
