import images from "assets/images";
import { useState } from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./styles.scss";

const SectionCategorie = () => {
  const [experts, setExperts] = useState([
    {
      title: `Happy Handra`,
      image: images.LandingExpert1,
    },
    {
      title: `Pedro Gamio`,
      image: images.LandingExpert3,
    },
    {
      title: `Sandra Muente`,
      image: images.LandingExpert4,
    },
    {
      title: `Ximena Vega`,
      image: images.LandingExpert5,
    },
    {
      title: `Ximena Maurial`,
      image: images.LandingExpert6,
    },
    {
      title: `Germán Roz`,
      image: images.LandingExpert7,
    },
    {
      title: `Mariela Catter`,
      image: images.LandingExpert2,
    },
    {
      title: `José Del Castillo`,
      image: images.LandingExpert8,
    },
    {
      title: `Felipe Berckemeyer`,
      image: images.LandingExpert9,
    },
    {
      title: `Arantxa Layseca`,
      image: images.LandingExpert10,
    },
  ]);

  return (
    <div>
      <section className="bg-dark">
        <div id="slider-expertos" className="swiper-container">
          <Swiper
            className="swiper-wrapper bg-black h-100por"
            spaceBetween={0}
            slidesPerView={5}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
            }}
            breakpoints={{
              360: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              480: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 0,
              },
            }}
            modules={[Autoplay]}
          >
            {experts.map((item, index) => (
              <SwiperSlide className="swiper-slide " key={index}>
                <div className="expert-gradient fs-16px t-white ">
                  <span className="p-1rem fs-14px ">
                    <b>{item.title}</b>
                  </span>
                </div>
                <img src={item.image} className="w-100por" />
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="c-slider__pagination swiper-pagination"></div>
        </div>
      </section>

      {/* <section className="bg-black t-black py-2rem">
        <Container>
          <Row>
            <Col lg="12" className="d-flex ai-center jc-center">
              <div className="ta-center">
                <span className="expert-t t-white">
                  <b>+80</b>&nbsp;
                </span>
              </div>
              <div className="ta-center">
                <span className="expert-st t-white">
                  <b>EXPERTOS</b>
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
    </div>
  );
};

export default SectionCategorie;
