import images from "assets/images";
import { Button, TextArea } from "components/atoms";
import errorsForm from "constants/errorsForm";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { saveAbout } from "views/myProfile/services";
import { FAbout, IAbout } from "./props";
import { rules } from "./rules";
import "./styles.scss";

const About = ({ dataAbout, setDataAbout }: IAbout) => {
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FAbout>({
    mode: `onChange`,
    defaultValues: {
      description: ``,
    },
  });

  const onEdit = () => {
    setIsEdit(!isEdit);
  };

  const onSubmit = (values: FAbout) =>
    saveAbout({ setLoading, values, setIsEdit, setDataAbout });

  return (
    <div className="profile-about">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="profile-about_title bg-yellow">
          <h5 className="m-0rem p-0rem ff-secondary fw-bold px-1rem">
            Sobre mí
          </h5>

          {!isEdit ? (
            <Button
              className="m-0rem p-0rem ff-primary fs-10px fw-bold br-30px w-35px h-35px"
              bgColor="cyan"
              bgHover="cyan"
              txtColor="white"
              txtHover="black"
              onClick={() => onEdit()}
              iconName="pencil"
              iconList="buttons"
              iconSize={20}
            />
          ) : (
            <div className="d-flex ai-center g-0-5rem">
              <Button
                className="m-0rem p-0rem ff-primary fs-12px fw-bold br-30px w-35px h-35px"
                bgColor="blueLight"
                bgHover="blue"
                txtColor="white"
                txtHover="white"
                type="submit"
                iconName="save"
                iconList="buttons"
                iconSize={19}
              />

              <Button
                className="m-0rem p-0rem ff-primary fs-12px fw-bold br-30px w-35px h-35px"
                bgColor="redLight"
                bgHover="red"
                txtColor="white"
                txtHover="white"
                onClick={() => onEdit()}
                iconName="close"
                iconList="buttons"
                iconSize={12}
              />
            </div>
          )}
        </div>

        <div className="profile-about_content bg-white px-2rem">
          {!isEdit ? (
            <div className="d-flex fd-column ai-center jc-center g-1rem">
              {dataAbout.description == null ? (
                <>
                  <p className="m-0rem p-0rem fs-14px ta-center">
                    Cuéntanos un poco sobre ti. ¡Nos encantaría conocerte más!
                  </p>
                  <img src={images.Bee} alt="" className="bee" />
                </>
              ) : (
                <p className="fs-18px ta-left">{dataAbout.description}</p>
              )}
            </div>
          ) : (
            <Controller
              control={control}
              name="description"
              rules={rules.decription}
              render={({ field: { value, onChange, onBlur } }) => (
                <TextArea
                  type="text"
                  placeholder={`Acerca de Mí`}
                  value={value || ``}
                  onChange={onChange}
                  onBlur={onBlur}
                  iconList="access"
                  iconNameLeft="user"
                  iconLeft={true}
                  iconRight={true}
                  rows={7}
                  messageError={errorsForm[errors?.description?.type || ``]}
                />
              )}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default About;
