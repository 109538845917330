import { Toast } from "components/atoms";
import API from "constants/api";
import endpoints from "constants/endpoints";
import { setAuthenticated } from "redux/actions/auth";
import { IHandleGeneratePassword } from "./props";

export const logged = (data: any) => {
  localStorage.setItem(`token`, data.token);
  localStorage.setItem(`names`, btoa(data.names));
  localStorage.setItem(`lastNames`, btoa(data.lastNames));
  setAuthenticated(true);

  Toast({
    type: `success`,
    message: `Inicio exitoso`,
  });

  window.location.href = `/cursos`;
};

export const handleGeneratePassword = async ({
  setLoading,
  values,
}: IHandleGeneratePassword) => {
  try {
    setLoading(true);

    if (values.password != values.repeatPassword) {
      Toast({
        type: `error`,
        message: `Las contraseñas no coinciden.`,
      });
      throw new Error();
    }

    const response = await API.request({
      url: endpoints.loginGeneratePassword,
      method: `post`,
      data: values,
    });

    if (response?.data?.status !== `success`) {
      Toast({
        type: `error`,
        message: `Error al generar contraseña.`,
      });
      throw new Error();
    }

    Toast({
      type: `success`,
      message: `Contraseña se generó correctamente.`,
    });

    logged(response.data.data);
  } catch (error: any) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};
