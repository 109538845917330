import { Toast } from "components/atoms";
import API from "constants/api";
import endpoints from "constants/endpoints";
import { IGetTopics } from "./props";

export const getTopics = async ({ idUnit, setData }: IGetTopics) => {
  try {
    const response = await API.request({
      url: endpoints.getTopics,
      params: {
        idUnit: idUnit,
      },
      hasToken: false,
    });
    if (response?.status !== 200) {
      throw new Error(response?.data?.message);
    }
    if (setData) setData(response.data?.data);
    else return response.data?.data;
  } catch (error: any) {
    Toast({
      message: error?.message || `No se ha encontrado temas.`,
      type: `error`,
    });
  }
};
