import { Button, Combobox, Input } from "components/atoms";
import { Col, Container, Row } from "components/molecules/grid";
import errorsForm from "constants/errorsForm";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { handleStepQuestion } from "../../services";
import { IFormInit, IInit } from "./props";
import { rules } from "./rules";
import "./styles.scss";

const Init = ({ setStepTest }: IInit) => {
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInit>({
    mode: `onChange`,
    defaultValues: {
      name: atob(
        localStorage.getItem(`testPersonalityName`) ||
          localStorage.getItem(`names`) ||
          ``
      ),
      age: atob(localStorage.getItem(`testPersonalityAge`) || ``),
    },
  });

  const [ages, setAges] = useState([
    {
      id: `10`,
      value: `10`,
      option: `10 AÑOS`,
    },
    {
      id: `11`,
      value: `11`,
      option: `11 AÑOS`,
    },
    {
      id: `12`,
      value: `12`,
      option: `12 AÑOS`,
    },
    {
      id: `13`,
      value: `13`,
      option: `13 AÑOS`,
    },
    {
      id: `14`,
      value: `14`,
      option: `14 AÑOS`,
    },
    {
      id: `15`,
      value: `15`,
      option: `15 AÑOS`,
    },
    {
      id: `16`,
      value: `16`,
      option: `16 AÑOS`,
    },
    {
      id: `17`,
      value: `17`,
      option: `17 AÑOS`,
    },
  ]);

  const onSubmit = (payload: IFormInit) =>
    handleStepQuestion({ payload, setLoading, setStepTest });

  return (
    <Container className="px-2rem">
      <Row>
        <Col lg="12">
          <h2 className="ta-center ff-secondary t-black pt-3rem pb-2rem fw-bold">
            TEST DE PERSONALIDAD
          </h2>
        </Col>
      </Row>
      <Container className="init">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="init-head bg-cyan ">
            <Col lg="12">
              <h3 className="ta-center ff-primary t-white fs-25px p-1rem fw-bold">
                ¡Cuéntanos sobre Ti!
              </h3>
            </Col>
          </Row>
          <Row className="init-body bg-white">
            <Container className="px-4rem py-2rem">
              <Row className="pt-2rem">
                <Col lg="12">
                  <span className="ta-left">Nombre(s)</span>
                  <Controller
                    control={control}
                    name="name"
                    rules={rules.name}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <Input
                        type="text"
                        placeholder="Nombre"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        iconList="access"
                        iconNameLeft="user"
                        iconLeft={true}
                        messageError={errorsForm[errors?.name?.type || ``]}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row className="pt-2rem">
                <Col lg="12">
                  <span className="ta-left">Edad</span>
                  <Controller
                    control={control}
                    name="age"
                    rules={rules.age}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <Combobox
                        labeltext=""
                        defaultText="Seleccionar edad..."
                        selectedtext={value || `Seleccionar edad...`}
                        icon="eva:arrow-ios-downward-outline"
                        classInput="pt-1 pb-1 pl-05 pr-25"
                        classIcon="txt-silver fs-15"
                        hasSearch={false}
                        required={true}
                        changeValue={onChange}
                        listOptions={ages}
                        messageError={errorsForm[errors?.age?.type || ``]}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row className="pt-2rem ta-center">
                <Col lg="12">
                  <Button
                    type="submit"
                    className="bg-cyan t-white hov-bg-black hov-t-white ff-primary fs-21px fw-900"
                    text="Continuar"
                  />
                </Col>
              </Row>
            </Container>
          </Row>
        </form>
      </Container>
      <Row className="py-3rem ta-center">
        <Col lg="12">
          <Button
            type="submit"
            className="bg-red t-white hov-bg-white hov-t-red hov-t-red ff-primary fs-18px fw-900"
            text="Volver al inicio"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Init;
