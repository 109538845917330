import ReactDOM from "react-dom";
import ToastContainer from "./childrens/ToastContainer";
import { IToast } from "./props";
import "./styles.scss";

let ctToastCount = 0;

const cogoToast = (options: IToast) => {
  const existsHeader = document.querySelector(`header.header`);
  let rootContainer = document.getElementById(`ct-container`);

  if (rootContainer && existsHeader) {
    rootContainer.style.paddingTop = `55px`;
  }

  if (!rootContainer) {
    rootContainer = document.createElement(`div`);
    rootContainer.id = `ct-container`;
    rootContainer.style.paddingTop = existsHeader ? `55px` : `0px`;
    document.body.appendChild(rootContainer);
  }

  ctToastCount++;

  const toast = {
    id: ctToastCount,
    message: options.message,
    type: options?.type || `info`,
    position: options?.position || `top-right`,
    hideAfter: options?.hideAfter || 3,
    heading: options?.heading || ``,
  };

  ReactDOM.render(<ToastContainer toast={toast} />, rootContainer);
};

export default cogoToast;
