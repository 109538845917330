import images from "assets/images";
import { Col, Container, Row } from "components/molecules/grid";
import "./styles.scss";

const SectionOne = () => {
  return (
    <section className="bg-white mt-60px">
      <Container>
        <Row className="m-0rem p-0rem">
          <Col lg="4" className="py-2rem desktop">
            <img src={images.LandingHead} className="img-head" />
          </Col>
          <Col lg="8" className="question py-3rem t-center fs-30px ">
            ¿Listo/a para{` `}
            <span>
              <b>revolucionar la educación?</b>
            </span>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SectionOne;
