import { Button, Card } from "components/atoms";
import { Container } from "components/molecules/grid";
import links from "constants/links";
import { useLayoutEffect, useState } from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.scss";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { getCourses } from "./services";

const SectionCourse = () => {
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);

  useLayoutEffect(() => {
    getCourses({
      setLoading: setLoading,
      setDataCourses: setCourses,
    });
  }, []);

  return (
    <section className="d-flex fd-column ai-center jc-center g-2rem bg-white py-3rem">
      <h2 className="ff-primary ta-center t-black fw-bold">
        DISFRUTA DE NUESTROS CURSOS
      </h2>
      <Container className="pt-2rem pb-1rem px-1rem">
        <Swiper
          spaceBetween={10}
          slidesPerView={4}
          navigation={true}
          modules={[Navigation]}
          breakpoints={{
            360: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            480: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
        >
          {courses.map((item: any, index) => (
            <SwiperSlide key={index}>
              <Card
                _id={item._id}
                id={item.id}
                name={item.name}
                description={item.description}
                duration={item.duration}
                color={item.color}
                thumbnail={item.thumbnail}
                teacher={item.teacher}
                price={item.price}
                likes={item.likes}
                link={item.link}
                grade={item.grade}
                isLikes={true}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
      <Button
        bgColor="red"
        txtHover="yellow"
        text="Ir a Cursos"
        className="w-100px ta-center mt-1rem"
        redirect={links.courses}
      />
    </section>
  );
};

export default SectionCourse;
