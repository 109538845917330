import { configureStore } from "@reduxjs/toolkit";
import envs from "constants/envs";
import reducer from "redux/slices";

const store = configureStore({
  reducer,
  devTools: envs.env === `development`,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
