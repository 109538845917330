const urls = {
  home: `https://aprendemab.com/`,
  facebook: `https://www.facebook.com/MABPeruEducacion`,
  instagram: `https://www.instagram.com/mab_educacion/?hl=es`,
  linkedin: `https://www.linkedin.com/company/mab-your-learning-coach/mycompany/`,
  youtube: `https://www.youtube.com/channel/UCL5IteTsd_BtDipFuKsoDjA`,
  email: `mailto:info@mablovers.com`,
  phone: `https://api.whatsapp.com/send?phone=51946151823`,
};

export default urls;
