import { Button } from "components/atoms";
import { useEffect, useRef } from "react";
import { IModal } from "./props";
import "./styles.scss";

const ModalQuestion = ({
  open,
  children,
  onClose,
  title,
  onCloseOut,
  bgContainer,
  bgTitle,
  colorTitle,
  colorClose,
  textLeftBtn,
  onClickLeftBtn,
  textRightBtn,
  onClickRightBtn,
  iconLeftBtn,
  iconRightBtn,
  textCenterBtn,
  onClickCenterBtn,
  iconCenterBtn,
  className,
}: IModal) => {
  const content = useRef<HTMLDivElement>(null);

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!onCloseOut) return;
    const isClickRight = e.button === 0;
    const isOut = !(
      content.current && content.current.contains(e.target as Element)
    );
    if (isClickRight && isOut) onClose();
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = `hidden`;
    } else {
      document.body.style.overflow = `auto`;
    }
  }, [open]);

  if (!open) return null;

  return (
    <div className={`modalQuestion ${className}`} onMouseDown={onMouseDown}>
      <div
        className={`modalQuestion-container  ${
          bgContainer ? `bg-${bgContainer}` : ``
        }`}
        ref={content}
      >
        <div className="pos-relative">
          <h1
            className={`modalQuestion-container__title ff-secondary ${
              colorTitle ? `t-${colorTitle}` : ``
            } ${bgTitle ? `bg-${bgTitle}` : ``} `}
          >
            {title}
          </h1>
          <Button
            onClick={onClose}
            className="modalQuestion-container__close"
            bgColor="transparent"
            txtColor={colorClose}
            txtHover="red"
            iconName="close"
          />
        </div>
        <div className="modalQuestion-container__children">{children}</div>
        {(onClickLeftBtn || onClickCenterBtn || onClickRightBtn) && (
          <div className="modalQuestion-container__btns">
            {(textLeftBtn || iconLeftBtn) && (
              <Button
                className="py-07 px-2 br-20px ff-primary fw-bold"
                bgColor="lightRed"
                txtColor="white"
                bgHover="darkRed"
                onClick={onClickLeftBtn}
                text={textLeftBtn}
                iconName={iconLeftBtn}
              />
            )}

            {(textCenterBtn || iconCenterBtn) && (
              <Button
                className="py-07 px-2 br-20px ff-primary  fw-bold"
                bgColor="orange"
                txtColor="white"
                txtHover="yellow"
                onClick={onClickCenterBtn}
                text={textCenterBtn}
                iconName={iconCenterBtn}
              />
            )}

            {(textRightBtn || iconRightBtn) && (
              <Button
                className="py-07 px-2 br-20px ff-primary fw-bold"
                bgColor="white"
                txtColor="orange"
                txtHover="yellow"
                bColor="orange"
                onClick={onClickRightBtn}
                text={textRightBtn}
                iconName={iconRightBtn}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalQuestion;
