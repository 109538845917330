import images from "assets/images";
import { Icon, Skeleton } from "components/atoms";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { IMeans } from "./props";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./styles.scss";

const Means = ({ loading, means, className }: IMeans) => {
  return (
    <section className={`${className}`}>
      {loading && (
        <Skeleton height="400px" bgColor="transparent" dotColor="yellow" />
      )}

      <div className="courses-means">
        <div className="courses-means_card">
          <h4 className="courses-means_h4 ff-secondary ta-center fs-subTitle">
            Recursos
          </h4>
          <div className="d-flex ai-center jc-center bg-white h-100por px-1rem">
            <Swiper
              spaceBetween={0}
              slidesPerView={3}
              breakpoints={{
                360: {
                  slidesPerView: 1,
                },
                480: {
                  slidesPerView: 1,
                },
                640: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
              navigation={true}
              modules={[Navigation]}
            >
              {means
                .filter((mean: any) => mean.pdf != `` && mean.pdf != null)
                .map((item: any, index: any) => (
                  <SwiperSlide key={index}>
                    <h5 className="courses-means-btn_title ff-primary fs-questions">
                      {item?.title}
                    </h5>
                    <div className="courses-means-btn">
                      <div className="courses-means-btn_div">
                        <img
                          className="courses-means-btn_div__img"
                          src={item?.img}
                          alt="Resumen"
                        />
                        <div className="courses-means-btn_div__hover">
                          {/* <img
                            className="courses-means-btn_div__hover___img"
                            alt="pdf"
                            src={images.Pdf}
                          /> */}
                          <a
                            href={item.pdf}
                            target="_blank"
                            rel="noopener noreferrer"
                            download={`${item?.title}.pdf`}
                            className="courses-means-btn_div__hover___a hov-t-yellow d-flex fd-column ai-center jc-center"
                          >
                            <Icon name="download" list="items" color="white" width={30} height={30}/>
                            Clic para descargar
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Means;
