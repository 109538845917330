import { useLayoutEffect, useMemo, useState } from "react";

import { Button } from "components/atoms";
import { Layout } from "components/organisms";
import SearchContent from "../../../components/organisms/header/childrens/searchContent/index";
import { Content } from "./childrens";
import { getCourses } from "./services";

const All = () => {
  const [search, setSearch] = useState(``);
  const [page, setPage] = useState(1);
  const [dataCourses, setDataCourses] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({
    pageIndex: 0,
    totalItems: 0,
    pagination: true,
  });
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    getData();
  }, []);

  const isSmall = useMemo(() => screen.width <= 1150, [screen]);

  const onSearch = () => {
    //setSearch(search.trim());
    setPage(1);
    setPaginationInfo({
      pageIndex: 0,
      totalItems: 0,
      pagination: true,
    });
    getCourses({
      setLoading: setLoading,
      search: search,
      paged: 1,
      setDataCourses: setDataCourses,
      setPaginationInfo: setPaginationInfo,
      dataCourses: [],
    });
  };

  const getData = () => {
    if (!paginationInfo.pagination) return false;
    window.scrollTo(0, 0);
    getCourses({
      setLoading: setLoading,
      search: search.trim(),
      paged: page,
      setDataCourses: setDataCourses,
      setPaginationInfo: setPaginationInfo,
      dataCourses: dataCourses,
    });
    setPage(page + 1);
  };

  const onShowMore = (paged: any) => {
    getData();
  };

  return (
    <Layout>
      <SearchContent onSearch={onSearch} setSearch={setSearch} />
      <section className="bg-white">
        <Content
          search={search.trim()}
          page={1}
          data={dataCourses}
          loading={loading}
        />
        <div className="d-flex jc-center ai-center pb-3rem container">
          <Button
            text="Cargar más cursos"
            txtHover="yellow"
            className="ff-primary fs-21px fw-600"
            onClick={() => onShowMore(page)}
            disabled={!paginationInfo.pagination}
          />
        </div>
      </section>
    </Layout>
  );
};

export default All;
