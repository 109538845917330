import images from "assets/images";
import { Button, Checkbox, Input } from "components/atoms";
import envs from "constants/envs";
import errorsForm from "constants/errorsForm";
import links from "constants/links";
import { useLayoutEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { injectGoogleJs } from "tools/googleAuth";
import "../styles.scss";
import { FLogin } from "./props";
import { rules } from "./rules";
import { handleGoogleSignIn, handleSignIn } from "./services";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [isHiddenEye, setIsHiddenEye] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FLogin>({
    mode: `onChange`,
    defaultValues: {
      email: atob(localStorage.getItem(`email`) || ``),
      remember: !!localStorage.getItem(`email`),
    },
  });

  useLayoutEffect(() => {
    injectGoogleJs();
    //@ts-ignore
    window.handleGoogleSignIn = handleGoogleSignIn;
  }, []);

  const onSubmit = (values: FLogin) => handleSignIn({ setLoading, values });

  const onBack = () => {
    window.location.href = `/`;
  };

  const onHiddenEye = () => {
    setIsHiddenEye(!isHiddenEye);
  };

  return (
    <section className="login">
      <div className="login-card bg-white w-450px mx-1rem pb-3rem">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="head bg-yellow d-flex ac-ai-jc-center">
            <button
              className="button-return bg-yellow t-black hov-t-red d-flex ai-center jc-center"
              onClick={() => onBack()}
            >
              <img src={images.HomeKumpai} className="w-40px" />
            </button>
            <span className="fs-20px fw-400 ff-secondary ml-6rem mr-9rem">
              INICIAR SESIÓN
            </span>
          </div>
          <div className="body">
            <div className="py-2rem">
              <img src={images.MabRed} className="w-25por" />
            </div>

            <div
              id="g_id_onload"
              data-client_id={envs.googleId}
              data-callback="handleGoogleSignIn"
              data-auto_prompt="false"
              className="d-flex ac-ai-jc-center pb-2rem"
            >
              <div
                className="g_id_signin"
                data-type="standard"
                data-shape="pill"
                data-theme="filled"
                data-text="signin_with"
                data-size="large"
                data-logo_alignment="left"
              />
            </div>

            <div className="d-flex ac-ai-jc-center mb-2rem">
              <div className="separator  w-75por">
                <span className="fs-14px fw-bold">O</span>
              </div>
            </div>

            <div className="pb-1rem px-3rem">
              <Controller
                control={control}
                name="email"
                rules={rules.email}
                render={({ field: { value, onChange, onBlur } }) => (
                  <Input
                    type="email"
                    placeholder="Correo electrónico"
                    value={value || ``}
                    onChange={onChange}
                    onBlur={onBlur}
                    iconList="access"
                    iconNameLeft="email"
                    iconLeft={true}
                    messageError={errorsForm[errors?.email?.type || ``]}
                  />
                )}
              />
            </div>

            <div className="pb-0-1rem px-3rem">
              <Controller
                control={control}
                name="password"
                rules={rules.password}
                render={({ field: { value, onChange, onBlur } }) => (
                  <Input
                    type={isHiddenEye ? `text` : `password`}
                    placeholder="Contraseña"
                    value={value || ``}
                    onChange={onChange}
                    onBlur={onBlur}
                    iconList="access"
                    iconNameLeft="password"
                    iconLeft={true}
                    iconNameRight={isHiddenEye ? `eye` : `eyeActive`}
                    iconRight={true}
                    onClickRight={onHiddenEye}
                    messageError={errorsForm[errors?.password?.type || ``]}
                  />
                )}
              />
            </div>

            <div className="w-85por pb-1rem ta-right">
              <Link
                className="l-forgot fs-18px bg-transparent"
                to={links.recoveryCredential}
              >
                Olvidé mi contraseña
              </Link>
            </div>

            <div className="w-85por pl-3rem pb-1rem">
              <Controller
                control={control}
                name="remember"
                render={({ field: { value, onChange, onBlur } }) => (
                  <Checkbox
                    checked={value || false}
                    onChange={onChange}
                    onBlur={onBlur}
                    text="Recuérdame"
                  />
                )}
              />
            </div>

            <Button
              type="submit"
              loading={loading}
              className="btnAccess bg-yellow t-black ff-secondary fw-900 fs-20px w-75por"
              text="Ingresar"
            />

            <div className="pt-1rem">
              <span className="fs-16px">¿Aún no tienes cuenta?, </span>
              <Link
                to={links.register}
                className="ff-secondary fs-18px l-highlight px-0-3rem l-black  hov-l-red foc-l-black "
              >
                REGÍSTRATE AQUÍ
              </Link>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Login;
