import { ILogo } from "./props";
import "./styles.scss";

const Logo = ({
  className,
  bgColor,
  h1Color,
  h2Color,
  h1Size,
  h2Size,
  h3Size,
}: ILogo) => {
  return (
    <aside className={`${className} logo_text bg-${bgColor}`}>
      <div className={`logo_text__title`}>
        <p className={`logo_text__h1 ff-secondary t-${h1Color} fs-${h1Size}`}>
          ESPACIO
        </p>
        <p className={`logo_text__h2 ff-secondary t-${h2Color} fs-${h2Size}`}>
          KUMPAI
        </p>
        <p className={`logo_text__h3 ff-primary fs-${h3Size}`}>
          Tu aula segura
        </p>
      </div>
    </aside>
  );
};

Logo.defaultProps = {
  h1Color: `orange`,
  h2Color: `orange`,
  h1Size: `35px`,
  h2Size: `50px`,
  h3Size: `m`,
};

export default Logo;
