import { Skeleton } from "components/atoms";
import { Container } from "components/molecules/grid";
import { Units } from "./childrens";
import { IUnitsContent } from "./props";
import "./styles.scss";

const UnitsContent = ({
  loading,
  idCourse,
  dataFirstUnit,
  dataUnitsContent,
}: IUnitsContent) => {
  return (
    <Container className="course-unitsContent py-2rem px-1rem">
      {loading ? (
        <Skeleton height="450px" dotColor="gray" />
      ) : (
        <>
          <Units
            idCourse={idCourse}
            firstUnit={dataFirstUnit}
            unitsContent={dataUnitsContent}
            className="course-unitsContent-units w-100por"
          />
        </>
      )}
    </Container>
  );
};

export default UnitsContent;
