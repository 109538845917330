import images from "assets/images";
import { Button } from "components/atoms";
import RadioButtonOption from "components/atoms/radioButtonOption";
import { Col, Container, Row } from "components/molecules/grid";
import { useLayoutEffect, useState } from "react";
import { IReview } from "./props";
import { handleStepResult } from "./services";

const Review = ({
  idCourse,
  idTopic,
  setStepReview,
  setDataReviewSatisfactory,
}: IReview) => {
  const [loading, setLoading] = useState(false);
  const [stateAnswer, setStateAnswer] = useState(`0`);
  const [isDisabledOptions, setIsDisabledOptions] = useState(false);
  const [correct, setCorrect] = useState(0);
  const [incorrect, setIncorrect] = useState(0);
  //SI EN CASO AVANZO CON EL TEST RECUPERAMOS DESDE EL LOCAL STORAGE
  const storageQuestions: any = localStorage.getItem(`reviewArrQuestion`);
  //SI EN CASO ES UN TEST NUEVO O EL LOCALSTORAGE SE LIMPIO LE ASIGNAMOS EL JSON QUE SE TIENE
  const [questions, setQuestions] = useState(
    JSON.parse(storageQuestions) || []
  );
  //ESTADO PARA TENER EL ID DE LA ULTIMA PREGUNTA DONDE SE QUEDO
  const [currentIdQuestion, setCurrentIdQuestion] = useState(
    Number(localStorage.getItem(`reviewCurrentIdQuestion`)) || 1
  );
  //ESTADO PARA TENER LA DATA DEL ULTIMA PREGUNTA DONDE SE QUEDO
  const [currentQuestion, setCurrentQuestion] = useState([
    { id: 0, title: ``, dimention: ``, options: [], answer: -1 },
  ]);
  //ESTADO PARA LOS BOTONOS DE ATRAS ADELANTE Y FINALIZAR
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [isFinishTest, setIsFinishTest] = useState(false);
  //const isBackDisabled = currentIdQuestion === 1 ? true : false;

  //INICIALIZAR LA PRIMERA O EN LA ULTIMA PREGUNTA DONDE SE QUEDO
  const callInit = () => {
    const arrQuestion: any = questions.filter(
      (item: any) => item.id === currentIdQuestion
    );
    setCurrentQuestion(arrQuestion);
    console.log(arrQuestion);
    //SI HAY UNA OPCION MARCADA EL BOTON SIGUIENTE ESTARÁ HABILITADO
    if (arrQuestion[0].answer == 0) {
      setIsDisabledOptions(false);
      setIsNextDisabled(true);
    } else {
      setIsDisabledOptions(true);
      setIsNextDisabled(false);
      getStateAnswer(currentIdQuestion);
    }

    currentIdQuestion == questions.length
      ? setIsFinishTest(true)
      : setIsFinishTest(false);
  };

  useLayoutEffect(() => {
    callInit();
  }, []);

  const getStateAnswer = (idQuestion: any) => {
    const valueStateAnswer = questions.filter(
      (item: any) => item.id == idQuestion
    );

    //VERIFICAR SI ES CORRECTO LO MARCADO
    if (valueStateAnswer[0].answer == valueStateAnswer[0].right) {
      setStateAnswer(`2`);
      setCorrect(correct + 1);
    } else {
      setStateAnswer(`1`);
      setIncorrect(incorrect + 1);
    }

    return valueStateAnswer;
  };

  const handleAnswer = (idQuestion: any, credit: any) => {
    //ACTUALIZAR EL ARRAY QUESTIONS
    const oldQuestions = questions;
    const newQuestions = oldQuestions.map((p: any) => {
      if (p.id == idQuestion) {
        p.answer = credit;
        return p;
      }
      return p;
    });
    setQuestions(newQuestions);
    //OBTENER EL ESTADO DE LA RESPUESTA
    getStateAnswer(idQuestion);
    //GUARDAR EN EL STORAGE
    localStorage.setItem(`reviewArrQuestion`, JSON.stringify(questions));
    //localStorage.setItem(`currentIdQuestion`, idQuestion);
    setIsDisabledOptions(true);

    //HABILITAR EL BOTON SIGUIENTE
    setIsNextDisabled(false);
    //SI SELECCIONA LA ULTIMA PREGUNTA HABILITAR EL BOTON FINALIZAR
    currentIdQuestion == questions.length
      ? setIsFinishTest(true)
      : setIsFinishTest(false);
  };

  const handlePrevious = () => {
    const arrQuestion: any = questions.filter(
      (item: any) => item.id === currentIdQuestion - 1
    );
    setCurrentQuestion(arrQuestion);
    setCurrentIdQuestion(currentIdQuestion - 1);

    //SI HAY UNA OPCION MARCADA EL BOTON SIGUIENTE ESTARÁ HABILITADO
    //arrQuestion[0].answer == -1
    //? setIsNextDisabled(true)
    //: setIsNextDisabled(false);
    setIsNextDisabled(true);
    //DESHABILITAR EL FINISH TEST
    setIsFinishTest(false);
  };

  const handleNext = () => {
    //FILTRAR PREGUNTAS
    const arrQuestion: any = questions.filter(
      (item: any) => item.id === currentIdQuestion + 1
    );
    setCurrentQuestion(arrQuestion);
    setCurrentIdQuestion(currentIdQuestion + 1);
    //GUARDAR EN EL LOCAL STORAGE
    localStorage.setItem(`reviewCurrentIdQuestion`, arrQuestion[0].id);
    //SI HAY UNA OPCION MARCADA EL BOTON SIGUIENTE ESTARÁ HABILITADO
    if (arrQuestion[0].answer == 0) {
      setIsNextDisabled(true);
      setIsFinishTest(false);
    } else {
      setIsNextDisabled(false);
      currentIdQuestion + 1 == questions.length
        ? setIsFinishTest(true)
        : setIsFinishTest(false);
    }
    setStateAnswer(`0`);
    setIsDisabledOptions(false);
  };

  const refreshOptions = (idQuestion: any) => {
    //DESHABILITAR OPCIONES
    const options: HTMLElement | any = document.getElementsByName(
      `options-` + idQuestion
    );
    console.log(options);
    for (let i = 0; i < options.length; i++) {
      options[i].disabled = false;
    }
  };

  const numberToLetterAlphabet = (number: number) => {
    const arrAlphabet = [`A`, `B`, `C`, `D`, `E`, `F`];
    return arrAlphabet[number];
  };

  const handleResult = () =>
    handleStepResult({
      setLoading: setLoading,
      idCourse: idCourse,
      idTopic: idTopic,
      setStepReview: setStepReview,
      setDataReviewSatisfactory: setDataReviewSatisfactory,
    });

  return (
    <Container className="px-1rem pb-2rem">
      <Row>
        <Col lg="12" className="pt-1rem d-flex ai-center jc-center">
          <p className="tp-question-progress bg-cyan p-0-5rem t-black">
            <span>{currentIdQuestion}</span> de <span>{questions.length}</span>
          </p>
        </Col>
      </Row>
      <Container className="bg-white ">
        <Row className="pt-1rem">
          <Col lg="12" className="ta-left">
            <span className="ta-center ff-primary t-black fs-18px p-1rem fw-bold">
              {currentQuestion[0].id}.- {currentQuestion[0].title}
            </span>
          </Col>
        </Row>
        <Row className="px-2rem py-2rem">
          <Col lg="10" col="12" className="ta-left">
            {currentQuestion[0].options.map((item: any, index: any) => (
              <RadioButtonOption
                key={index}
                name={`options-${currentQuestion[0].id}`}
                id={`option-${currentQuestion[0].id}-${index + 1}`}
                text={`${numberToLetterAlphabet(index)}) ${item.option}`}
                value={index + 1}
                checked={index + 1 === currentQuestion[0].answer ? true : false}
                onChange={() => handleAnswer(currentQuestion[0].id, index + 1)}
                disabled={isDisabledOptions}
              />
            ))}
          </Col>
          <Col lg="2" col="12" className="d-flex ai-center jc-center">
            {stateAnswer == `1` ? <img src={images.Incorrect}></img> : null}
            {stateAnswer == `2` ? <img src={images.Correct}></img> : null}
          </Col>
        </Row>
        <Row className="px-2rem">
          <Col lg="7" className="pb-1rem">
            <span className="fs-10px fw-bold ">
              * Recuerda que debes tener más de 3 respuestas correctas para
              aprobar el repaso.
            </span>
          </Col>
          <Col lg="5" className="d-flex ai-center jc-right ">
            {/*<Button
                iconList="arrows"
                iconName="back-alt"
                txtColor="black"
                bgColor="white"
                bgHover="turquoise"
                text="Atrás"
                className={`ff-primary fw-bold px-2rem br-20px mr-1rem`}
                onClick={() => handlePrevious()}
                disabled={isBackDisabled}
                iconPosition="left"               

                  />*/}

            {!isFinishTest && (
              <Button
                iconList="arrows"
                iconName="next-alt"
                txtColor="black"
                bgColor="white"
                bgHover="turquoise"
                text="Siguiente"
                className="ff-primary fw-bold px-2rem br-20px ml-1rem"
                onClick={() => handleNext()}
                disabled={isNextDisabled}
              />
            )}
            {isFinishTest && currentIdQuestion == questions.length && (
              <Button
                iconList="arrows"
                iconName="next-alt"
                txtColor="black"
                bgColor="white"
                bgHover="turquoise"
                text="Finalizar"
                className="ff-primary fw-bold px-2rem br-20px ml-1rem"
                onClick={() => handleResult()}
              />
            )}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Review;
