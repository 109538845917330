import images from "assets/images";
import { Card } from "components/atoms";
import { useState } from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.scss";

const Discovery = () => {
  const [courses, setCourses] = useState([
    {
      _id: `31710`,
      name: `Repostería saludable`,
      description: `Estefania nos enseñará a hacer las tradicionales recetas de repostería en versiones aptas para veganos...`,
      duration: '1',
      teacher: `Estefania Kahan`,
      thumbnail: images.LandingCourse1,
      color: `magenta`,
      link: `reporteria-saludable`,
      price: 0,
      likes: 1,
      grade: ``,
    },
    {
      _id: `41689`,
      name: `Deporte y actividad física`,
      description: `El curso contiene fundamentos que describen fácilmente cómo diferenciar la actividad física y el...`,
      duration: '1',
      teacher: `Mariella Catter`,
      thumbnail: images.LandingCourse2,
      color: `limon`,
      link: `deporte-actividad-fisica`,
      price: 0,
      likes: 6,
      grade: ``,
    },
    {
      _id: `33142`,
      name: `Audiolibros 4 años`,
      description: `Paula nos contará diversos cuentos entretenidos en el cual retará nuestra comprensión auditiva y lectora.`,
      duration: '1',
      teacher: `Paula Lascurain`,
      thumbnail: images.LandingCourse3,
      color: `indigo`,
      link: `audiolibros-4-años`,
      price: 0,
      likes: 12,
      grade: ``,
    },
    {
      _id: `983`,
      name: `Ciencia y Tecnología 5`,
      description: `Revisaremos los temas de ciencia y tecnología de 5to grado ....`,
      duration: '1',
      teacher: `Almudena Arribas`,
      thumbnail: images.LandingCourse4,
      color: `cyan`,
      link: `amistando`,
      price: 0,
      likes: 190,
      grade: ``,
    },
    {
      _id: `39037`,
      name: `Audiolibros 3 años`,
      description: `Paula nos contará diversos cuentos entretenidos en el cual retará nuestra comprensión auditiva y lectora.`,
      duration: '1',
      teacher: `Paula Lascurain`,
      thumbnail: images.LandingCourse3,
      color: `magenta`,
      link: `audiolibros-3-años`,
      price: 0,
      likes: 1,
      grade: ``,
    },
    {
      _id: `35852`,
      name: `Comprensión de textos III`,
      description: `Paula nos contará diversos cuentos entretenidos en el cual retará nuestra comprensión auditiva y lectora.`,
      duration: '1',
      teacher: `Paula Lascurain`,
      thumbnail: images.LandingCourse3,
      color: `limon`,
      link: `comprensión-de-textos-iii`,
      price: 0,
      likes: 3,
      grade: ``,
    },
    {
      _id: `415`,
      name: `Castellano I`,
      description: `En este curso aprenderemos los tipos o clases de entonación, palabras compuestas, entre otros.`,
      duration: '1',
      teacher: `Celine Hatchwell`,
      thumbnail: images.LandingCourse5,
      color: `orange`,
      link: `castellano-i`,
      price: 0,
      likes: 26,
      grade: ``,
    },
    {
      _id: `3225`,
      name: `Comunicación 2`,
      description: `Reforzaremos los de temas de comunicación de 2do grado, conociendo todo tipo de textos, como las historietas, fábulas y leyendas.`,
      duration: '1',
      teacher: `Paula Lascurain`,
      thumbnail: images.LandingCourse6,
      color: `indigo`,
      link: `Comunicación-2`,
      price: 0,
      likes: 68,
      grade: ``,
    },
  ]);

  return (
    <div className="py-3rem px-2rem d-flex jc-center ai-center fd-column">
      <span className="ff-secondary fs-19px fw-bold ta-center pb-1rem">
        Descubre más
      </span>
      <Swiper
        spaceBetween={10}
        slidesPerView={4}
        navigation={true}
        modules={[Navigation]}
        breakpoints={{
          360: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          480: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        }}
      >
        {courses.map((item, index) => (
          <SwiperSlide key={index}>
            <Card
              _id={item._id}
              id={item._id}
              name={item.name}
              description={item.description}
              duration={item.duration}
              color={item.color}
              thumbnail={item.thumbnail}
              teacher={item.teacher}
              price={item.price}
              likes={item.likes}
              link={item.link}
              grade={item.grade}
              isLikes={false}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Discovery;
