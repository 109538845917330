import images from "assets/images";
import { Col, Row } from "components/molecules/grid";
import { IKpis } from "./props";
import "./styles.scss";

const Kpis = ({ dataProgress }: IKpis) => {
  const numberToString = (number: number) => {
    if (number <= 9) {
      return `0` + number;
    } else {
      return number;
    }
  };

  return (
    <Row>
      <Col lg="4" col="4" className="pt-3rem">
        <div className="kpi">
          <div className="kpi-content d-flex jc-center ai-center pt-1-5rem">
            <span className="ff-secondary fs-70px t-black">
              {numberToString(dataProgress.numberOfCourses)}
            </span>
            <img
              className="ml-1rem w-30por desktop"
              src={images.ProfileCourseEnrolled}
            ></img>
          </div>
          <div className="kpi-footer d-flex jc-center ai-center">
            <span className="ta-center fs-16px fw-bold">
              Cursos <br /> inscritos
            </span>
          </div>
        </div>
      </Col>
      <Col lg="4" col="4" className="pt-3rem">
        <div className="kpi">
          <div className="kpi-content d-flex jc-center ai-center pt-1-5rem">
            <span className="ff-secondary fs-70px t-green">
              {numberToString(dataProgress.numberOfTopics)}
            </span>
            <img
              className="ml-1rem w-30por desktop"
              src={images.ProfileCourseCompleted}
            ></img>
          </div>
          <div className="kpi-footer d-flex jc-center ai-center">
            <span className="ta-center fs-16px fw-bold">
              Cursos <br /> aprobados
            </span>
          </div>
        </div>
      </Col>
      <Col lg="4" col="4" className="pt-3rem">
        <div className="kpi">
          <div className="kpi-content d-flex jc-center ai-center pt-1-5rem">
            <span className="ff-secondary fs-70px t-orange">
              {numberToString(dataProgress.numberCertificates)}
            </span>
            <img
              className="ml-1rem w-30por desktop"
              src={images.ProfileCourseCertificated}
            ></img>
          </div>
          <div className="kpi-footer d-flex jc-center ai-center">
            <span className="ta-center fs-16px fw-bold">
              Diplomas <br /> obtenidas
            </span>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Kpis;
