import images from "assets/images";
import { Button, Input } from "components/atoms";
import errorsForm from "constants/errorsForm";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import "../styles.scss";
import { FGeneratePassword } from "./props";
import { rules } from "./rules";
import { handleGeneratePassword } from "./services";

const GeneratePassword = () => {
  const query = new URLSearchParams(useLocation().search);
  const token = query.get(`t`);
  const names = query.get(`names`);
  const email = query.get(`email`);
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FGeneratePassword>({
    mode: `onChange`,
    defaultValues: {
      password: ``,
      repeatPassword: ``,
      token: token,
    },
  });

  const onSubmit = (values: FGeneratePassword) =>
    handleGeneratePassword({ setLoading, values });

  return (
    <div>
      <section className="login">
        <div className="login-card bg-white w-450px mx-1rem mb-2rem pb-3rem">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="head bg-yellow d-flex ac-ai-jc-center">
              <span className="fs-20px fw-400 ff-secondary">
                GENERAR CONTRASEÑA
              </span>
            </div>
            <div className="body">
              <div className="pt-2rem pb-1rem">
                <img src={images.MabRed} className="w-25por" />
              </div>

              <div className="pb-2rem">
                <h2 className="ff-secondary">HOLA, {names} </h2>
                <span className="ff-primary fs-16px">{email}</span>
              </div>

              <div className="pt-1rem pb-1rem px-3rem ta-left">
                <span>Nueva contraseña</span>
                <Controller
                  control={control}
                  name="password"
                  rules={rules.password}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Input
                      type="password"
                      placeholder="Nueva contraseña"
                      value={value || ``}
                      onChange={onChange}
                      onBlur={onBlur}
                      iconList="access"
                      iconNameLeft="password"
                      iconLeft={true}
                      messageError={errorsForm[errors?.password?.type || ``]}
                    />
                  )}
                />
              </div>

              <div className="pt-1rem pb-2rem px-3rem ta-left">
                <span>Repetir contraseña</span>
                <Controller
                  control={control}
                  name="repeatPassword"
                  rules={rules.repeatPassword}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Input
                      type="password"
                      placeholder="Repetir contraseña"
                      value={value || ``}
                      onChange={onChange}
                      onBlur={onBlur}
                      iconList="access"
                      iconNameLeft="password"
                      iconLeft={true}
                      messageError={
                        errorsForm[errors?.repeatPassword?.type || ``]
                      }
                    />
                  )}
                />
              </div>
              <Button
                type="submit"
                loading={loading}
                className="btnAccess bg-yellow t-black ff-secondary fw-900 fs-20px w-75por "
                text="Generar"
              />
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default GeneratePassword;
