import { useEffect, useRef, useState } from "react";
import accessList from "./lists/access.svg";
import arrowsList from "./lists/arrows.svg";
import buttonsList from "./lists/buttons.svg";
import courseList from "./lists/course.svg";
import itemsList from "./lists/items.svg";
import kumpaiList from "./lists/kumpai.svg";
import socialList from "./lists/social.svg";
import { IIcon } from "./props";

const Icon = ({
  name,
  className,
  list,
  onClick,
  width,
  height,
  color,
}: IIcon) => {
  const icon = useRef<SVGSVGElement>(null);
  const [hasStroke, setHasStroke] = useState(false);

  const lists = {
    items: itemsList,
    buttons: buttonsList,
    arrows: arrowsList,
    social: socialList,
    access: accessList,
    course: courseList,
    kumpai: kumpaiList,
  };

  useEffect(() => {
    const iconRef = icon?.current?.children?.[0]?.getAttribute(`xlink:href`);
    const iconPath = iconRef?.split(`#`)?.[1];

    if (iconPath?.includes(`stroke`)) setHasStroke(true);
  }, []);

  return (
    <svg
      ref={icon}
      onClick={onClick}
      className={`
        ${className || ``}
        ${hasStroke ? `stroke-${color}` : `fill-${color}`}
      `}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
    >
      <use xlinkHref={`${lists[list || `items`]}#${name}`} />
    </svg>
  );
};

Icon.defaultProps = {
  width: 24,
  height: 24,
};

export default Icon;
