import { ContainerFluid } from "components/molecules/grid";
import { Layout } from "components/organisms";
import { useLayoutEffect, useState } from "react";
import { Init, Question, Result } from "./children";
import { getResult } from "./services";

const Motivation = () => {
  const [loading, setLoading] = useState(false);
  const [stepTest, setStepTest] = useState(
    localStorage.getItem(`testMotivationStep`) || `init`
  );

  useLayoutEffect(() => {
    getResult(setLoading, setStepTest);
  }, []);

  return (
    <Layout>
      <ContainerFluid className="bg-yellow">
        {stepTest === `init` ? <Init setStepTest={setStepTest} /> : null}
        {stepTest === `question` ? (
          <Question setStepTest={setStepTest} />
        ) : null}
        {stepTest === `result` ? <Result /> : null}
      </ContainerFluid>
    </Layout>
  );
};

export default Motivation;
