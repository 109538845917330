import "./styles.scss";

const SectionAbility = () => {
  return (
    <section className="landing">
      <div className="landing-container-lr">
        <div className="bg-white responsive-container-l d-flex jc-end">
          <div className="landing-content-l">
            <div className="d-flex ai-center jc-center px-2rem">
              <div className="w-50por ai-center jc-center ta-center">
                <span className="fs-30px t-yellow">
                  <b>+35,000</b>
                </span>
              </div>
              <div className="w-50por ai-center jc-center ta-center">
                <span className="fs-18px t-black">
                  <b>
                    Familias son parte de la
                    <br />
                    revolución educativa.
                  </b>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-black responsive-container-r">
          <div className="landing-content-r">
            <div className="d-flex ai-center jc-center px-2rem">
              <div className="w-50por ai-center jc-center ta-center">
                <span className="fs-30px t-yellow">
                  <b>+100,000</b>
                </span>
              </div>
              <div className="w-50por ai-center jc-center ta-center">
                <span className="fs-18px t-white">
                  <b>
                    Estudiantes beneficiados
                    <br />
                    virtualmente.
                  </b>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionAbility;
