import images from "assets/images";
import { Col, Container, Row } from "components/molecules/grid";
import "./styles.scss";

const SectionTool = () => {
  return (
    <section className="bg-yellow t-black pt-1rem pb-1rem">
      <Container>
        <Row className="ai-center p-2rem m-0rem">
          <Col lg="6">
            <img src={images.LandingTool1} className="w-100por" />
          </Col>
          <Col lg="6">
            <img src={images.LandingTool2} className="w-100por" />
          </Col>
        </Row>
      </Container>

      <Container className="pb-1rem">
        <Row className="m-0rem p-0rem">
          <Col lg="12" className="d-flex ai-center jc-center ta-center">
            <div className="text-center">
              <span className="tools-st t-black ">
                <b>HERRAMIENTAS EDUCATIVAS</b>
              </span>
            </div>
          </Col>
        </Row>
      </Container>

      {/*<Container className="pb-1rem">
        <Row>
          <Col lg="12" className="d-flex ai-center jc-center ta-center">
            <div className="ta-center">
              <span className="tools-t t-red">
                <b>+30,000</b>&nbsp;
              </span>
            </div>
            <div className="text-center">
              <span className="tools-st t-black ">
                <b>HERRAMIENTAS EDUCATIVAS</b>
              </span>
            </div>
          </Col>
        </Row>
  </Container>*/}

      <Container className="pb-2rem">
        <Row className="jc-center m-0rem p-0rem">
          <Col lg="8" className="ta-center">
            <span className="fs-18px">
              Todos nuestros cursos incluyen material complementario que se
              adaptan a los diferentes estilos de aprendizaje, como{` `}
              <b>
                hojas de trabajo, resúmenes, mapas mentales, solucionarios,
                <br /> ¡y más!
              </b>
            </span>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SectionTool;
