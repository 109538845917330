import { Icon, Tooltip } from "components/atoms";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { IButton } from "./props";
import "./styles.scss";

const Button = ({
  id,
  type,
  onClick,
  onMouseUp,
  disabled,
  className,
  text,
  bgColor,
  bgHover,
  txtColor,
  txtHover,
  bColor,
  iconName,
  iconList,
  iconAnimate,
  redirect,
  redirectExternal,
  iconSize,
  iconPosition,
  loading,
  bsColor,
  txtFamily,
  dataClientId,
  dataCallback,
  tooltip,
  target,
  rel,
  download,
  IconClass,
  children,
}: IButton) => {
  const ref = useRef(null);
  const ComponentLink = redirectExternal ? `a` : Link;
  const Component = redirect ? ComponentLink : `button`;

  return (
    <>
      <Component
        id={id}
        ref={ref}
        to={redirect || ``}
        href={redirect || ``}
        rel={rel}
        target={target}
        download={download}
        onClick={!redirect && !loading ? onClick : undefined}
        onMouseUp={!redirect && !loading ? onMouseUp : undefined}
        data-client-id={dataClientId}
        data-callback={dataCallback}
        type={type}
        className={`
          btn
          ${className || ``}
          ${txtFamily ? txtFamily : ``}
          ${txtColor ? `t-${txtColor}` : ``}
          ${txtHover ? `hov-t-${txtHover}` : ``}
          ${txtHover && iconName ? `hov-svg-${txtHover}` : ``}
          ${bgColor ? `bg-${bgColor}` : ``}
          ${bgHover ? `hov-bg-${bgHover}` : ``}
          ${bColor ? `b-2px-${bColor}` : ``}
          ${bsColor ? `box-shadow-1px` : ``}
          ${disabled ? `disabled` : ``}
        `}
        disabled={loading || disabled}
      >
        {iconName && !loading && iconPosition === `left` && (
          <Icon
            className={`
              ${iconAnimate ? `throd-icon` : ``}
              ${text ? `mr-0-5rem` : ``}
            `}
            name={iconName}
            list={iconList}
            color={txtColor}
            width={iconSize}
            height={iconSize}
          />
        )}

        {loading ? `Cargando...` : <span>{text}</span>}

        {iconName && !loading && iconPosition === `right` && (
          <Icon
            className={`${IconClass}
              ${iconAnimate ? `throd-icon` : ``}
              ${text ? `ml-0-5rem` : ``}
            `}
            name={iconName}
            list={iconList}
            color={txtColor}
            width={iconSize}
            height={iconSize}
          />
        )}
        {children}
      </Component>

      {tooltip && <Tooltip reference={ref} text={tooltip} />}
    </>
  );
};

Button.defaultProps = {
  type: `button`,
  txtColor: `white`,
  bgColor: `red`,
  iconAnimate: false,
  iconSize: 20,
  iconPosition: `right`,
};

export default Button;
