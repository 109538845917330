import images from "assets/images";
import { IBanner } from "./props";
import "./styles.scss";

const Banner = ({ cover, name }: IBanner) => {
  return (
    <section className="speaker-banner">
      <div className="speaker-banner_img bg-yellow">
        <img src={images.BannerSocioEmotional} alt="" />
      </div>
      <div className="bg-yellow py-1rem px-1rem">
        <div className="speaker-banner_information container">
          <img src={cover} alt="" />
          <h2>{name}</h2>
          {/*<div>
            <p className="mb-0-5rem">
              <span>
                <Icon list="items" name="heart-line" color="black" />
              </span>
              {` `}
              Calificación de la instructora: 4.8
            </p>
            <p>
              <span>
                <Icon list="items" name="video-line" color="black" />
              </span>
              {` `}
              20 Cursos
            </p>
  </div>*/}
        </div>
      </div>
    </section>
  );
};

export default Banner;
