import "../styles.scss";
import { IRow } from "./props";

const Row = ({ className, ref, children }: IRow) => {
  return (
    <div className={`row ${className}`} ref={ref}>
      {children}
    </div>
  );
};

export default Row;
