import { Layout } from "components/organisms";
import { useEffect } from "react";
import {
  SectionAbility,
  SectionCourse,
  SectionExpert,
  SectionOne,
  SectionSponsor, SectionTestimony,
  SectionTool,
  SectionTwo
} from "./childrens";

const Landing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <SectionOne />
      <SectionTwo />
      <SectionCourse />
      {/*<SectionCategorie />
      <SectionTest />*/}
      <SectionTool />
      <SectionAbility />
      <SectionExpert />
      {/*<SectionTeam />*/}
      <SectionTestimony />
      <SectionSponsor />
      {/*<SectionGallery />*/}
    </Layout>
  );
};

export default Landing;
