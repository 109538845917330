import logo from "assets/images/logos/logo-amab.webp";
import heart from "assets/images/vectors/heart.webp";
import { Icon } from "components/atoms";
import links from "constants/links";
import urls from "constants/urls";
import { Link } from "react-router-dom";
import "./styles.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="content">
          <div className="section">
            <img src={logo} alt="logo" className="logo mb-1rem" />
            <div className="social">
              <h4 className="mt-1rem mb-1rem">encuéntranos en</h4>
              <div className="cards">
                <a className="mr-1rem" href={urls.facebook}>
                  <Icon list="social" name="facebook" color="black" />
                </a>
                <a className="mr-1rem" href={urls.instagram}>
                  <Icon list="social" name="instagram" color="black" />
                </a>
                <a className="mr-1rem" href={urls.linkedin}>
                  <Icon
                    list="social"
                    name="linkedin"
                    color="black"
                    width={22}
                  />
                </a>
                <a href={urls.youtube}>
                  <Icon list="social" name="youtube" color="black" />
                </a>
              </div>
            </div>
          </div>

          {/*<div className="section">
             <h4 className="title mt-0rem mb-1rem ml-1rem">Mundo MAB</h4>
            <Link className="option" to={links.us}>
              Nosotros
            </Link>
            <Link className="option" to={links.school}>
              Escuela de hoy
            </Link>
            <Link className="option" to={links.projects}>
              Proyectos amables
            </Link>
            <Link className="option" to={links.bookAndPlay}>
              Libro y juego
            </Link>
          </div>*/}

          <div className="section">
            <h4 className="title mt-0rem mb-1rem ml-1rem">Contacto</h4>
            <a className="option" href={urls.email}>
              <Icon
                list="social"
                name="email"
                color="white"
                width={18}
                height={18}
              />
              info@mablovers.com
            </a>

            <a
              className="option"
              href={urls.phone}
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                list="social"
                name="whatsapp"
                color="white"
                width={18}
                height={18}
              />
              946 151 823
            </a>
          </div>

          <div className="section ta-right">
            {/* <Button
              text="Dona aquí"
              txtHover="yellow"
              iconName="heart"
              className="mb-2rem"
              redirect={links.donations}
              iconAnimate={true}
            /> */}
            <Link className="option bold mb-1rem" to={links.complaintsBook}>
              <Icon list="social" name="claims" color="white" height={18} />
              Libro de reclamaciones
            </Link>
          </div>
        </div>

        <div className="vector">
          <figure>
            <img src={heart} alt="heart" />
          </figure>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
