import images from "assets/images";
import { Toast } from "components/atoms";
import regexs from "constants/regexs";
import { useRef, useState } from "react";
import { IAvatar } from "./props";
import "./styles.scss";

const Avatar = ({ dataAvatar, setDataAvatar }: IAvatar) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<Blob | MediaSource | [] | any>();

  const returnSrc = () => {
    if (!image) return images.User;
    if (typeof image === `string`) return image;
    return URL.createObjectURL(image);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file: any = e.target.files?.[0];

      if (!file) {
        //setImage(images.User);
        throw new Error(`No se ha seleccionado un archivo`);
      }

      if (!regexs.formatImage.test(file.type)) {
        //setImage(images.User);
        throw new Error(`El archivo debe ser una imagen`);
      }

      setImage(file);
    } catch (error: any) {
      Toast({
        type: `error`,
        message: error?.message || `Error al cargar la imagen`,
      });
    } finally {
      fileRef.current?.value && (fileRef.current.value = ``);
    }
  };

  return (
    <div className="pos-relative">
      <div className="user-avatar-container">
        <img src={images.RainbowProfile} alt="" className="rainbow" />

        <div className="user-avatar-img">
          {dataAvatar.avatar ? (
            <img src={dataAvatar.avatar} alt="" className="user" />
          ) : (
            <img src={returnSrc()} alt="" className="user" />
          )}
          {/*<Button
            className="edit-btn"
            iconName="pencil"
            iconList="buttons"
            iconSize={24}
            txtColor="white"
            bgColor="turquoise"
            txtHover="black"
            onClick={() => fileRef.current?.click()}
          />

          <input
            ref={fileRef}
            className="d-none"
            type="file"
            onChange={handleChange}
            accept="image/png, image/jpg, image/jpeg, image/webp"
          />*/}
        </div>
      </div>
    </div>
  );
};

export default Avatar;
