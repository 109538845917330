import { IStep } from "./props";
import "./styles.scss";

const Step = ({ steps, isButtonBack }: IStep) => {
  const handleBack = () => {
    window.history.back();
  };

  const lastStep = steps.length;

  return (
    <div className="pos-relative">
      <div className="steps">
        {steps.map((item, index) => {
          const classNameCircle = item.currentActive
            ? `bg-yellow t-black fw-900`
            : ``;
          const classNameLine = item.currentActive ? `bg-yellow` : ``;
          if (item.number === 1) {
            return (
              <div key={index} className="step">
                <div className={`circle ${classNameCircle}`}>{item.number}</div>
                <div className={`line ${classNameLine}`}></div>
              </div>
            );
          }

          if (item.number > 1 && item.number < lastStep) {
            return (
              <div key={index} className="step">
                <div className={`line ${classNameLine}`}></div>
                <div className={`circle ${classNameCircle}`}>{item.number}</div>
                <div className={`line ${classNameLine}`}></div>
              </div>
            );
          }

          if (item.number === lastStep) {
            return (
              <div key={index} className="step">
                <div className={`line ${classNameLine}`}></div>
                <div className={`circle ${classNameCircle}`}>{item.number}</div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default Step;
