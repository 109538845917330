import images from "assets/images";
import { Icon } from "components/atoms";
import { Col, Container, Row } from "components/molecules/grid";
import { IReviewSatisfactory } from "./props";

const ReviewSatisfactory = ({
  dataReviewSatisfactory,
}: IReviewSatisfactory) => {
  return (
    <Container className="p-3rem">
      <Row>
        <Col lg="12" className="d-flex ai-center jc-center">
          <Icon list="items" name="correct" />
          <p className="ml-1rem fw-bold ff-primary ta-center fs-21px">
            {dataReviewSatisfactory.correct} respuesta(s) correcta(s).
          </p>
        </Col>
      </Row>
      <Row>
        <Col lg="12" className="d-flex ai-center jc-center">
          <Icon list="items" name="incorrect" />
          <p className="ml-1rem fw-bold ff-primary ta-center fs-21px">
            {dataReviewSatisfactory.incorrect} respuesta(s) incorrecta(s).
          </p>
        </Col>
      </Row>
      <Row className="pt-2rem">
        <Col lg="12" className="d-flex ai-center jc-center">
          <img src={images.HeartVector} className="w-10por"></img>
        </Col>
      </Row>
    </Container>
  );
};

export default ReviewSatisfactory;
