import { Icon } from "components/atoms";
import { IInput } from "./props";
import "./styles.scss";

const input = ({
  type,
  iconNameRight,
  iconNameLeft,
  iconList,
  onChange,
  placeholder,
  value,
  readOnly,
  onKeyUp,
  onBlur,
  messageError,
  required,
  iconColor,
  iconLeft,
  iconRight,
  className,
  onClickRight,
}: IInput) => {
  return (
    <div className="input ">
      <div className="inputWithIcon">
        {iconLeft === true && (
          <div className="iLeft d-flex ac-ai-jc-center">
            <Icon
              name={`${iconNameLeft}`}
              className={`ml-0-2rem`}
              list={iconList}
              color={iconColor}
              width={20}
              height={20}
            />
          </div>
        )}

        <input
          type={type}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange && onChange(e.target.value);
          }}
          value={value}
          autoComplete="none"
          readOnly={readOnly}
          required={required}
          onBlur={onBlur}
          onKeyUp={onKeyUp}
          className={`${className} bg-white fs-16px ff-primary`}
          placeholder={placeholder}
        />

        {iconRight === true && (
          <div className="iRight d-flex ac-ai-jc-center" onClick={onClickRight}>
            <Icon
              name={`${iconNameRight}`}
              className={`mr-0-2rem`}
              list={iconList}
              color={iconColor}
              width={20}
              height={20}
            />
          </div>
        )}
      </div>

      {messageError && <p className="input_error">{messageError}</p>}
    </div>
  );
};

input.defaultProps = {
  type: `text`,
  iconColor: `silver`,
  size: 52,
};

export default input;
