import images from "assets/images";
import { Col, Container, Row } from "components/molecules/grid";
import { Layout } from "components/organisms";
import { useEffect } from "react";
import "./styles.scss";

const Us = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Container>
        <Row>
          <Col lg="5">
            <div className="py-2rem">
              <h1 className="t-black mb-2rem">
                <span className="d-block">
                  Educación <br /> que te hace{` `}
                </span>
                <span className="t-red h0 big">CRECER</span>
              </h1>
              <p>
                Somos una comunidad de personas que creemos en una educación
                integral para todos y para todas, donde hay un balance
                académico, emocional y creativo.
              </p>
            </div>
          </Col>
          <Col lg="7" className="d-flex">
            <div className="pos-relative d-flex">
              <img
                className="w-100por of--contain"
                src={images.WorldUsBanner}
                alt=""
              />
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="bg-yellow">
        <Row>
          <Col lg="5" className="desktop">
            <div className="d-flex">
              <div className="w-100por">
                <img src="" className="imgUs" />
              </div>
            </div>
          </Col>
          <Col lg="7" className="py-2rem">
            <h2 className="mb-2rem">Habilidades para la vida</h2>
            <p>
              Trabajamos fortaleciendo las habilidades propuestas por la
              Organización Mundial de la Salud (OMS): autoconocimiento, manejo
              de emociones y sentimientos, manejo de la tensión y el estrés,
              comunicación asertiva, empatía, relaciones interpersonales, manejo
              de conflictos, toma de decisiones, pensamiento crítico y
              pensamiento creativo.
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="desktop">
        <Row>
          <Col lg="7">
            <h2 className="c-ceo-bio__title mb-0rem t-black fw-xbold fs-30px lh-30 tt-uppercase">
              <h2>Del garaje al Perú</h2>
            </h2>
            <div className="c-ceo-bio__text t-black ff-secondary mb-2rem">
              <p>
                Luego de varios años dando clases particulares a niños y niñas
                de diferentes colegios privados de Lima, Macarena expande sus
                servicios a todo el Perú con el objetivo de disminuir brechas
                sociales, educativas y tecnológicas en el país. Maca no solo
                trabaja con estudiantes, sino también con docentes, directores,
                colegios, ONGs, UGELES y empresas, fortaleciendo de esta forma
                nuestra misión de girar la educación hacia una mucho más
                emocional y humana y formar así mejores personas y una mejor
                sociedad.
              </p>
            </div>
          </Col>
          <Col lg="5">
            <div className="c-card-article  c-card-article__left bg-white box-shadow w-80por h-100por">
              <figure className="c-card-article__figure mb-1rem">
                <img
                  className="w-100por h-100por of--cover"
                  src={images.WorldUsGarage1}
                  alt=""
                />
              </figure>
              <div className="c-card-article__content"></div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Us;
