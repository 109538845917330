import { Button, Skeleton } from "components/atoms";
import SeeMoreBtn from "components/atoms/seeMoreBtn";
import { Col, Container, ContainerFluid, Row } from "components/molecules/grid";
import { Link } from "react-router-dom";
import { ISpeaker } from "./props";
import "./styles.scss";

const Speaker = ({ loading, dataSpeaker }: ISpeaker) => {
  return (
    <ContainerFluid className="pos-relative bg-yellow py-1rem">
      <Row>
        <Col lg="12">
          <Container className="course-speaker">
            <Row className="d-flex ai-center jc-center">
              <Col
                lg="3"
                md="5"
                sm="5"
                xsm="12"
                className="course-speaker-avatar d-flex ai-center jc-center py-1rem"
              >
                {loading ? (
                  <Button
                    className=""
                    bgColor="transparent"
                    iconList="course"
                    iconName="speaker"
                    txtColor="black"
                    iconSize={200}
                    iconAnimate={true}
                  />
                ) : (
                  <Link to={`/speaker/${dataSpeaker.speakerId}`}>
                    <img src={dataSpeaker.cover} alt="" className="bg-white" />
                  </Link>
                )}
              </Col>
              <Col
                lg="6"
                md="7"
                sm="7"
                xsm="12"
                className="course-speaker-details pt-2rem pb-1rem"
              >
                {loading ? (
                  <Skeleton dotColor="black" bgColor="transparent" />
                ) : (
                  <>
                    <div className="teacher fs-30px fw-bold">
                      <Link
                        to={`/speaker/${dataSpeaker.speakerId}`}
                        className="td-none t-black"
                      >
                        {dataSpeaker.name}
                      </Link>
                    </div>
                    <SeeMoreBtn
                      text={
                        dataSpeaker.about != undefined ? dataSpeaker.about : ``
                      }
                      maxLength={100}
                    />
                  </>
                )}

                {/*<div className="calification py-1rem fs-16px fw-bold">
              <Icon
                list="items"
                name="heart-line"
                color="black"
                className="pr-0-4rem"
              />
              Calificación: {calification} puntos.
            </div>

            <div className="numberCourses pb-1rem fs-16px fw-bold">
              <Icon
                list="items"
                name="video-line"
                color="black"
                className="pr-0-4rem"
              />
              {numberCourses == 1
                ? ` Curso: ` + numberCourses + ` curso.`
                : ` Cursos: ` + numberCourses + ` cursos.`}
              </div>*/}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </ContainerFluid>
  );
};

export default Speaker;
