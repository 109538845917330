import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Auth, { IAccess } from "../types/auth";

const initialState: Auth = {
  authenticated: false,
  access: {
    convivenciaEscolar: false,
    habilidadesSocioemocionales: false,
  },
};

const authSlice = createSlice({
  name: `auth`,
  initialState,
  reducers: {
    setAuthenticated(state, action: PayloadAction<boolean>) {
      state.authenticated = action.payload;
    },
    setAccess(state, action: PayloadAction<IAccess>) {
      state.access = action.payload;
    },
  },
});

export const { setAuthenticated, setAccess } = authSlice.actions;

export default authSlice.reducer;
