import { Button, Pagination, Skeleton } from "components/atoms";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ITable } from "./props";
import "./styles.scss";

const Table = ({
  columns,
  dataEmpty,
  data,
  propiertyId,
  isAddRow,
  className,
  loading,
  pagination,
  handleAddRow,
  handleEditRow,
  handleDeleteRow,
}: ITable) => {
  const [addRow, setAddRow] = useState(false);
  const [editRow, setEditRow] = useState(false);
  const [selectItem, setSelectItem] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: `onChange`,
  });

  useEffect(() => {
    if (addRow || editRow) {
      const obj: any = {};
      columns.map((column) => {
        obj[column.accesor] = selectItem ? selectItem[column.accesor] : ``;
      });

      if (selectItem) {
        obj[propiertyId] = selectItem[propiertyId];
      }

      reset(obj);
    }
  }, [addRow, editRow]);

  const selectEdit = (data: any) => {
    setSelectItem(data);
    setEditRow(!editRow);
    setAddRow(false);
  };

  const onEdit = (data: any) => {
    handleEditRow(data, () => setEditRow(false));
  };

  const onSubmit = (data: any) => {
    handleAddRow(data, () => setAddRow(false));
  };

  return (
    <section className={`table-component ${className}`}>
      <div className="table-component_wraper">
        <table className="table-component_table">
          <thead className="table-component_table__head">
            <tr>
              {columns.map((column, index) => (
                <th key={index}>{column.header}</th>
              ))}

              <th>Acciones</th>
            </tr>
          </thead>

          {!loading && (
            <>
              <tbody className="table-component_table__body">
                {data.map((item, index) => (
                  <tr key={index}>
                    {columns.map((column, index) => (
                      <td
                        key={index}
                        className={`
                          ${
                      item[propiertyId] === selectItem?.[propiertyId] &&
                            editRow &&
                            errors[column.accesor]
                        ? `td_error`
                        : ``
                      }

                            ${
                      item[propiertyId] === selectItem?.[propiertyId] &&
                              editRow
                        ? `bg-skyBlue`
                        : ``
                      }
                        `}
                      >
                        {item[propiertyId] === selectItem?.[propiertyId] &&
                        editRow ? (
                            <input
                              type="text"
                              className="input"
                              placeholder={column.placeholder}
                              {...register(column.accesor, {
                                ...column.rules,
                              })}
                            />
                          ) : (
                            <>{item[column.accesor]}</>
                          )}
                      </td>
                    ))}

                    <td className="td_action jc-center g-05">
                      <Button
                        className="m-0 p-0"
                        bgColor="transparent"
                        txtColor="turquoise"
                        txtHover="gray"
                        onClick={() => selectEdit(item)}
                        iconName={
                          item[propiertyId] === selectItem?.[propiertyId] &&
                          editRow
                            ? `close`
                            : `pencil`
                        }
                        iconSize={
                          item[propiertyId] === selectItem?.[propiertyId] &&
                          editRow
                            ? 18
                            : 25
                        }
                      />

                      {item[propiertyId] === selectItem?.[propiertyId] &&
                      editRow ? (
                          <Button
                            className="m-0 p-0"
                            bgColor="transparent"
                            txtColor="turquoise"
                            txtHover="gray"
                            onClick={() => handleSubmit(onEdit)()}
                            iconName="save"
                            iconSize={25}
                          />
                        ) : (
                          <Button
                            className="m-0 p-0"
                            bgColor="transparent"
                            txtColor="turquoise"
                            txtHover="gray"
                            onClick={() => handleDeleteRow(item)}
                            iconName="trash"
                            iconSize={25}
                          />
                        )}
                    </td>
                  </tr>
                ))}

                {!data.length && !addRow && (
                  <tr>
                    <td colSpan={columns.length} className="td_action">
                      <p>{dataEmpty}</p>
                    </td>
                  </tr>
                )}

                {addRow && (
                  <tr>
                    {columns.map((column, index) => (
                      <td
                        key={index}
                        className={`
                          ${errors[column.accesor] && `td_error`}
                          bg-skyBlue
                        `}
                      >
                        <input
                          type="text"
                          className="input"
                          placeholder={column.placeholder}
                          {...register(column.accesor, {
                            ...column.rules,
                          })}
                        />
                      </td>
                    ))}

                    <td className="td_action">
                      <Button
                        className="m-0 p-0"
                        bgColor="transparent"
                        txtColor="turquoise"
                        txtHover="gray"
                        onClick={() => handleSubmit(onSubmit)()}
                        iconName="save"
                        iconSize={25}
                      />
                    </td>
                  </tr>
                )}
              </tbody>

              {isAddRow && (
                <tfoot className="table-component_table__foot">
                  <tr>
                    <td colSpan={columns.length} className="w-100por">
                      <Button
                        className="table-component_table__foot___add"
                        bgColor="white"
                        bColor={addRow ? `redLight` : `turquoise`}
                        txtColor={addRow ? `redLight` : `turquoise`}
                        bgHover="silverLight"
                        onClick={() => {
                          setSelectItem(null);
                          setAddRow(!addRow);
                          setEditRow(false);
                        }}
                        iconName={!addRow ? `plus` : ``}
                        text={addRow ? `Cancelar` : `Agregar`}
                      />
                    </td>
                  </tr>
                </tfoot>
              )}
            </>
          )}
        </table>
      </div>

      <Skeleton open={loading} bgColor="white" height="550px" />

      <Pagination
        open={!loading && Boolean(data.length)}
        currentPage={pagination.currentPage}
        totalPages={pagination.totalPages}
        onChangePage={(page: number) => {
          pagination.onChangePage(page);
          setSelectItem(null);
          setAddRow(false);
          setEditRow(false);
        }}
      />
    </section>
  );
};

Table.defaultProps = {
  isAddRow: true,
  dataEmpty: `No hay datos para mostrar`,
};

export default Table;
