import images from "assets/images";
import "./styles.scss";

import { useAppSelector, useWindowSize } from "hooks";
import { useMemo, useState } from "react";

import logo from "assets/images/logos/logo-amab.webp";
import { Button, Dropdown } from "components/atoms";
import links from "constants/links";
import { Link } from "react-router-dom";
import { Container } from "./../../../components/molecules/grid";

const Header = () => {
  const screen = useWindowSize();
  const [openMenuMab, setOpenMenuMab] = useState(false);
  const { authenticated } = useAppSelector((state) => state.auth);

  const toggleMenuMab = () => setOpenMenuMab(!openMenuMab);
  const isSmall = useMemo(() => screen.width <= 1150, [screen]);

  const signOut = () => {
    localStorage.removeItem(`token`);
    localStorage.removeItem(`names`);
    localStorage.removeItem(`lastNames`);
    window.location.href = `/`;
  };

  return (
    <>
      <header className="header">
        <Container>
          <nav className="d-flex jc-space-between">
            <div className="d-flex ai-center">
              <Link className="d-flex ai-center" to={links.landing}>
                <img src={logo} alt="logo" className="logo mr-0-5rem" />
              </Link>

              <Button
                text="Cursos"
                bgColor="transparent"
                txtHover="yellow"
                redirect={links.courses}
              />

              {/*  <Button
                text="Kumpai"
                bgColor="turquoise"
                txtHover="orange"
                redirect={links.kumpai}
                className="mr-1rem"
              /> */}
            </div>
            <div className="d-flex ai-center">
              {authenticated ? (
                <>
                  {isSmall && (
                    <>
                      {/*<Button
                        text="Mis cursos"
                        bgColor="transparent"
                        txtHover="yellow"
                        redirect={links.myProfile}
                      />*/}
                      <Button
                        id="menu-mab"
                        bgColor="transparent"
                        iconList="arrows"
                        iconName="down"
                        txtHover="yellow"
                        onClick={toggleMenuMab}
                        className="d-flex fd-row-reverse ai-center jc-center g-0-5rem"
                      >
                        <img
                          src={images.User}
                          alt=""
                          className="userPhoto-header"
                        />
                      </Button>
                      <Dropdown
                        htmlBy="menu-mab"
                        open={openMenuMab}
                        onClose={toggleMenuMab}
                        typeOption="link"
                        options={[
                          { label: `Mi Perfil`, redirect: links.myProfile },
                          { label: `Mis Cursos`, redirect: links.myCourses },
                          { label: `Cerrar Sesión`, onClick: () => signOut() },
                        ]}
                      />
                    </>
                  )}

                  {!isSmall && (
                    <>
                      {/*<Button
                        text="Mis cursos"
                        bgColor="transparent"
                        txtHover="yellow"
                        redirect={links.myCourses}
                      />*/}
                      <Button
                        id="menu-mab"
                        bgColor="transparent"
                        iconList="arrows"
                        iconName="down"
                        txtHover="yellow"
                        onClick={toggleMenuMab}
                        className="d-flex fd-row-reverse ai-center jc-center g-0-5rem"
                      >
                        <img
                          src={images.User}
                          alt=""
                          className="userPhoto-header"
                        />
                      </Button>
                      <Dropdown
                        htmlBy="menu-mab"
                        open={openMenuMab}
                        onClose={toggleMenuMab}
                        typeOption="link"
                        options={[
                          { label: `Mi Perfil`, redirect: links.myProfile },
                          { label: `Cerrar Sesión`, onClick: () => signOut() },
                        ]}
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  {!isSmall && (
                    <Button
                      text="Regístrate"
                      txtHover="yellow"
                      redirect={links.register}
                    />
                  )}

                  <Button
                    text="Iniciar sesión"
                    bgColor="transparent"
                    txtHover="yellow"
                    redirect={links.login}
                  />
                </>
              )}
            </div>
          </nav>
        </Container>
      </header>
    </>
  );
};

export default Header;
