import heart from "assets/images/vectors/heart.webp";
import { Col, Container, Row } from "components/molecules/grid";
import { IDescription } from "./props";
import "./styles.scss";

const Description = ({ about }: IDescription) => {
  const words = [
    { id: `1`, word: `lorem ipsum` },
    { id: `2`, word: `lorem ipsum` },
    { id: `3`, word: `lorem ipsum` },
    { id: `6`, word: `lorem ipsum` },
    { id: `7`, word: `lorem ipsum` },
    { id: `8`, word: `lorem ipsum` },
  ];
  return (
    <Container className="speaker">
      <Row className="py-1rem">
        <Col lg="9" md="12" sm="12" className="speaker-detail">
          <p className="fw-bold ml-1rem fs-21px fw-bold">Sobre mí</p>
          <p className="px-1rem speaker-detail-description fs-18px">{about}</p>
        </Col>
        {/*<Col lg="4" md="8" sm="12">
          <p className="fw-bold ml-2rem">Experiencia</p>
          <div className="speaker-skills_words px-1rem">
            {words?.map((item) => (
              <p key={item.id}>
                <span>
                  <Icon name="check-circle-line" />
                </span>
                {` `}
                {item.word}
                {` `}
              </p>
            ))}
          </div>
            </Col>*/}
        <Col lg="3" md="4" sm="12">
          <div className="speaker_logo-heart">
            <img src={heart} alt="heart" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Description;
