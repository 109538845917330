import SpinnerLoading from "../spinner";
import { ISkeleton } from "./props";
import "./styles.scss";

const Skeleton = ({
  open,
  height,
  bgColor,
  dotColor,
  className,
}: ISkeleton) => {
  if (!open) return null;

  return (
    <div
      className={`skeleton bg-${bgColor} ${className}`}
      style={{ height: height }}
    >
      <SpinnerLoading bgColor={dotColor} />
    </div>
  );
};

Skeleton.defaultProps = {
  open: true,
  bgColor: `silverMedium`,
  dotColor: `magenta`,
};

export default Skeleton;
