import { Toast } from "components/atoms";
import API from "constants/api";
import endpoints from "constants/endpoints";
import { IHandleRecoveryCredential } from "./props";

export const handleRecoveryCredential = async ({
  setLoading,
  values,
}: IHandleRecoveryCredential) => {
  try {
    setLoading(true);

    const response = await API.request({
      url: endpoints.loginRecoveryCretendial,
      method: `post`,
      data: values,
    });

    if (response?.data?.status !== `success`) {
      throw new Error();
    }

    Toast({
      type: `success`,
      message: `Por favor, revisar su correo ` + values.email,
    });

    window.location.href = `/acceso`;
  } catch (error: any) {
    Toast({
      type: `error`,
      message: `Error al recuperar credencial.` + error?.message,
    });
  } finally {
    setLoading(false);
  }
};
