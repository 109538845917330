import { Button, Input } from "components/atoms";
import errorsForm from "constants/errorsForm";
import { useLayoutEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { injectGoogleJs } from "tools/googleAuth";
import { Step } from "../../../../components/molecules";
import "../../styles.scss";
import { FStep1, IStep1 } from "./props";
import { rules } from "./rules";
import { handleGoogleSignIn, handleStep1 } from "./services";
import "./styles.scss";

const Step1 = ({ setStep }: IStep1) => {
  const [loading, setLoading] = useState(false);
  const [isHiddenEye, setIsHiddenEye] = useState(false);
  const [isHiddenEyeConfirm, setIsHiddenEyeConfirm] = useState(false);

  const [steps, setSteps] = useState([
    {
      number: 1,
      currentActive: true,
    },
    {
      number: 2,
      currentActive: false,
    },
    {
      number: 3,
      currentActive: false,
    },
  ]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FStep1>({
    mode: `onChange`,
    defaultValues: {
      names: atob(localStorage.getItem(`names`) || ``),
      lastNames: atob(localStorage.getItem(`lastNames`) || ``),
      email: atob(localStorage.getItem(`email`) || ``),
      remember: !!localStorage.getItem(`email`),
    },
  });

  const handleContinue = (values: FStep1) => {
    handleStep1({ setLoading, values, setStep });
  };

  const onHiddenEye = () => {
    setIsHiddenEye(!isHiddenEye);
  };

  const onHiddenEyeConfirm = () => {
    setIsHiddenEyeConfirm(!isHiddenEyeConfirm);
  };

  useLayoutEffect(() => {
    injectGoogleJs();
    //@ts-ignore
    window.handleGoogleSignIn = handleGoogleSignIn;
  }, []);

  return (
    <section className="register bgImage1">
      <div className="py-1-5rem">
        <Step steps={steps} />
      </div>
      <div className="register-content">
        <div className="card w-450px mx-1rem mb-2rem pb-3rem">
          <form onSubmit={handleSubmit(handleContinue)}>
            <div className="head bg-yellow d-flex ac-ai-jc-center">
              <span className="fs-20px fw-400 ff-secondary">
                REGÍSTRATE Y EMPIEZA A APRENDER
              </span>
            </div>

            <div className="body">
              <div className="pt-2-5rem px-3rem">
                <Controller
                  control={control}
                  name="names"
                  rules={rules.names}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Input
                      type="text"
                      placeholder="Nombres"
                      value={value || ``}
                      onChange={onChange}
                      onBlur={onBlur}
                      iconList="access"
                      iconNameLeft="user"
                      iconLeft={true}
                      messageError={errorsForm[errors?.names?.type || ``]}
                    />
                  )}
                />
              </div>

              <div className="pb-0-5rem px-3rem">
                <Controller
                  control={control}
                  name="lastNames"
                  rules={rules.lastNames}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Input
                      type="text"
                      placeholder="Apellidos"
                      value={value || ``}
                      onChange={onChange}
                      onBlur={onBlur}
                      iconList="access"
                      iconNameLeft="user"
                      iconLeft={true}
                      messageError={errorsForm[errors?.lastNames?.type || ``]}
                    />
                  )}
                />
              </div>

              <div className="pb-0-5rem px-3rem">
                <Controller
                  control={control}
                  name="email"
                  rules={rules.email}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Input
                      type="email"
                      placeholder="Correo electrónico"
                      value={value || ``}
                      onChange={onChange}
                      onBlur={onBlur}
                      iconList="access"
                      iconNameLeft="email"
                      iconLeft={true}
                      messageError={errorsForm[errors?.email?.type || ``]}
                    />
                  )}
                />
              </div>

              <div className="pb-0-5rem px-3rem">
                <Controller
                  control={control}
                  name="password"
                  rules={rules.password}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Input
                      type={isHiddenEye ? `text` : `password`}
                      placeholder="Contraseña"
                      value={value || ``}
                      onChange={onChange}
                      onBlur={onBlur}
                      iconList="access"
                      iconNameLeft="password"
                      iconLeft={true}
                      iconNameRight={isHiddenEye ? `eye` : `eyeActive`}
                      iconRight={true}
                      onClickRight={onHiddenEye}
                      messageError={errorsForm[errors?.password?.type || ``]}
                    />
                  )}
                />
              </div>

              <div className="pb-1-5rem px-3rem">
                <Controller
                  control={control}
                  name="passwordRepeat"
                  rules={rules.passwordRepeat}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Input
                      type={isHiddenEyeConfirm ? `text` : `password`}
                      placeholder="Confirmar contraseña"
                      value={value || ``}
                      onChange={onChange}
                      onBlur={onBlur}
                      iconList="access"
                      iconNameLeft="password"
                      iconLeft={true}
                      iconNameRight={isHiddenEyeConfirm ? `eye` : `eyeActive`}
                      iconRight={true}
                      onClickRight={onHiddenEyeConfirm}
                      messageError={
                        errorsForm[errors?.passwordRepeat?.type || ``]
                      }
                    />
                  )}
                />
              </div>

              <Button
                text="Regístrate"
                type="submit"
                loading={loading}
                className="bg-yellowLigth t-black hov-bg-yellow hov-t-red br-25px ff-secondary fs-18px fw-900 w-75por"
              />
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Step1;
