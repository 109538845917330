import images from "assets/images";
import { Icon } from "components/atoms";
import { Col, Container, Row } from "components/molecules/grid";
import { ICard } from "./props";
import "./styles.scss";

const Card = ({
  _id,
  id,
  name,
  description,
  color,
  thumbnail,
  teacher,
  price,
  likes,
  link,
  grade,
  duration,
  isLikes,
}: ICard) => {
  return (
    <div className={`cardCourse br--large bg-${color}`}>
      <figure className={`figure pos-relative br-top--large w-100por`}>
        {/* {price == 0 ? (
          <div className="free-tag pos-absolute d-flex ac-ai-jc-center bg-yellow fs-16px ff-secondary fw-bold">
            <Icon list="course" name="heart" color="red" width={18} />
            <span className="pl-0-5rem">GRATIS</span>
          </div>
        ) : null} */}

        {grade != `` ? (
          <div className="grade-tag pos-absolute t-black ff-secondary d-flex ac-ai-jc-center bg-yellow fw-900 fs-16px">
            <span>{grade}</span>
          </div>
        ) : null}

        <img
          src={thumbnail != null ? thumbnail : images.Course}
          alt=""
          className="of--cover br-top--large w-100por h-100por"
        />
      </figure>

      <div className="content d-flex fd-column px-1rem ">
        <div className="titles">
          <p className="name fw-900 fs-18px">{name}</p>
          <p className="teacher fs-16px">Con {teacher}</p>
        </div>
        <p className="description fs-16px">{description}</p>
      </div>
      {isLikes ? (
        <Container className="px-1rem pb-1-5rem">
          <Row>
            {likes > 0 || price > 0 ? (
              <Col lg="12">
                {likes > 0 ? (
                  <span className="likes fs-20px fw-bold d-flex ai-center ">
                    <Icon list="course" name="heart" color="black" width={16} />
                    {likes}
                  </span>
                ) : null}
              </Col>
            ) : (
              <Col lg="12">
                <span className="likes fs-20px fw-bold d-flex ai-center ">
                  <Icon list="course" name="heart" color="black" width={16} />1
                </span>
              </Col>
            )}
          </Row>
        </Container>
      ) : null}
      <a
        href={`/curso/${id}`}
        className="footer tt-uppercase ff-secondary fs-21px py-1rem ta-center bg-black hov-t-yellow t-white w-100por"
      >
        ¡comenzar curso!
      </a>
    </div>
  );
};

export default Card;
