const regexs = {
  dni: /^[0-9]{8,12}$/,
  number: /^[0-9]{1,2}$/,
  text: /^[a-zA-ZÀ-ÿ\s]{1,25}$/,
  email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  formatImage: /image\/(png|jpg|jpeg|webp)/,
  question:
    /^[a-zA-Z0-9À-ÿ\s\.,;:¡!-_+=]{0,}[¿][a-zA-Z0-9À-ÿ\s\.,;:¡!-_+=]{4,}[?][a-zA-Z0-9À-ÿ\s\.,;:¡!-_+=]{0,}$/,
  description: /^[a-zA-ZÀ-ÿ0-9_.+-\s]{6,500}$/,
};

export default regexs;
