import { Button, Input, Skeleton } from "components/atoms";
import errorsForm from "constants/errorsForm";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { saveInformation } from "views/myProfile/services";
import { FInformation, IInformation } from "./props";
import { rules } from "./rules";
import "./styles.scss";

const Information = ({
  dataInformation,
  setDataInformation,
  role,
}: IInformation) => {
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FInformation>({
    mode: `onChange`,
    defaultValues: {
      names: ``,
      lastNames: ``,
    },
  });

  const onEdit = () => {
    setIsEdit(!isEdit);
  };

  const onSubmit = (values: FInformation) =>
    saveInformation({ setLoading, values, setIsEdit, setDataInformation });

  return (
    <div className="profile-information bg-yellow d-flex fd-column jc-center">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex ai-center">
          {!isEdit ? (
            <span className="names fw-bold tav-middle  t-black">
              {dataInformation.names} {dataInformation.lastNames}
            </span>
          ) : (
            <div className="d-flex ai-center g-1rem h-70px ">
              <Controller
                control={control}
                name="names"
                rules={rules.names}
                render={({ field: { value, onChange, onBlur } }) => (
                  <Input
                    type="text"
                    placeholder="Nombres"
                    value={value || ``}
                    onChange={onChange}
                    onBlur={onBlur}
                    iconList="access"
                    iconNameLeft="user"
                    iconLeft={true}
                    iconRight={true}
                    messageError={errorsForm[errors?.names?.type || ``]}
                  />
                )}
              />
              <Controller
                control={control}
                name="lastNames"
                rules={rules.lastNames}
                render={({ field: { value, onChange, onBlur } }) => (
                  <Input
                    type="text"
                    placeholder="Apellidos"
                    value={value || ``}
                    onChange={onChange}
                    onBlur={onBlur}
                    iconList="access"
                    iconNameLeft="user"
                    iconLeft={true}
                    iconRight={true}
                    messageError={errorsForm[errors?.lastNames?.type || ``]}
                  />
                )}
              />
            </div>
          )}

          {!isEdit ? (
            <Button
              className="m-0rem ml-1rem p-0rem ff-primary fs-12px fw-bold br-30px w-35px h-35px"
              bgColor="cyan"
              txtColor="white"
              txtHover="black"
              onClick={() => onEdit()}
              iconName="pencil"
              iconList="buttons"
              iconSize={20}
            />
          ) : (
            <div className="d-flex ai-center ml-1rem g-0-5rem">
              <Button
                className="m-0rem p-0rem ff-primary fs-12px fw-bold br-30px w-35px h-35px"
                bgColor="blueLight"
                bgHover="blue"
                txtColor="white"
                txtHover="white"
                type="submit"
                iconName="save"
                iconList="buttons"
                iconSize={19}
              />

              <Button
                className="m-0rem p-0rem ff-primary fs-12px fw-bold br-30px w-35px h-35px"
                bgColor="redLight"
                bgHover="red"
                txtColor="white"
                txtHover="white"
                onClick={() => onEdit()}
                iconName="close"
                iconList="buttons"
                iconSize={12}
              />
            </div>
          )}
        </div>
        <div>
          {loading ? (
            <Skeleton height="20px" dotColor="gray" />
          ) : (
            <p className="m-0rem p-0rem fs-18px">{role}</p>
          )}
        </div>
      </form>
    </div>
  );
};

export default Information;
