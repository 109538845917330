import { Button, Icon } from "components/atoms";
import { Col, Container, Row } from "components/molecules/grid";
import { IReviewUnsatisfactory } from "./props";
const ReviewUnsatisfactory = ({ setStepReview }: IReviewUnsatisfactory) => {
  const storageReviewFeedBack: any = localStorage.getItem(`reviewFeedBack`);
  const reviewFeedBack = JSON.parse(storageReviewFeedBack) || [];

  const handleTryAgain = () => {
    setStepReview(`question`);
    localStorage.removeItem(`reviewFeedBack`);
    localStorage.setItem(`reviewCurrentIdQuestion`, `1`);
    const storageQuestionsInit: any = localStorage.getItem(
      `reviewArrQuestionInit`
    );
    const questionsInit = JSON.parse(storageQuestionsInit);
    localStorage.setItem(`reviewArrQuestion`, JSON.stringify(questionsInit));
  };

  return (
    <Container className="p-3rem">
      <Row>
        <Col lg="12" className="ta-center">
          <h5>TUS RESPUESTAS</h5>
        </Col>
      </Row>
      <Row>
        <Col lg="12" className="d-flex fd-column ">
          {reviewFeedBack.map((item: any, index: any) => (
            <Row key={index} className="py-0-5rem hov-bg-silverLight">
              <Col lg="12">
                <span className="fs-16px">
                  <b>{item.question}</b>
                </span>
              </Col>
              <Col lg="10" col="10">
                <p className="fs-14px ml-2rem">{item.option}</p>
              </Col>
              <Col lg="2" col="2" className="d-flex ai-center jc-center">
                {item.result === true ? (
                  <Icon list="items" name="correct" />
                ) : (
                  <Icon list="items" name="incorrect" />
                )}
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
      <Row className="pt-2rem">
        <Col lg="7">
          <span className="fs-10px fw-bold">
            * Recuerda que debes tener más de 3 respuestas correctas para
            aprobar el repaso.
          </span>
        </Col>
        <Col lg="5" className="d-flex ai-center jc-right">
          <Button
            txtColor="black"
            bgColor="yellow"
            bgHover="turquoise"
            text="Intentar de Nuevo"
            className="ff-primary fw-bold px-2rem br-20px ml-1rem"
            onClick={() => handleTryAgain()}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ReviewUnsatisfactory;
