import API from "constants/api";
import endpoints from "constants/endpoints";

export const getResultPersonality = async (
  setLoading: Function,
  setIsTestPersonality: Function
) => {
  try {
    setLoading(true);

    const response = await API.request({
      method: `get`,
      url: endpoints.getTestPersonality,
      hasToken: true,
    });

    if (response?.data?.status !== `success`) {
      throw new Error();
    }

    setIsTestPersonality(true);
    setLoading(false);
  } catch {
    setIsTestPersonality(false);
  } finally {
    setLoading(false);
  }
};

export const getResultMotivation = async (
  setLoading: Function,
  setIsTestMotivation: Function
) => {
  try {
    setLoading(true);

    const response = await API.request({
      method: `get`,
      url: endpoints.getTestMotivation,
      hasToken: true,
    });

    if (response?.data?.status !== `success`) {
      throw new Error();
    }

    setIsTestMotivation(true);
    setLoading(false);
  } catch {
    setIsTestMotivation(false);
  } finally {
    setLoading(false);
  }
};
