import { Spinner } from "components/atoms";
import links from "constants/links";
import { Suspense } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes as Switch
} from "react-router-dom";
import { Landing, MyCourses, MyProfile, Register, Us } from "views";
import {
  GeneratePassword,
  Login,
  RecoveryCredential,
  VerifiedEmail
} from "views/access";
import { Course, Courses, Speaker, Topic } from "views/courses";

import { Motivation, Personality } from "views/tests";

const Routes = () => {
  return (
    <Router>
      <Suspense fallback={<Spinner className="w-100vw h-100vh" />}>
        <Switch>
          <Route path={links.landing} element={<Landing />} />
          <Route path={links.us} element={<Us />} />
          <Route path={links.login} element={<Login />} />
          <Route
            path={links.recoveryCredential}
            element={<RecoveryCredential />}
          />
          <Route
            path={`${links.generatePassword}`}
            element={<GeneratePassword />}
          />
          <Route path={links.register} element={<Register />} />
          <Route path={links.verefiedEmail} element={<VerifiedEmail />} />

          <Route path="*" element={<Navigate to={links.landing} replace />} />

          <Route path={links.courses} element={<Courses />} />
          <Route path={`${links.course}/:idCourse`} element={<Course />} />
          <Route
            path={`${links.course}/:idCourse/topico/:idTopic`}
            element={<Topic />}
          />
          {/*<Route path={`${links.topic}/:idTopic`} element={<Topic />} />*/}
          <Route path={`${links.speaker}/:speakerId`} element={<Speaker />} />
          <Route path={links.speaker} element={<Speaker />} />

          <Route path={links.myProfile} element={<MyProfile />} />
          <Route path={links.myCourses} element={<MyCourses />} />
          <Route path={links.testMotivation} element={<Motivation />} />
          <Route path={links.testPersonality} element={<Personality />} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
