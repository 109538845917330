import { Toast } from "components/atoms";
import API from "constants/api";
import endpoints from "constants/endpoints";
import { IGetCourses } from "./props";

export const getCourses = async ({
  setLoading,
  setDataCourses,
}: IGetCourses) => {
  try {
    setLoading(true);
    const response = await API.request({
      url: endpoints.getCourses,
      params: {
        limit: 8,
        main: true,
      },
      hasToken: false,
    });
    if (response?.status !== 200) {
      throw new Error(response?.data?.message);
    }
    setDataCourses(response?.data?.data);
    setLoading(false);
  } catch (error: any) {
    Toast({
      message: error?.message || `No se han encontrado cursos.`,
      type: `error`,
    });
  } finally {
    setLoading(false);
  }
};
