import { Toast } from "components/atoms";
import API from "constants/api";
import endpoints from "constants/endpoints";
import {
  IGetCourses,
  IGetProfile,
  ISaveAbout,
  ISaveInformation
} from "./props";

export const getProfile = async ({
  setLoading,
  setData,
  setDataInformation,
  setDataAbout,
}: IGetProfile) => {
  try {
    setLoading(true);

    const response = await API.request({
      method: `get`,
      url: endpoints.getProfile,
      hasToken: true,
    });

    if (response?.data?.status !== `success`) {
      throw new Error();
    }
    setData(response?.data?.data);
    setDataInformation({
      names: response?.data?.data.names,
      lastNames: response?.data?.data.lastNames,
    });
    setDataAbout({
      description: response?.data?.data.description,
    });
  } catch (error: any) {
    setData([]);
    setDataInformation({});
    setDataAbout({});
  } finally {
    setLoading(false);
  }
};

export const getCourses = async ({
  setLoading,
  setDataCourses,
  setDataProgress,
}: IGetCourses) => {
  try {
    setLoading(true);

    const response = await API.request({
      method: `get`,
      url: endpoints.getProfileCourses,
      hasToken: true,
    });

    if (response?.data?.status !== `success`) {
      throw new Error();
    }
    setDataCourses(response?.data?.data);
    setDataProgress({
      numberOfCourses: response?.data?.data.length,
      numberCertificates: response?.data?.data.filter(
        (item: any) => item.certificate === true
      ).length,
      numberOfTopics: response?.data?.data.filter(
        (item: any) => item.numberOfTopics == item.numberOfTopicsApproved
      ).length,
    });
  } catch (error: any) {
    setDataCourses([]);
    setDataProgress({});
  } finally {
    setLoading(false);
  }
};

export const saveInformation = async ({
  setLoading,
  values,
  setIsEdit,
  setDataInformation,
}: ISaveInformation) => {
  try {
    setLoading(true);

    const response = await API.request({
      method: `patch`,
      url: endpoints.setProfileInformation,
      hasToken: true,
      data: values,
    });

    if (response?.data?.status !== `success`) {
      throw new Error();
    }

    Toast({
      type: `success`,
      message: `Información registrada correctamente.`,
    });

    setDataInformation(response?.data?.data);
    setIsEdit(false);
  } catch {
    setDataInformation({});
    setIsEdit(true);
  } finally {
    setLoading(false);
  }
};

export const saveAbout = async ({
  setLoading,
  values,
  setIsEdit,
  setDataAbout,
}: ISaveAbout) => {
  try {
    setLoading(true);

    const response = await API.request({
      method: `patch`,
      url: endpoints.setProfileAbout,
      hasToken: true,
      data: values,
    });

    if (response?.data?.status !== `success`) {
      throw new Error();
    }

    Toast({
      type: `success`,
      message: `Acerca de mí registrado correctamente.`,
    });

    setDataAbout(response?.data?.data);
    setIsEdit(false);
  } catch {
    setDataAbout({});
    Toast({
      type: `error`,
      message: `Debe iniciar sesión.`,
    });
    window.location.href = `/acceso`;
  } finally {
    setLoading(false);
  }
};

export const saveSkills = async ({
  setLoading,
  values,
  setIsEdit,
  setDataAbout,
}: ISaveAbout) => {
  try {
    setLoading(true);

    const response = await API.request({
      method: `patch`,
      url: endpoints.setProfileAbout,
      hasToken: true,
      data: values,
    });

    if (response?.data?.status !== `success`) {
      throw new Error();
    }

    Toast({
      type: `success`,
      message: `Habilidades registrados correctamente.`,
    });

    setDataAbout(response?.data?.data);
    setIsEdit(false);
  } catch {
    setDataAbout({});
    Toast({
      type: `error`,
      message: `Debe iniciar sesión.`,
    });
    window.location.href = `/acceso`;
  } finally {
    setLoading(false);
  }
};
