import regexs from "constants/regexs";

export const rules = {
  names: {
    required: true,
    pattern: regexs.text,
  },
  lastNames: {
    required: true,
    pattern: regexs.text,
  },
};
