import { Toast } from "components/atoms";
import API from "constants/api";
import endpoints from "constants/endpoints";
import baremoJson from "./baremo.json";
import { IHandleStepQuestion, IHandleStepResult } from "./props";
import resultJson from "./result.json";

export const getResult = async (
  setLoading: Function,
  setStepTest: Function
) => {
  try {
    setLoading(true);

    const response = await API.request({
      method: `get`,
      url: endpoints.getTestPersonality,
      hasToken: true,
    });

    if (response?.data?.status !== `success`) {
      throw new Error();
    }

    localStorage.setItem(`testPersonalityStep`, `result`);
    localStorage.setItem(
      `testPersonalityResult`,
      JSON.stringify(response.data.data)
    );

    Toast({
      type: `success`,
      message: `Test realizado correctamente.`,
    });

    setStepTest(`result`);
    setLoading(false);
  } catch {
    if (localStorage.getItem(`testPersonalityStep`) != `question`) {
      localStorage.setItem(`testPersonalityStep`, `init`);
      setStepTest(`init`);
    } else {
      localStorage.setItem(`testPersonalityStep`, `question`);
      setStepTest(`question`);
    }
  } finally {
    setLoading(false);
  }
};

export const handleStepQuestion = ({
  payload,
  setLoading,
  setStepTest,
}: IHandleStepQuestion) => {
  try {
    setLoading(true);
    localStorage.setItem(`testPersonalityStep`, `question`);
    localStorage.setItem(`testPersonalityName`, btoa(payload.name));
    localStorage.setItem(`testPersonalityAge`, btoa(payload.age));
    setStepTest(`question`);
    setLoading(false);
  } catch {
    localStorage.setItem(`testPersonalityStep`, `init`);
    setStepTest(`init`);
  } finally {
    setLoading(false);
  }
};

export const handleStepResult = async ({
  setLoading,
  setStepTest,
}: IHandleStepResult) => {
  try {
    setLoading(true);
    let percentile = 0;
    let percentiln = 0;
    let test = ``;
    const age = atob(localStorage.getItem(`testPersonalityAge`) || ``);
    const name = atob(localStorage.getItem(`testPersonalityName`) || ``);
    const storageQuestions: any = localStorage.getItem(
      `testPersonalityArrQuestion`
    );
    const questions = JSON.parse(storageQuestions);

    // OBTENER LOS VALORES DE LAS DIMENSIONES
    const filter: any = {
      i: questions.filter((q: any) => q.dimention == `I`),
      e: questions.filter((q: any) => q.dimention == `E`),
      n: questions.filter((q: any) => q.dimention == `N`),
      l: questions.filter((q: any) => q.dimention == `L`),
      ee: questions.filter((q: any) => q.dimention == `EE`),
    };

    // SUMAR LOS VALORES DE LAS DIMENSIONES
    const total: any = {
      i: filter.i
        .map((q: any) => q.answer)
        .reduce((q1: any, q2: any) => q1 + q2, 0),
      e: filter.e
        .map((q: any) => q.answer)
        .reduce((q1: any, q2: any) => q1 + q2, 0),
      n: filter.n
        .map((q: any) => q.answer)
        .reduce((q1: any, q2: any) => q1 + q2, 0),
      l: filter.l
        .map((q: any) => q.answer)
        .reduce((q1: any, q2: any) => q1 + q2, 0),
      ee: filter.ee
        .map((q: any) => q.answer)
        .reduce((q1: any, q2: any) => q1 + q2, 0),
    };

    // OBTENER LA TABLA DE BAREMO DE UNA EDAD ESPECÍFICA
    const baremo = baremoJson.filter((q: any) => q.age == age);

    // COMPARAR LOS VALORES DE LAS DIMENSIONAES EN LA TABLA BAREMO DE UNA EDAD ESPECIFICA
    if (baremo.length != 0) {
      // OBTENER EL PERCENTIL DE LA DIMESION E
      const rpercentile = baremo[0].baremo.filter((q) => q.e == total.e);
      if (rpercentile.length != 0) {
        percentile = rpercentile[0].percentil;
      } else {
        percentile = 0;
      }

      // OBTENER EL PERCENTIL DE LA DISMENSION N
      const rpercentilpn = baremo[0].baremo.filter((q) => q.n == total.n);
      if (rpercentilpn.length != 0) {
        percentiln = rpercentilpn[0].percentil;
      } else {
        percentiln = 0;
      }
    }

    // LÓGICA DE RESULTADO
    if (percentile >= 50 && percentiln < 50) {
      test = `E-EE`;
    } else if (percentile < 50 && percentiln >= 50) {
      test = `I-IE`;
    } else if (percentile >= 50 && percentiln >= 50) {
      test = `E-IE`;
    } else if (percentile < 50 && percentiln < 50) {
      test = `I-EE`;
    }

    // OBTENER EL RESULTADO EN BASE A LA LÓGICA
    const resultTest: any = resultJson.filter((q) => q.test == test);

    //ESTRUCTURA QUE SE INSERTARÁ EN LA BASE DE DATOS
    const data = {
      i: total.i,
      e: total.e,
      n: total.n,
      l: total.l,
      ee: total.ee,
      percentile: percentile,
      percentiln: percentiln,
      test: test,
      title: resultTest[0].title,
      abstract: resultTest[0].abstract,
      name: name,
      age: age + ` AÑOS`,
    };

    localStorage.setItem(`testPersonalityResult`, JSON.stringify(data));

    const response = await API.request({
      method: `post`,
      url: endpoints.setTestPersonality,
      hasToken: true,
      data: data,
    });

    if (response?.data?.status !== `success`) {
      throw new Error();
    }

    localStorage.removeItem(`testPersonalityName`);
    localStorage.removeItem(`testPersonalityAge`);
    localStorage.removeItem(`testPersonalityCurrentIdQuestion`);
    localStorage.removeItem(`testPersonalityArrQuestion`);
    localStorage.removeItem(`testPersonalityStep`);

    Toast({
      type: `success`,
      message: `Test realizado correctamente.`,
    });

    setStepTest(`result`);
    setLoading(false);
  } catch {
    localStorage.setItem(`testPersonalityStep`, `question`);
    setStepTest(`question`);
  } finally {
    setLoading(false);
  }
};
