import { Toast } from "components/atoms";
import endpoints from "constants/endpoints";
import { http } from "requests/http";

import {
  IGetTopic,
  IGetTopicLikes,
  IGetTopicProgress,
  IGetTopicsContent,
  ISetTopicLikes
} from "./props";

export const getTopic = async ({
  setLoading,
  authenticated,
  idCourse,
  idTopic,
  setData,
  setStepReview,
  setIsCertificate,
  setCertificate
}: IGetTopic) => {
  try {
    setLoading(true);

    const {data, status, message, errors} =  await http({
      method: `get`,
      url: endpoints.getTopic,
      params: {
        idCourse: idCourse,
        idTopic: idTopic,
      },
      hasToken: authenticated,
    })

    /*const response = await API.request({
      method: `get`,
      url: endpoints.getTopic,
      params: {
        idCourse: idCourse,
        idTopic: idTopic,
      },
      hasToken: authenticated,
    });*/

    if (status !== 200) {
      throw new Error(errors);
    }

    setData(data);
  
    if (authenticated) {

      //CURSO APROBADO
      setIsCertificate(data.progress.certificate);    
      if(data.progress.certificate)
      {
        //CERTIFICADO
        const nameCourse = atob(localStorage.getItem(`nameCourse`) || ``)
        const fullNameStudent =  
          atob(localStorage.getItem(`names`) || ``) +
            ` ` +
            atob(localStorage.getItem(`lastNames`) || ``)
        ;
        const dateApprovedCourse = data.progress.dateCertificate
        const detailCertificate = data.duration+' horas de teoría y práctica.'
        const codeCertificate = data.progress.codeCertification
      
        let dataCertificate: any = {data:[
          [nameCourse],
          [fullNameStudent],
          [dateApprovedCourse],
          [detailCertificate],
          [codeCertificate]
        ]
        }

        renderCertificate({dataCertificate, setCertificate})    
      }

      //TOPICO APROBADO
      const topicApproved = data.progress.topicsApproved.filter(
        (item: any) => item == idTopic
      );     
      
      if (topicApproved.length == 0) {
        setStepReview(`question`);

        //PARA EL REPASO
        const arrQuestions = data.questions.map(
          (item: any, index: any) => ({
            id: index + 1,
            title: item.title,
            options: item.options,
            answer: 0,
            right: item.right,
          })
        );
        localStorage.setItem(`reviewCurrentIdQuestion`, `1`);
        localStorage.setItem(
          `reviewArrQuestionInit`,
          JSON.stringify(arrQuestions)
        );
        localStorage.setItem(`reviewArrQuestion`, JSON.stringify(arrQuestions));
      } else {
        setStepReview(`reviewSatisfactory`);
      }      
    }

    setLoading(false);
  } catch (error: any) {
    Toast({
      message: error?.message || `No se ha encontrado el curso`,
      type: `error`,
    });
    setData({});
    setLoading(false);
  } 
};

export const getTopicsContent = async (
  {
    setLoading,
    idCourse,
    idTopic,
    setDataContent,
    setTopicContent,
    setCurrentTopicIndex,
    setNameCourse,
  }: IGetTopicsContent,
  
) => {
  setLoading(true);
  try {

    const {data, status, message, errors} =  await http({
      url: endpoints.getTopicsContent,
      params: {
        idCourse: idCourse,
        idTopic: idTopic,
      },
      hasToken: false,
    })

    /*const response = await API.request({
      url: endpoints.getTopicsContent,
      params: {
        idCourse: idCourse,
        idTopic: idTopic,
      },
      hasToken: false,
    });*/

    if (status !== 200) {
      throw new Error(errors);
    }

    setDataContent(data);

    //CREAR UN ARRAY DE PURO TOPICS
    const arrTopics: any = [];
    data.forEach((element: any) =>
      element.topicContent.forEach((topic: any) =>
        arrTopics.push(topic.idTopic)
      )
    );
    setTopicContent(arrTopics);
    setNameCourse(data[0].titleCourse);
    //OBTENER EL INDEX DEL ACTUAL TOPICO
    setCurrentTopicIndex(arrTopics.indexOf(Number(idTopic)));

    setLoading(false);
  } catch (error: any) {
    Toast({
      message: error?.message || `No se ha encontrado el curso`,
      type: `error`,
    });
    setDataContent([]);
    setLoading(false);
  } 
};

export const getTopicProgress = async ({
  setLoading,
  idCourse,
  idTopic,
  setDataReviewSatisfactory,
}: IGetTopicProgress) => {
  try {
    setLoading(true);

    const {data, status, message, errors} =  await http({
      method: `get`,
      url: endpoints.getTopicReview,
      params: {
        idCourse: idCourse,
        idTopic: idTopic,
      },
      hasToken: true,
    })

    /*const response = await API.request({
      method: `get`,
      url: endpoints.getTopicReview,
      params: {
        idCourse: idCourse,
        idTopic: idTopic,
      },
      hasToken: true,
    });*/

    if (status !== 200) {
      setDataReviewSatisfactory({ correct: 0, incorrect: 0 });
      throw new Error(errors);
    }
    setDataReviewSatisfactory(data);
    setLoading(false);
  } catch (error: any) {
    setLoading(false);
  } 
};

export const getTopicLikes = async (
  { idTopic, setDataLikes }: IGetTopicLikes,
  setLoading: (loading: boolean) => void
) => {
  try {
    setLoading(true);

    const {data, status, message, errors} =  await http({
      url: endpoints.getTopicLikes,
      params: {
        idTopic: idTopic,
      },
      hasToken: false,
    })


    /*const response = await API.request({
      url: endpoints.getTopicLikes,
      params: {
        idTopic: idTopic,
      },
      hasToken: false,
    });*/

    if (status !== 200) {
      throw new Error(errors);
    }

    setDataLikes(data);
    setLoading(false);
  } catch (error: any) {
    Toast({
      message: error?.message || `No se ha encontrado el curso`,
      type: `error`,
    });
    setDataLikes([]);
    setLoading(false);
  } 
};

export const setTopicLikes = async ({
  setLoading,
  idTopic,
  userId,
  setDataLikes,
}: ISetTopicLikes) => {
  try {
    setLoading(true);

    const {data, status, message, errors} =  await http({
      url: endpoints.setTopicLikes,
      params: {
        idTopic: idTopic,
        idUser: userId,
      },
      hasToken: true,
    })


    /*const response = await API.request({
      url: endpoints.setTopicLikes,
      params: {
        idTopic: idTopic,
        idUser: userId,
      },
      hasToken: true,
    });*/

    if (status !== 200) {
      throw new Error(errors);
    }
    setDataLikes(data);
    setLoading(false);
  } catch (error: any) {
    Toast({
      message: error?.message || `No se ha encontrado el curso`,
      type: `error`,
    });
    setDataLikes([]);
    setLoading(false);
  } 
};

export const renderCertificate = async ({ dataCertificate, setCertificate }: any) => {
  try {    

    const {fileStatus, fileData, fileErrors} =  await http({
      url: endpoints.generateCertificate,
      method: `post`,
      data: dataCertificate,
      hasToken: true,
    })

    /*const response = await API.request({
      url: endpoints.generateCertificate,
      method: `post`,
      data: dataCertificate,
      hasToken: true,
    });*/

    if (fileStatus !== 200) {
      throw new Error(fileErrors);
    }

    setCertificate(fileData);
  } catch (error: any) {
    Toast({
      message: error?.message || `No se ha encontrado el curso`,
      type: `error`,
    });
  } 
};
