import { Toast } from "components/atoms";
import API from "constants/api";
import endpoints from "constants/endpoints";
import { IGetCourse } from "./props";

export const getCourse = async ({
  setLoading,
  idCourse,
  setDataTrailer,
  setDataSpeaker,
  setDataFirstUnit,
  setDataUnitsContent,
}: IGetCourse) => {
  try {
    setLoading(true);
    const response = await API.request({
      method: `get`,
      url: endpoints.getCourse,
      params: {
        idCourse: idCourse,
      },
      hasToken: false,
    });
    if (response?.status !== 200) {
      throw new Error(response?.data?.message);
    }
    setDataTrailer({
      idCourse: response.data?.data.idCourse,
      titleCourse: response.data?.data.titleCourse,
      description: response.data?.data.description,
      trailer: response.data?.data.trailer,
    });
    setDataSpeaker({
      speakerId: response.data?.data.speaker.speakerId,
      name: response.data?.data.speaker.name,
      about: response.data?.data.speaker.about,
      cover: response.data?.data.speaker.cover,
      calification: 0,
      numberCourses: 0,
    });
    setDataFirstUnit(response.data?.data.firstUnit);
    setDataUnitsContent(response.data?.data.unitsContent);

    //NOMBRE DEL CURSO
    localStorage.setItem('nameCourse', btoa(response.data?.data.titleCourse))
    setLoading(false);
  } catch (error: any) {
    Toast({
      message: error?.message || `No se ha encontrado el curso`,
      type: `error`,
    });
  } finally {
    setLoading(false);
  }
};
