import regexs from "constants/regexs";

export const rules = {
  name: {
    required: true,
    pattern: regexs.text,
  },
  sex: {
    required: true,
    pattern: regexs.text,
  },
  age: {
    required: true,
    pattern: regexs.number,
  },
};
