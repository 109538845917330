import axios from "axios";
import envs from "constants/envs";
import errorsHttp from "constants/errorsHttp";
import { IApi } from "./props";

let cancelToken: () => void;

class API {
  static cancel = () => cancelToken();

  static async request({
    baseURL = envs.api,
    url,
    method,
    params,
    data,
    hasToken,
    token,
  }: IApi) {
    const getToken = () => token || localStorage.getItem(`token`);

    //if (hasToken && !getToken()) return;

    const headersTemplate = Object.assign({
      ...(hasToken && {
        Authorization: `Bearer ${getToken()}`,
      }), 
    });

    return axios
      .create({ baseURL })({
        url,
        method,
        params,
        data,
        headers: { ...headersTemplate, "Content-Type": `application/json` },
        cancelToken: new axios.CancelToken((c) => (cancelToken = c)),
      })
      .then((response) => response)
      .catch((error) => {
        if (error?.message === errorsHttp.NetworkError) {
          throw new Error(
            `El servidor no responde o no tienes conexión a internet.`
          );
        }
        return error?.response;
      });
  }
}

export default API;
