import "./styles.scss";

import { Icon } from "components/atoms";
import { useState } from "react";
import { ICollapse } from "./props";

const Collapse2 = ({
  reference,
  children,
  text,
  className,
  img,
  classNameImg,
  extraOnShowCollapse,
  collapsed,
}: ICollapse) => {
  const onShowCollapse = async () => {
    if (extraOnShowCollapse) {
      const temp = await extraOnShowCollapse();
      console.log(temp);
      setNewContent(temp);
    }
    setShowCollapse(!showCollapse);
  };
  const [showCollapse, setShowCollapse] = useState(collapsed);
  const [newContent, setNewContent] = useState(null);

  return (
    <div className={`collapse-group`}>
      <div
        ref={reference || null}
        className={`${className} collapse-btn fs-subTitle`}
        onClick={() => onShowCollapse()}
      >
        {img && <img src={img} alt="" className={`${classNameImg}`} />}

        {text}

        <Icon
          className={`collapse-arrow ${showCollapse ? `open` : ``}`}
          name="down"
          list="arrows"
          color="black"
          width={20}
        />
      </div>

      <div className={`collapse-content ${showCollapse ? `open` : ``}`}>
        {extraOnShowCollapse ? newContent : children}
      </div>
    </div>
  );
};

export default Collapse2;
