import images from "assets/images";
import { Button } from "components/atoms";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const VerifiedEmail = () => {
  const query = new URLSearchParams(useLocation().search);
  const token = query.get(`t`);
  const names = query.get(`names`);
  const email = query.get(`email`);
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <section className="login">
        <div className="login-card bg-white w-450px mx-1rem mb-2rem pb-3rem">
          <div className="head bg-yellow d-flex ac-ai-jc-center">
            <span className="fs-20px fw-400 ff-secondary">EMAIL VALIDADO</span>
          </div>
          <div className="body">
            <div className="pt-2rem pb-1rem">
              <img src={images.MabRed} className="w-25por" />
            </div>

            <div className="pb-2rem">
              <h2 className="ff-secondary">BIENVENIDO, {names} </h2>
              <span className="ff-primary fs-16px">{email}</span>
            </div>
            <Button
              redirect="/"
              loading={loading}
              className="btnAccess bg-yellow t-black ff-secondary fw-900 fs-20px w-50por "
              text="IR AL INICIO"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default VerifiedEmail;
